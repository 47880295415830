import React from "react";
import GetAuthContext from "../../shared/context/AuthContext";
import { ACTIONS_LIST } from "../../shared/utils/constants";

interface Props {
  requiredPage: string; // Page permission required to see the component
  children: JSX.Element; // The component to be rendered
  checkPermission?: ({
    // Custom function which can be provided to check the permission
    requiredPage,
    requiredFunctions,
    sourceEntityId,
    permissions,
  }: {
    requiredPage: string;
    sourceEntityId?: string | Array<string>;
    requiredFunctions: Array<(typeof ACTIONS_LIST)[keyof typeof ACTIONS_LIST]>;
    permissions: any;
  }) => boolean;
  requiredFunctions?: Array<(typeof ACTIONS_LIST)[keyof typeof ACTIONS_LIST]>; // The functional apis the user should have access to render this component
  showPermissionDenied?: boolean; // Whether to show permission denied or a blank page
  sourceEntityId?: string | Array<string>; // If present, the source entity id for which the access to functional api should be present
  some?: boolean; // If true, the component will be rendered if the user has access to any of the functional apis
  isUserType?: boolean;
  disableNotHide?: boolean;
}

function PermissionDenied() {
  return (
    <div className="flex flex-row items-center justify-center w-full h-full">
      <p className="flex text-3xl font-semibold py-8">Permission Denied</p>
    </div>
  );
}

function checkPermissionDefault({
  requiredPage,
  sourceEntityId,
  requiredFunctions = [],
  permissions,
  some,
}: {
  requiredPage: string;
  sourceEntityId?: string | Array<string>;
  requiredFunctions?: string[];
  permissions: any;
  some?: boolean;
}) {
  let sourceEntityIds: Array<string> = [];
  let isAllowed = !!permissions[requiredPage];
  const pagePermissions = permissions[requiredPage] ?? {};

  if (!sourceEntityId || !isAllowed) {
    // User is simply checking for page access or if he does not have the access to the page,
    // return now itself
    return isAllowed;
  }
  if (typeof sourceEntityId === "string") {
    sourceEntityIds = [sourceEntityId];
  }
  if (Array.isArray(sourceEntityId)) {
    sourceEntityIds = [...sourceEntityId];
  }
  // User has page access
  if (sourceEntityIds?.length || requiredFunctions?.length > 0) {
    isAllowed = sourceEntityIds?.some((s) => {
      return !!pagePermissions?.[s];
    });

    if (isAllowed && requiredFunctions?.length) {
      isAllowed =
        isAllowed && some
          ? requiredFunctions.some((func) => {
              return sourceEntityIds?.some((s) => {
                return pagePermissions?.[s]?.includes(func);
              });
            })
          : requiredFunctions.every((func) => {
              return sourceEntityIds?.some((s) => {
                return pagePermissions?.[s].includes(func);
              });
            });
    }
    // }
  }
  return isAllowed;
}

export default function PrivateComponent({
  children,
  requiredPage,
  checkPermission,
  requiredFunctions = [],
  showPermissionDenied = false, // Used only for pages and tabs - used to determine if page or component
  sourceEntityId,
  some = false,
  disableNotHide = false,
}: Props) {
  const { userState } = GetAuthContext();
  const { permissions = {} } = userState || {};

  let isAllowed = checkPermission
    ? checkPermission?.({
        requiredPage,
        requiredFunctions,
        permissions,
        sourceEntityId,
      })
    : checkPermissionDefault?.({
        requiredPage,
        requiredFunctions,
        permissions,
        sourceEntityId,
        some,
      });

  if (isAllowed || disableNotHide) {
    let props = {};

    if (showPermissionDenied) {
      // indicates that this is a page
      props = { pageName: requiredPage };
    } else if (!isAllowed && disableNotHide) {
      props = {
        disabled: disableNotHide,
        style: { cursor: "not-allowed", opacity: "0.5" },
      };
    }

    return (
      <>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, props)
        )}
      </>
    );
  }

  if (showPermissionDenied) {
    return <PermissionDenied />;
  }

  return null;
}
