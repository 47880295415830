import { CommonService } from "../../services/Common";
import PartnersService from "../../services/Partners";
import {
  PAGE_TYPES,
  PRODUCT_TYPES,
  SEARCH_TYPES,
} from "../../services/Partners/constants";
import { FINBOX_USER } from "../../shared/context/AuthContext";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";
import usePartnersStore from "../Partners/store";
import LZString from "lz-string";

type Mode = "onboarded" | "pending" | "rejected";

export const STATUS_MAP: Record<Mode, number> = {
  pending: 0,
  rejected: 2,
  onboarded: 1,
};

export default async function partnersListLoader({
  request,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  console.log("Partners Loader called");
  const url = new URL(request.url);
  const { getPartnersList, getChannelsList } = usePartnersStore.getState();
  const searchParams = new URLSearchParams(url.search);
  const page = +(searchParams.get("page") ?? 1);
  const status = searchParams.get("status") || undefined;
  const productTypeParam = searchParams.get("productType") || "all";
  const searchTypeParam = searchParams.get("searchType") ?? "";
  const searchTextParam = searchParams?.get("searchText") ?? "";
  const token = getToken();
  let productTypes = searchParams.get("productType") || "all";
  if (productTypes == "all") {
    productTypes = "";
  }
  const finboxUser = JSON.parse(
    LZString.decompress(localStorage?.getItem(FINBOX_USER)) ?? "{}"
  );
  const userType = finboxUser?.userType ?? "";

  await getPartnersList({
    limit: 10,
    page,
    token,
    searchType: searchTypeParam ?? "",
    searchText: searchTextParam ?? "",
    filters: [],
    productType: productTypes,
    pageName,
    status,
  });
  await getChannelsList({
    pages: [],
    token,
    searchType: searchTypeParam ?? "",
    searchText: searchTextParam ?? "",
    productType: productTypes,
    pageName,
    dsaID: "",
    type: "full",
    userType: userType!,
  });
  usePartnersStore.setState({
    productType: productTypes,
    searchText: searchTextParam,
    searchType: searchTypeParam,
    status,
  });

  const productType =
    PRODUCT_TYPES.find((pType) => pType.value === productTypeParam) ||
    PRODUCT_TYPES[0];
  const searchType =
    SEARCH_TYPES.find((p) => p.value == searchTypeParam) || SEARCH_TYPES[0];

  // const promiseResponse = await Promise.all(promisesList);

  return {
    searchText: searchTextParam,
    searchType,
    productType,
  };
}
