import { LeadDTO } from "../../../dtos/LeadDTO";

export interface LeadsState {
  isFetchingLeads: boolean;
  isFetchingLeadsSuccess: boolean;
  isFetchingLeadsFailure: boolean;

  leads: LeadDTO[];

  currentLead: LeadDTO;

  error: string;
}

const initialLeadsState: LeadsState = {
  isFetchingLeads: false,
  isFetchingLeadsSuccess: false,
  isFetchingLeadsFailure: false,

  leads: [],

  currentLead: {
    customerID: "",
    mobile: "",
    createdAt: "",
    name: "",
    email: "",
    dob: "",
    gender: "",
    pincode: "",
    pan: "",
    status: "",
    fisScore: undefined,
    bureauScores: [],
    loanApplicationIDs: [],
    source: "",
    partnerCode: "",
    isAllowEditName: undefined,
    allowSessionLink: undefined,
    sourceEntityName: "",
    sourceEntityID: "",
    loanApplicationID: "",
    loanApplicationNum: "",
    dsaName: "",
    dsaEmail: "",
    dsaMobile: "",
    agentCode: "",
    productType: "",
    updatedAt: "",
  },

  error: "",
};

export default initialLeadsState;
