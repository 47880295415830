import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import Navbar from "./navbar";
import GetAuthContext from "../context/AuthContext";
import { UserService } from "../../services/User";
import {
  getTokenFromStorage,
  getUserInfoFromLocalStorage,
} from "../services/Fetch";
import { PAGES_LIST } from "../utils/constants";

const forbiddenRoutes = [
  "signin",
  "reset-password",
  "verify-account-details",
  "/verify-mobile-password",
  "/verify-otp",
  "/authenticate",
  "/password-expire",
  "/signin-mobile",
];

function Layout() {
  const navigate = useNavigate();
  const [appReady, setAppReady] = useState(false);
  const { userState, logout } = GetAuthContext();

  useEffect(() => {
    const token = getTokenFromStorage();
    const currentRoute = window.location.href;
    const shouldRedirectToSignIn = !forbiddenRoutes.some(
      (route) => currentRoute.indexOf(route) !== -1
    );

    if (token) {
      if (
        currentRoute.indexOf("signin") > -1 ||
        window.location.pathname === "/"
      ) {
        const userPermissions = userState?.permissions ?? {};
        if (
          Object.keys(userPermissions)?.length === 1 &&
          userPermissions?.[PAGES_LIST.ADMIN_PAGE]
        ) {
          navigate("/admin/user", {
            replace: true,
          });
        } else if (Object.keys(userPermissions)?.length != 0) {
          navigate(window.innerWidth < 640 ? "/home" : "/leads", {
            replace: true,
          });
        }
      }
    } else if (shouldRedirectToSignIn) {
      logout();
      navigate("/signin", { replace: true });
    }

    setAppReady(true);
  }, [userState]);

  return (
    <Fragment>
      <Navbar />
      <div className="mx-auto max-w-screen-3xl sm:px-4 sm:px-22 lg:px-14">
        {appReady ? <Outlet></Outlet> : null}
      </div>
    </Fragment>
  );
}

export default Layout;
