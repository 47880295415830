import { Fragment, useMemo } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { NavLink, useNavigate } from "react-router-dom";
import { NavbarLink } from "./interfaces";
// import FinBoxLogo from "../../../assets/img/FinBoxLogo.svg";
import GetAuthContext from "../../context/AuthContext";
import NavbarMenuButton from "./NavbarMenuButton";
import { initializeName } from "../../utils/Common";
import Divider from "../../../components/atomic/Divider";
import GetMixpanelContext from "../../context/MixpanelContext";
import { CREDIT_LINE, OVERDRAFT, PAGES_LIST } from "../../utils/constants";

export default function Navbar() {
  const { userState, logout, getPageConfig } = GetAuthContext();
  const { permissions } = userState;
  const { resetIdentity } = GetMixpanelContext();
  const navigate = useNavigate();
  const { isAuthenticated } = userState || {};
  const pageConfig = getPageConfig();
  // const isFinboxOpsUser = userState?.group === "finbox_ops" ?? false;

  let menuDropdown: Array<NavbarLink> = [
    {
      label: "My Profile",
      href: "/user-profile/details",
      showInMobile: true,
      isVisible: !!permissions?.[PAGES_LIST.USER_PROFILE_PAGE],
    },
    {
      label: "Utilities",
      href: "/utilities",
      showInMobile: false,
      isVisible: !!permissions?.[PAGES_LIST.UTILITIES_PAGE],
    },
    {
      label: "Integrations",
      href: "/integrations",
      showInMobile: false,
      isVisible: !!permissions?.[PAGES_LIST.INTEGRATIONS_PAGE],
    },
    {
      label: "Raise Ticket",
      onClick: () => {
        window.open("mailto:support@finbox.in");
      },
      showInMobile: false,
      isVisible: true,
    },
    {
      label: "Contact Us",
      onClick: () => {
        window.open("https://finbox.in/contact-us/", "_blank");
      },
      showInMobile: false,
      isVisible: true,
    },
  ];

  let productMenuLinks = [
    {
      label: pageConfig["global"].navbarProducts.LOAN_APPLICATION.name,
      href: "/loan-applications",
      showInMobile: true,
      isVisible: !!permissions?.[PAGES_LIST.LOAN_APPLICATIONS_LISTING_PAGE],
    },
    {
      label: "Credit Line",
      isExpandable: true,
      expandDirection: "right",
      showInMobile: false,
      isVisible:
        (!!permissions?.[PAGES_LIST.ACTIVELINE_LISTING_PAGE] ||
          !!permissions?.[PAGES_LIST.ALL_TRANSACTIONS_LISTING_PAGE]) &&
        (pageConfig["global"]?.productTypes
          ? pageConfig["global"]?.productTypes?.includes(CREDIT_LINE)
          : true),
      menuItems: [
        {
          label: "All Transactions",
          href: "/products/all-transactions",
          showInMobile: false,
          isVisible: !!permissions?.[PAGES_LIST.ALL_TRANSACTIONS_LISTING_PAGE],
        },
        {
          label: "Active Lines",
          href: "/products/active-line",
          showInMobile: false,
          isVisible: !!permissions?.[PAGES_LIST.ACTIVELINE_LISTING_PAGE],
        },
      ],
    },
    {
      label: "Overdraft Bills",
      href: "/products/overdraft",
      showInMobile: false,
      isVisible:
        !!permissions?.[PAGES_LIST.OVERDRAFT_LISTING_PAGE] &&
        (pageConfig["global"]?.productTypes
          ? pageConfig["global"]?.productTypes?.includes(OVERDRAFT)
          : true),
    },
  ];

  let navigationLeftLinks: Array<NavbarLink> = [
    {
      label: "Leads",
      href: "/leads",
      showInMobile: false,
      isVisible: !!permissions?.[PAGES_LIST.LEADS_LISTING_PAGE],
    },
  ];

  const isMobileScreen = window.innerWidth < 640;

  const isAdminAccess = useMemo(() => {
    const userPermissions = userState?.permissions ?? {};
    if (userPermissions?.[PAGES_LIST.ADMIN_PAGE]) {
      return true;
    }
    return false;
  }, [userState]);

  return (
    <Disclosure
      as="nav"
      className="bg-gray-800 sticky top-0 z-[30] w-full md:relative md:z-auto"
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-screen-3xl px-4 sm:px-22 lg:px-14">
            <div className="flex h-14 justify-between">
              <div className="flex w-full justify-between">
                <div className="flex items-center justify-start ">
                  <div
                    className="flex flex-shrink-0 items-center justify-start"
                    style={{
                      width: `${
                        isMobileScreen
                          ? "auto"
                          : (window.innerWidth - 112) * 0.2
                      }px`,
                    }}
                  >
                    <img
                      onClick={() => {
                        const redirectUrl =
                          window.innerWidth < 640 ? "/home" : "/leads";
                        navigate(redirectUrl);
                      }}
                      className="h-6 w-auto cursor-pointer"
                      src={
                        "https://finbox-cdn.s3.ap-south-1.amazonaws.com/finbox-logos/logo-light.svg"
                      }
                      alt="FinBox"
                    />
                  </div>
                  {userState.isAuthenticated && (
                    <div className="hidden sm:ml-4 sm:flex h-full items-center justify-start md:space-x-16">
                      {navigationLeftLinks.map((link, index) => {
                        return link.isVisible ? (
                          <NavLink
                            key={index}
                            to={link.href!}
                            className={({ isActive }) =>
                              classNames(
                                "block text-center align-middle text-sm font-medium h-min py-2 px-4 rounded-lg",
                                isActive
                                  ? "bg-gray-900 text-white"
                                  : "bg-gray-800 text-white"
                              )
                            }
                          >
                            {link.label}
                          </NavLink>
                        ) : null;
                      })}

                      {productMenuLinks.filter(
                        (productMenu) => productMenu.isVisible
                      )?.length > 0 ? (
                        <div className="hidden md:flex md:space-x-12 items-center">
                          <NavbarMenuButton
                            buttonText={
                              <div className="inline-flex items-center justify-between text-white font-medium">
                                Products
                                <ChevronDownIcon className="w-4 h-4 text-white ml-2"></ChevronDownIcon>
                              </div>
                            }
                            menuItems={productMenuLinks}
                            preContent={""}
                            styles={{
                              container: "ml-0 mr-0",
                              button: "px-0",
                              menuItem: "text-sm",
                              menuItemsList: "w-56",
                            }}
                          />
                        </div>
                      ) : null}

                      {permissions?.[PAGES_LIST.PARTNERS_PAGE] && (
                        <NavLink
                          to={"/partners/list"}
                          className={({ isActive }) =>
                            classNames(
                              "block text-center align-middle text-sm font-medium h-min py-2 px-4 rounded-lg",
                              isActive
                                ? "bg-gray-900 text-white"
                                : "bg-gray-800 text-white"
                            )
                          }
                        >
                          Partners
                        </NavLink>
                      )}

                      {permissions?.[PAGES_LIST.REPORTS_PAGE] && (
                        <NavLink
                          to={"/reports"}
                          className={({ isActive }) =>
                            classNames(
                              "block text-center align-middle text-sm font-medium h-min py-2 px-4 rounded-lg",
                              isActive
                                ? "bg-gray-900 text-white"
                                : "bg-gray-800 text-white"
                            )
                          }
                        >
                          Reports
                        </NavLink>
                      )}
                      {permissions?.[PAGES_LIST.ANALYTICS_PAGE] && (
                        <NavLink
                          to={"/analytics"}
                          className={({ isActive }) =>
                            classNames(
                              "block text-center align-middle text-sm font-medium h-min py-2 px-4 rounded-lg",
                              isActive
                                ? "bg-gray-900 text-white"
                                : "bg-gray-800 text-white"
                            )
                          }
                        >
                          Analytics
                        </NavLink>
                      )}
                      {permissions?.[PAGES_LIST.ADMIN_PAGE] && (
                        <NavLink
                          to={"/admin/user"}
                          className={({ isActive }) =>
                            classNames(
                              "block text-center align-middle text-sm font-medium h-min py-2 px-4 rounded-lg",
                              isActive
                                ? "bg-gray-900 text-white"
                                : "bg-gray-800 text-white"
                            )
                          }
                        >
                          Admin
                        </NavLink>
                      )}
                    </div>
                  )}
                </div>

                {/* <div className="hidden sm:ml-6 sm:flex sm:space-x-8 items-center">
                  <NavbarMenuButton
                    buttonText={
                      <div className="inline-flex items-center justify-center">
                        <InformationCircleIcon className="w-5 h-5 text-gray-400 mr-2"></InformationCircleIcon>
                        Help
                      </div>
                    }
                    menuItems={helpMenuItems}
                    preContent={""}
                    styles={{
                      container: "mr-2",
                      buttonContainer: "",
                      menuItem: "text-sm",
                    }}
                  />
                </div> */}
              </div>
              {userState.isAuthenticated && (
                <div className="hidden sm:ml-6 sm:flex sm:items-center border-green-500">
                  {/* Profile dropdown */}
                  <Menu
                    as="div"
                    className="relative ml-3"
                  >
                    <div>
                      <Menu.Button className="flex rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 p-0.5 bg-indigo-600">
                        <span className="sr-only">Open user menu</span>
                        <div className="h-8 w-8 rounded-full text-white bg-indigo-600 flex items-center justify-center font-medium border border-indigo-600">
                          {userState?.name && initializeName(userState.name)}
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-[30] mt-2 min-w-[220px] origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userState.isAuthenticated &&
                          window.location.pathname !== "/maintenance" && (
                            <div className="flex items-start px-4 py-2 mb-2">
                              <div className="flex-shrink-0">
                                <div className="h-6 w-6 rounded-full text-white bg-indigo-600 flex items-center justify-center text-xs font-medium">
                                  {userState?.name &&
                                    initializeName(userState.name)}
                                </div>
                              </div>
                              <div className="ml-2">
                                <div
                                  className="text-sm text-gray-700 font-medium mb-1"
                                  title={userState?.name}
                                >
                                  {userState?.name}
                                </div>
                                <div
                                  className="text-xs text-gray-500 truncate max-w-[150px]"
                                  title={userState?.email}
                                >
                                  {userState?.email}
                                </div>
                              </div>
                            </div>
                          )}

                        <Divider className="mb-2"></Divider>

                        {menuDropdown.map((menuItem, index) => {
                          return menuItem.isVisible ? (
                            <Menu.Item key={index}>
                              {menuItem.href ? (
                                <NavLink
                                  to={menuItem?.href}
                                  className={classNames(
                                    "px-4 py-2 text-sm text-gray-700 flex items-center justify-start hover:bg-indigo-50"
                                  )}
                                  onClick={menuItem?.onClick}
                                >
                                  {menuItem.icon ?? null}
                                  {menuItem.label}
                                </NavLink>
                              ) : (
                                <a
                                  className={classNames(
                                    "px-4 py-2 text-sm text-gray-700 flex items-center justify-start hover:bg-indigo-50"
                                  )}
                                  onClick={menuItem?.onClick}
                                >
                                  {menuItem.icon ?? null}
                                  {menuItem.label}
                                </a>
                              )}
                            </Menu.Item>
                          ) : null;
                        })}
                        {isAuthenticated && (
                          <Menu.Item>
                            <a
                              className={classNames(
                                "px-4 py-2 text-sm text-gray-700 flex items-center justify-start hover:bg-indigo-50"
                              )}
                              onClick={() => {
                                logout();
                                resetIdentity();
                              }}
                            >
                              Logout
                            </a>
                          </Menu.Item>
                        )}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )}

              {/* Mobile Menu Button */}
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu Button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  ) : (
                    <Bars3Icon
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden bg-gray-50 shadow-sm">
            {({ close }) => (
              <div className="border-t border-gray-200 pt-4 pb-3">
                {isAuthenticated && (
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <div className="h-8 w-8 md:h-10 md:w-10 text-sm font-semibold md:font-base md:text-sm rounded-full text-white bg-indigo-600 flex items-center justify-center">
                        {userState?.name && initializeName(userState.name)}
                      </div>
                    </div>
                    <div className="ml-3">
                      <div className="text-sm font-medium text-gray-700">
                        {userState?.name}
                      </div>
                      <div className="text-xs font-medium text-gray-400">
                        {userState?.email}
                      </div>
                    </div>
                  </div>
                )}
                <div className="mt-3 space-y-1">
                  {menuDropdown.map((menuItem, index) => {
                    const { href, onClick, icon, label, showInMobile } =
                      menuItem;
                    if (isMobileScreen && showInMobile) {
                      if (href) {
                        return (
                          <Disclosure.Button
                            key={index}
                            as={NavLink}
                            to={href!}
                            onClick={onClick! ?? null}
                            className="flex items-center justify-start px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                          >
                            {icon ?? null}
                            {label}
                          </Disclosure.Button>
                        );
                      } else {
                        return (
                          <a
                            key={index}
                            className="flex items-center justify-start px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                            onClick={(e) => {
                              onClick?.(e);
                              close();
                            }}
                          >
                            {label}
                          </a>
                        );
                      }
                    } else {
                      return null;
                    }
                  })}
                  {isAuthenticated && (
                    <a
                      className="flex items-center justify-start px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                      onClick={() => {
                        logout();
                        close();
                        resetIdentity();
                      }}
                    >
                      Logout
                    </a>
                  )}
                </div>
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
