import { Fragment } from "react";
import { NavLink, NavLinkProps, useMatch } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import Divider from "../../../components/atomic/Divider";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

export interface NavbarMenuItem extends Omit<NavLinkProps, "to"> {
  icon?: string | JSX.Element;
  label: string | JSX.Element;
  href?: string;
  isExpandable?: boolean;
  expandDirection?: "right" | "left";
  menuItems?: Array<NavbarMenuItem>;
  isVisible?: boolean;
}

interface IProps {
  buttonText: string | JSX.Element;
  menuItems: Array<NavbarMenuItem>;
  preContent?: string | JSX.Element;
  srText?: string;
  styles?: {
    container?: string;
    button?: string;
    menuItemsList?: string;
    menuItem?: string;
    buttonContainer?: string;
  };
  menuDirection?: "left" | "right";
  manualClose?: () => void;
}

function NavbarMenuButton({
  buttonText,
  menuItems = [],
  preContent,
  srText = "",
  styles = {},
  menuDirection = "left",
  manualClose,
}: // manualClose = () => {},
IProps) {
  return (
    <Menu
      as="div"
      className={classNames("relative", styles.container || "ml-8")}
    >
      {({ close }) => (
        <>
          <div className={styles.buttonContainer}>
            <Menu.Button
              className={classNames(
                "flex items-center justify-center rounded-full px-4 py-2 text-sm focus:outline-none",
                styles.button || "bg-gray-800"
              )}
            >
              <span className="sr-only">
                {srText ? srText : "Open user menu"}
              </span>
              {buttonText}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={classNames(
                "absolute mt-2 w-64 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50",
                styles.menuItemsList || "",
                menuDirection === "right" ? "right-0" : "left-0"
              )}
            >
              {preContent}
              {preContent && <Divider className="mb-2"></Divider>}
              {menuItems.map((menuItem, menuIdx) => {
                let isMatch = null;
                if (menuItem?.href) {
                  isMatch = useMatch(menuItem.href, location.pathname);
                }
                if (!menuItem.isVisible) {
                  return null;
                }

                if (!menuItem.isExpandable) {
                  return (
                    <Menu.Item key={menuIdx}>
                      {menuItem.href ? (
                        <NavLink
                          to={menuItem?.href}
                          className={classNames(
                            "px-4 text-sm py-2 text-gray-700 flex items-center justify-start bg-white hover:bg-indigo-50",
                            !!isMatch && "bg-gray-200",
                            styles.menuItem || ""
                          )}
                          onClick={() => {
                            if (manualClose) {
                              manualClose();
                            }
                          }}
                        >
                          {menuItem.icon ?? null}
                          {menuItem.label}
                        </NavLink>
                      ) : (
                        <a
                          onClick={(e) => {
                            menuItem?.onClick(e);
                            if (manualClose) {
                              manualClose();
                            }
                          }}
                          className={classNames(
                            "px-4 text-sm py-2 text-gray-700 flex items-center justify-start bg-white hover:bg-indigo-50",
                            !!isMatch && "bg-gray-200",
                            styles.menuItem || ""
                          )}
                        >
                          {menuItem.icon ?? null}
                          {menuItem.label}
                        </a>
                      )}
                    </Menu.Item>
                  );
                }

                return (
                  // <Menu.Item key={menuIdx}>
                  //   {({ close }) => (
                  <NavbarMenuButton
                    key={menuIdx}
                    buttonText={
                      <div className="flex items-center justify-between text-gray-700 w-full">
                        {menuItem.expandDirection === "right" ? (
                          <>
                            {menuItem.label}
                            <ChevronDownIcon
                              className={classNames(
                                "w-4 h-4 text-gray-700 ml-2 -rotate-90"
                              )}
                            ></ChevronDownIcon>
                          </>
                        ) : (
                          <>
                            <ChevronDownIcon
                              className={classNames(
                                "w-4 h-4 text-gray-700 ml-2 rotate-90"
                              )}
                            ></ChevronDownIcon>
                            {menuItem.label}
                          </>
                        )}
                      </div>
                    }
                    menuItems={menuItem?.menuItems ?? []}
                    preContent={""}
                    styles={{
                      container: "ml-3 mr-2",
                      buttonContainer: "",
                      menuItem: "text-sm",
                      menuItemsList:
                        "flyout-right w-56 rounded-bl-none rounded-tl-none",
                      button: "bg-white pr-1 pl-1 w-full",
                    }}
                    menuDirection="right"
                    manualClose={manualClose ?? close}
                  />
                  // )}
                  // </Menu.Item>
                );
              })}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

export default NavbarMenuButton;
