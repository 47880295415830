import { TbExternalLink } from "react-icons/tb";
import { useLoaderData } from "react-router";
import PageHeader from "../../components/atomic/Header/PageHeader";
import { BreadCrumb } from "../../components/atomic/Breadcrumbs/interface";
import { Get } from "../../shared/services/Fetch";
import { useEffect, useState } from "react";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";
import { XMarkIcon } from "@heroicons/react/24/outline";
import CopyText from "../../components/atomic/CopyText";
import { snakeToTitle } from "../../shared/utils/TextUtil";
import GetMixpanelContext from "../../shared/context/MixpanelContext";
import { PageProps } from "../../services/Common/interfaces";
import { noHeadersResponse } from "../../shared/utils/Global";

interface iUserDSA {
  agentCode: string;
  creditLink: string;
  dsaID: string;
  dsaName: string;
  lenderName: string;
  productType: "personal_loan" | "business_loan";
  sourceEntityID: string;
}

export const userDSAListLoader = async ({
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) => {
  const headers = new Headers();
  if (pageName) {
    headers.append("page-header", pageName);
  } else {
    return noHeadersResponse();
  }
  const response = await Get<{ agentList: iUserDSA[] }>(
    `/masterDashboard/userDSAList`,
    getToken(),
    headers
  );
  return response.data;
};

interface IAgentCard {
  agentData: iUserDSA;
  onJourneyStart: (creditLink: string) => void;
}

const AgentCard = ({ agentData, onJourneyStart }: IAgentCard) => {
  return (
    <>
      <div
        className="w-full bg-white box-border p-3 mb-2 shadow-sm rounded-lg text-sm cursor-pointer border border-gray-200"
        onClick={() => {
          onJourneyStart(agentData.creditLink);
        }}
      >
        <div className="flex justify-between  w-full items-center mb-4">
          <h2 className=" text-gray-600">{agentData.dsaName}</h2>
          <TbExternalLink className="text-indigo-600 w-4 h-4" />
        </div>
        <div className="flex justify-between  w-full items-end  text-gray-800 ">
          {agentData.lenderName ? (
            <div>
              <div className="text-xs text-gray-400 mb-1">Lender:</div>
              <h2 className="font-medium text-xs">{agentData.lenderName}</h2>
            </div>
          ) : null}

          <h2 className="ml-2 text-xs text-gray-600">
            {snakeToTitle(agentData.productType)}
          </h2>
        </div>
      </div>
    </>
  );
};

const CreateNewLead = ({ pageName }: PageProps) => {
  const { setCurrentScreen } = GetMixpanelContext();
  const userDSALoaderData = useLoaderData() as { agentList: iUserDSA[] };
  const [journeyUrl, setJourneyUrl] = useState<string>("");
  const breadcrumbs: Array<BreadCrumb> = [
    {
      name: "Create New Lead",
      href: "/create-new-lead",
      current: false,
    },
  ];

  useEffect(() => {
    setCurrentScreen("Mobile Create New Lead Page");
  }, []);

  const onJourneyStart = (creditLink: string) => {
    setJourneyUrl(creditLink);
  };

  const onJourneyClose = () => {
    setJourneyUrl("");
  };

  return (
    <div className="w-full  bg-white">
      {journeyUrl ? (
        <div className="relative">
          <iframe
            className="w-full block overflow-auto"
            allow={`camera *`}
            style={{
              height: "calc(100vh - 56px)",
            }}
            src={journeyUrl}
          ></iframe>
          <button
            onClick={onJourneyClose}
            className="p-2 block border top-4 z-[999] right-4 absolute border-indigo-600 rounded-lg bg-white h-auto w-auto"
          >
            <XMarkIcon className="w-5 h-5 text-indigo-700" />
          </button>
        </div>
      ) : (
        <>
          <PageHeader
            title="Choose New Lead"
            breadcrumbs={breadcrumbs}
          ></PageHeader>
          <div className="px-2 pb-4 border-b border-gray-200">
            <h2 className="text-sm font-medium mb-2">My Credit Links</h2>
            {userDSALoaderData?.agentList?.map((agentData, index) => {
              return (
                <div
                  key={index}
                  className="mb-2 border border-gray-200 rounded-md px-2 py-4"
                >
                  <p className="text-xs flex items-center justify-between mb-2">
                    {agentData.lenderName ? (
                      <span className="text-xs font-medium text-gray-800">
                        {agentData.lenderName}
                      </span>
                    ) : null}
                    <span className="">
                      {snakeToTitle(agentData.productType)}
                    </span>
                  </p>
                  <CopyText
                    styles={{
                      container: "",
                      input:
                        "w-full border border-dashed border-gray-300 rounded-tl-lg rounded-bl-lg bg-gray-100 !h-10",
                      button: "bg-gray-200 text-gray-700 border-gray-200 !h-10",
                    }}
                    text={agentData.creditLink}
                  ></CopyText>
                </div>
              );
            }) ?? null}
          </div>

          <div className="px-2 py-4">
            <h2 className="text-sm font-medium mb-2">Start Journey</h2>
            {userDSALoaderData?.agentList?.map((agentData, index) => {
              return (
                <AgentCard
                  key={index}
                  agentData={agentData}
                  onJourneyStart={onJourneyStart}
                />
              );
            }) ?? null}
          </div>
        </>
      )}
    </div>
  );
};

export default CreateNewLead;
