import ActivityTimeline from "../../components/atomic/ActivityTimeline";
import { useLoaderData, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Get } from "../../shared/services/Fetch";
import GetAuthContext from "../../shared/context/AuthContext";
import { noHeadersResponse } from "../../shared/utils/Global";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

export async function loanTimelineLoader({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const { loanID } = params;
  const headers = new Headers();
  if (pageName) {
    headers.append("page-header", pageName);
  } else {
    return noHeadersResponse();
  }
  return await Get(
    `/masterDashboard/loanActivity?loanApplicationID=${loanID}&page=1&limit=8`,
    getToken(),
    headers
  );
}

function LoanTimeline({ pageName }: { pageName?: string }) {
  const { data }: any = useLoaderData();
  const { userActivityHistory } = data || {};

  const [page, setPage] = useState(1);
  const [loanActivity, setLoanActivity] = useState<any[]>([]);
  const [hasMorePages, setHasMorePages] = useState(true);
  const { fetchToken } = GetAuthContext();
  const { loanID } = useParams();

  useEffect(() => {
    setLoanActivity(userActivityHistory || []);
  }, [userActivityHistory]);

  async function getLoanActivity(page = 1) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const response: any = await Get(
      `/masterDashboard/loanActivity?loanApplicationID=${loanID}&page=${page}&limit=8`,
      fetchToken(),
      headers
    );
    if (response.status) {
      const newActivities = response.data?.userActivityHistory || [];
      if (newActivities.length > 0) {
        setLoanActivity((prevActivities) => [
          ...prevActivities,
          ...newActivities,
        ]);
      } else {
        setHasMorePages(false);
      }
    } else {
      console.log("API failed", response.error);
    }
  }

  useEffect(() => {
    if (page > 1 && hasMorePages) {
      getLoanActivity(page);
    }
  }, [page, hasMorePages]);

  function onViewMoreClick() {
    setPage((prevPage) => prevPage + 1);
  }

  return (
    <div className="px-4 overflow-auto">
      <div className="flex items-center text-base font-medium">
        <h3 className="text-sm text-gray-700 font-medium w-max border-b-2 border-indigo-500 py-2 pr-2">
          Timeline
        </h3>
      </div>

      <ActivityTimeline
        timeline={loanActivity}
        onViewMoreClick={onViewMoreClick}
        page={page}
        hasMorePages={hasMorePages}
      />
    </div>
  );
}

export default LoanTimeline;
