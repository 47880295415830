import mixpanel from "mixpanel-browser";
import { Toaster } from "react-hot-toast";
import "react-tooltip/dist/react-tooltip.css";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { MouseEvent, lazy, Suspense, useLayoutEffect } from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Layout from "./layout/Layout";
import Button from "../components/atomic/Button";
import { getResolvedRoutes } from "./route.config";
import { AuthProvider } from "./context/AuthContext";
import { AlertProvider } from "./context/AlertContext";
import { MixpanelProvider } from "./context/MixpanelContext";

const ErrorPage = lazy(() => import("../pages/ErrorPage"));

function clickHandler() {
  const detailElements =
    document.querySelectorAll<HTMLDetailsElement>("details[open]");

  detailElements.forEach((element) => {
    element.open = false;
  });
}

function App() {
  useLayoutEffect(() => {
    document.body.addEventListener("click", clickHandler);

    return () => {
      document.body.removeEventListener("click", clickHandler);
    };
  }, []);

  useLayoutEffect(() => {
    let deferredPrompt: null | BeforeInstallPromptEvent = null;
    const a2hsContainer = document.querySelector("#a2hs-container");
    const addBtn = document.querySelector(".add-button");
    console.log("setting up beforeinstallprompt");
    window.addEventListener(
      "beforeinstallprompt",
      (e: BeforeInstallPromptEvent) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        console.log("before install prompt triggered!");
        // Stash the event so it can be triggered later.
        deferredPrompt = e;
        const finboxInstallBannerTiming = localStorage.getItem(
          "finbox-install-banner"
        );
        let showBanner = true;
        if (finboxInstallBannerTiming) {
          let bannerTiming = new Date(finboxInstallBannerTiming).getTime();
          let timeInterval = new Date().getTime() - bannerTiming;

          showBanner = timeInterval >= 1000 * 60 * 60; // 1 hour
          if (import.meta?.REACT_APP_ENV !== "PROD") {
            showBanner = timeInterval >= 1000 * 60 * 60 * 10; // 10 hours
          }
        }

        if (showBanner) {
          localStorage.removeItem("finbox-install-banner");
          a2hsContainer?.classList.remove("hidden");
          a2hsContainer?.classList.add("flex");

          addBtn?.addEventListener("click", () => {
            // hide our user interface that shows our A2HS button
            addBtn.classList.add("hidden");

            // Show the prompt
            (deferredPrompt as BeforeInstallPromptEvent).prompt();
            // Wait for the user to respond to the prompt
            (deferredPrompt as BeforeInstallPromptEvent).userChoice.then(
              (choiceResult) => {
                if (choiceResult.outcome === "accepted") {
                  mixpanel.track("user_clicks_install_pwa");
                  localStorage.removeItem("finbox-install-banner");
                } else {
                  console.log("User dismissed the A2HS prompt");
                }
                deferredPrompt = null;
              }
            );
          });
        }
      }
    );

    window.addEventListener("appinstalled", () => {
      mixpanel.track("pwa_installed");
      // If visible, hide the install promotion
      a2hsContainer?.classList.remove("flex");
      a2hsContainer?.classList.add("hidden");
      console.log("PWA Installed Successfully");
    });
  }, []);

  function dismissInstallBanner(e: MouseEvent<HTMLButtonElement>) {
    e?.stopPropagation();
    localStorage.setItem("finbox-install-banner", new Date().toString());
    const installBanner = document.querySelector("#a2hs-container");
    if (installBanner) {
      // mixpanel?.track?.("dismiss_pwa_install_banner");
      installBanner?.classList?.remove("flex");
      installBanner?.classList?.add("hidden");
    }
  }

  const queryClient = new QueryClient();
  return (
    <>
      {
        <AlertProvider>
          <QueryClientProvider client={queryClient}>
            <Suspense fallback={<p></p>}>
              <AuthProvider>
                <MixpanelProvider>
                  <RouterProvider
                    router={createBrowserRouter([
                      {
                        path: "/",
                        id: "root",
                        element: <Layout />,
                        errorElement: <ErrorPage />,
                        index: false,
                        children: getResolvedRoutes(),
                      },
                    ])}
                  ></RouterProvider>
                </MixpanelProvider>
              </AuthProvider>
            </Suspense>
            <ReactQueryDevtools />
          </QueryClientProvider>
          <Toaster />
        </AlertProvider>
      }
      <div
        id="a2hs-container"
        className="
            hidden
            w-full
            border border-gray-200
            fixed
            bottom-0
            left-0
            p-4
            z-[1001]
            bg-white
            shadow-md
            items-center
            justify-between
          "
      >
        <p className="text-sm">Install the Finbox Dashboard App</p>
        <div className="flex items-center">
          <button
            className="
              add-button
              bg-indigo-600
              text-white
              px-4
              py-2
              rounded-lg
              text-xs
              font-medium
              md:mr-4
            "
          >
            Install
          </button>
          <Button
            type="button"
            onClick={dismissInstallBanner}
            className="!bg-transparent !px-2"
          >
            <XMarkIcon className="w-4 h-4 text-gray-700" />
          </Button>
        </div>
      </div>
    </>
  );
}

export default App;
