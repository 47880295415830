import { IPartnerData } from ".";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";
import { Get } from "../../shared/services/Fetch";
import { noHeadersResponse } from "../../shared/utils/Global";
import { snakeToTitle } from "../../shared/utils/TextUtil";

interface ILoaderResponse {
  data: null | {
    agentList: Array<IPartnerData>;
  };
  error: string;
  status: boolean;
}
export async function createAgentLoader({
  getToken,
  pageName,
}: CustomLoaderFunctionArgs): Promise<ILoaderResponse> {
  try {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const response = await Get<ILoaderResponse["data"]>(
      `/masterDashboard/userDSAList`,
      getToken(),
      headers
    );
    const userDSAList = response?.data?.agentList?.map((agent) => {
      let productType = snakeToTitle(agent.productType);
      let name = agent.lenderName + ` (${productType})`;
      return {
        ...agent,
        name,
        value: agent.dsaID,
      };
    });

    return {
      ...response,
      data: {
        agentList: userDSAList || [],
      },
    };
  } catch (ex: any) {
    return {
      data: null,
      error: new Error(ex).message,
      status: false,
    };
  }
}
