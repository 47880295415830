import {
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { ReactNode } from "react";
import { Toast } from "react-hot-toast";

export type NotifyStatesTypes = "error" | "success" | "info";

const ICONS_BY_TYPE: Record<NotifyStatesTypes, ReactNode> = {
  error: <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
  success: (
    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
  ),
  info: (
    <InformationCircleIcon
      className="h-6 w-6 text-gray-400"
      aria-hidden="true"
    />
  ),
};

interface IProps {
  t: Toast;
  title: ReactNode;
  text?: ReactNode;
  type: NotifyStatesTypes;
  toast: any;
}

export default function Notification({ t, title, text, type, toast }: IProps) {
  return (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex-1 w-0 px-2 py-2">
        <div className="flex items-start">
          <div className="flex-shrink-0">{ICONS_BY_TYPE[type]}</div>
          <div className="ml-3 flex-1">
            <p className={classNames("text-sm font-medium", "text-gray-900")}>
              {title}
            </p>
            <p className={classNames("mt-0.5 text-xs", "text-gray-900")}>
              {text}
            </p>
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200">
        <button
          onClick={(e) => {
            e.stopPropagation();
            toast.dismiss(t.id);
          }}
          className="w-full border border-transparent rounded-none rounded-r-lg py-2 px-4 flex items-center justify-center text-sm font-medium text-indigo-600"
        >
          <XMarkIcon className="w-5 h-5 text-gray-700" />
        </button>
      </div>
    </div>
  );
}
