import classNames from "classnames";

export default function Divider({ className = "" }) {
  return (
    <div className={classNames("relative", { [className]: !!className })}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-200" />
      </div>
    </div>
  )
}
