export const USERS_SEARCH_TYPES = [
  {
    name: "Name",
    value: "name",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Mobile",
    value: "mobile",
  },
];

export const USER_MANAGEMENT_COLUMN = [
  {
    name: "",
    key: "checkbox",
    headerStyles: "z-10",
  },
  {
    name: "Username",
    key: "username",
    headerStyles: "z-10",
    width: "180px",
  },
  {
    name: "Email",
    key: "email",
    headerStyles: "z-10",
  },
  {
    name: "Mobile",
    key: "mobile",
    headerStyles: "z-10",
  },
  // {
  //   name: "Roles",
  //   key: "roles",
  //   headerStyles: "z-10",
  // },
  {
    name: "Last Activity",
    key: "lastActivity",
    headerStyles: "z-10",
  },
  {
    name: "Password Age",
    key: "passwordAge",
    headerStyles: "z-10",
  },
  {
    name: "MFA Status",
    key: "mfaStatus",
    headerStyles: "z-10",
  },
  {
    name: "status",
    key: "status",
    headerStyles: "z-10",
  },
  {
    name: "",
    key: "actions",
    rowStyles: "!overflow-visible",
    headerStyles: "z-10",
  },
];

export const statusOptions = [
  {
    name: "Active",
    value: "ACTIVE",
  },
  {
    name: "Deactivated",
    value: "INACTIVE",
  },
];


export const FILTER_TABLE_COLUMN = [
  {
    name: "Name",
    key: "userName",
    width: "180px",
    headerStyles:"!bg-[#FAFAFA] rounded-none"
  },
  {
    name: "Email",
    key: "userEmail",
    headerStyles:"!bg-[#FAFAFA] rounded-none"
  },
  {
    name: "Contact Number",
    key: "userMobile",
    headerStyles:"!bg-[#FAFAFA] rounded-none"
  },
  // {
  //   name: "Updated At",
  //   key: "updatedAt",
  //   headerStyles:"!bg-[#FAFAFA] rounded-none"
  // },
  {
    name: "Platform",
    key: "SourceEntityName",
    headerStyles:"!bg-[#FAFAFA] rounded-none"
  },
  {
    name: "Group",
    key: "groupName",
    headerStyles:"!bg-[#FAFAFA] rounded-none"
  },
  {
    name: "Status",
    key: "userStatus",
    headerStyles:"!bg-[#FAFAFA] rounded-none"
  },
]

export const DATE_TYPE = [
  {
    name:"createdAt",
    value:"created_at"
  },
  {
    name:"updatedAt",
    value:"updated_at"
  }
]