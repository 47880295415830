import GetAuthContext from "../../../shared/context/AuthContext";
import { Delete, Post } from "../../../shared/services/Fetch";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { notify } from "../../../shared/context/AlertContext";
import { Agent } from "../../../services/Common/interfaces";
import { Partner } from "../../../services/Partners/interfaces";
import DropdownWithSearch from "../../atomic/DropdownWithSearch";
import Button from "../../atomic/Button";
import { useParams } from "react-router";
import GetMixpanelContext from "../../../shared/context/MixpanelContext";
import { noHeadersResponse } from "../../../shared/utils/Global";
import PartnersService from "../../../services/Partners";

interface IProps {
  setOpen: React.Dispatch<boolean>;
  reload: any;
  selectedPartner: Partner | null;
  modalType?: string;
  pageName: string;
}

export default function DeactivateAgentModal({
  reload,
  setOpen,
  modalType = "delete",
  selectedPartner: selectedRow = null,
  pageName,
}: IProps): JSX.Element {
  const { fetchToken } = GetAuthContext();
  const { trackUserAction } = GetMixpanelContext();
  const [agentList, setAgentList] = useState<Array<Agent>>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [reassignDSAID, setReassignDSAID] = useState<string>("");
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const { userID } = useParams();

  useEffect(() => {
    PartnersService.getAgentList({
      token: fetchToken(),
      searchQuery: searchText,
      productType: selectedRow?.product,
      pageName,
    }).then((response) => {
      if (response.status) {
        setAgentList(response?.data?.agentList ?? []);
      }
    });
  }, []);

  async function onSearch(searchText: string) {
    const response = await PartnersService.getAgentList({
      token: fetchToken(),
      searchQuery: searchText,
      productType: selectedRow?.product,
      pageName,
    });
    if (response.status) {
      trackUserAction("search_agent", {
        searchText,
        productType: selectedRow?.product,
        userID,
      });
      setAgentList(response.data?.agentList ?? []);
    }
  }

  async function onSubmit() {
    if (isConfirmed) {
      try {
        let response;
        const headers = new Headers();
        if (pageName) {
          headers.append("page-header", pageName);
        } else {
          return noHeadersResponse();
        }
        if (modalType === "reassign" && userID) {
          response = await Post(
            "/masterDashboard/reassignLead",
            fetchToken(),
            {
              userID,
              dsaID: reassignDSAID,
            },
            headers
          );
        } else {
          response = await Delete(
            `/masterDashboard/deleteAgent?deletedDSAID=${selectedRow.dsaID}&reassignDSAID=${reassignDSAID}`,
            fetchToken(),
            undefined,
            headers
          );
        }

        if (response.status) {
          if (modalType === "reassign") {
            trackUserAction("reassign_lead", {
              userID,
              reassignDSAID,
            });
          } else {
            trackUserAction("delete_agent", {
              reassignDSAID,
              deletedDSAID: selectedRow?.dsaID,
            });
          }
          notify({
            type: "success",
            text:
              modalType === "reassign"
                ? "Reassigned Lead Successfully"
                : "Deactivated Agent Successfully",
            title: "Success",
          });
          reload();
          setOpen(false);
        } else {
          notify({
            title: "Deactivate Agent Failed",
            text: response.error,
          });
        }
      } catch (error: any) {
        notify({
          title: "Error",
          text: new Error(error.toString()).message,
        });
      }
    }
  }

  const formattedAgentList = agentList?.map((agent) => {
    const updatedAgent = { ...agent };
    // updatedAgent.name = (
    //   <div className="flex flex-col items-start">
    //     <span className="text-xs text-gray-800">{updatedAgent.dsaName}</span>
    //     <span className="text-xs text-gray-500">{updatedAgent.agentCode}</span>
    //   </div>
    // );
    updatedAgent.name = `${updatedAgent.dsaName} - ${updatedAgent.agentCode}`;
    updatedAgent.value = updatedAgent.dsaID;

    return updatedAgent;
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <DropdownWithSearch
            searchFunc={onSearch}
            options={formattedAgentList}
            multiple={false}
            onChange={(data) => {
              setReassignDSAID(data.dsaID);
            }}
            placeholder={"Enter Name or Agent Code"}
            label="Reassign all Leads to"
            mode={window.innerWidth < 640 ? "reverse" : ""}
          ></DropdownWithSearch>
        </div>

        <div className="flex items-center justify-between flex-wrap md:flex-nowrap">
          <div className="mb-2 w-full md:w-auto md:mb-0">
            {isConfirmed && (
              <p className="text-xs text-red-600">
                This is a permanent action. Are you sure you want to proceed?
              </p>
            )}
          </div>

          <div className="flex w-full md:w-auto justify-end md:justify-start">
            <button
              className="text-sm mr-4 px-4 py-2 w-auto rounded-lg bg-white border border-gray-300 text-gray-700 flex items-center justify-center font-medium"
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
                setIsConfirmed(false);
              }}
            >
              Cancel
            </button>

            {isConfirmed && (
              <Button
                type="submit"
                color="primary"
              >
                Confirm
              </Button>
            )}
            {!isConfirmed && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  if (reassignDSAID) {
                    setIsConfirmed(true);
                  }
                }}
                color="primary"
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
