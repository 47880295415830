import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import React, { ReactNode } from "react";

interface IProps {
  title: ReactNode;
  titleIcon?: any;
  styles?: {
    container?: string;
    button?: string;
    panel?: string;
  };
  children: any;
  defaultOpen?: boolean;
}

export function Accordion({
  title,
  styles = {
    container: "",
    button: "",
    panel: "",
  },
  children,
  titleIcon,
  defaultOpen = false,
}: IProps) {
  return (
    <div className={styles.container || ""}>
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          /* Use the `open` state to conditionally change the direction of an icon. */
          <>
            <Disclosure.Button
              as="button"
              className={classNames(
                open ? "border-b border-gray-200" : "bg-gray-100",
                styles.button
              )}
            >
              <div className="flex items-center">
                {!!titleIcon && titleIcon}
                {title}
              </div>
              <ChevronRightIcon
                className={classNames(
                  open ? "rotate-90 transform" : "",
                  "w-4 h-4 text-gray-400"
                )}
              />
            </Disclosure.Button>
            <Disclosure.Panel className={styles.panel}>
              {children}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
