export const snakeToTitle = (s: string): string => {
  return s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : " " + d.toUpperCase()
  );
};

export const checkForUndefinedAndNull = (s: any) => {
  return s !== undefined && s !== null;
};

export const getAddressString = (currentAddress: string): string => {
  try {
    let result = "";
    const addressObj = JSON.parse?.(currentAddress);
    if (addressObj && Object.keys(addressObj)?.length > 0) {
      result =
        addressObj.line1 +
        ", " +
        addressObj.line2 +
        ", " +
        addressObj.city +
        ", " +
        addressObj.state +
        ", " +
        addressObj.pincode;
    }

    return result;
  } catch (ex) {
    console.log(new Error(ex).message);
    return "";
  }
};
