import { useState } from "react";
import SelectListBox from "../../atomic/Dropdown/SelectListBox";
import { LOAN_CANCELLATION_REASONS } from "../../../services/LoanApplication/constants";
import { useForm } from "react-hook-form";
import { LoanApplicationService } from "../../../services/LoanApplication";
import GetAuthContext from "../../../shared/context/AuthContext";
import { notify } from "../../../shared/context/AlertContext";
import Input from "../FormElements/Input";
import Button from "../../atomic/Button";
import { ErrorTextBox } from "../../atomic/ErrorBox";

interface IProps {
  setOpen: Function;
  reload: Function;
  loanApplicationID: string;
  pageName: string;
}
export default function CancelLoanApplicationModal({
  setOpen,
  reload,
  loanApplicationID = "",
  pageName,
}: IProps) {
  const [reason, setReason] = useState<{ name: string }>({ name: "" });
  const {
    handleSubmit,
    setError,
    register,
    clearErrors,
    formState: { errors },
  } = useForm();
  const { fetchToken } = GetAuthContext();

  async function onSubmit(formState) {
    if (!reason?.name) {
      setError("reason", {
        message: "required",
      });
      return;
    }

    const payload = {
      loanApplicationID,
      token: fetchToken(),
      reason:
        reason?.value == "other" ? formState?.comment?.trim() : reason.name,
      pageName,
    };

    const response = await LoanApplicationService.cancelApplication(payload);

    if (response.status) {
      setOpen(false);
      reload();
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="mb-4">
          <SelectListBox
            label="Reason"
            selected={reason}
            requiredIcon={true}
            options={LOAN_CANCELLATION_REASONS}
            setSelected={(data) => {
              clearErrors("reason");
              setReason(data);
            }}
            placeholder="Select Cancellation Reason"
          />
        </fieldset>
        {reason.value === "other" && (
          <fieldset className="mb-4">
            <Input
              register={register("comment", {
                required: true,
              })}
              type="text"
              label="Comment"
              requiredIcon={true}
              placeholder="Enter cancellation reason"
              error={errors.comment}
              errorText={
                errors.comment?.type === "required" ? "reason is required" : ""
              }
            />
          </fieldset>
        )}
        {errors.reason ? (
          <ErrorTextBox text={errors.reason.message}></ErrorTextBox>
        ) : null}
        <fieldset className="flex items-center justify-end mt-4 mb-1">
          <Button
            type="submit"
            disabled={!Boolean(reason?.value)}
            className="text-sm px-4 py-2 w-auto rounded-lg text-white bg-indigo-700 flex items-center justify-center font-medium"
          >
            Confirm
          </Button>
        </fieldset>
      </form>
    </div>
  );
}
