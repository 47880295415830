import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch, { UseFetchReturnType } from "../../../shared/hooks/useFetch";
import { Get } from "../../../shared/services/Fetch";
import GetAuthContext from "../../../shared/context/AuthContext";
import initialLeadsState from "./leadsState";
import { LeadDTO } from "../../../dtos/LeadDTO";
import { noHeadersResponse } from "../../../shared/utils/Global";

interface ILeadsContext {
  leadsDetails: LeadDTO;
  isLeadsDetailsLoading: boolean;
  fetchLeadsDetails: Function;
  pageName: string;
}

const LeadsContext = createContext({
  leadsDetails: initialLeadsState.currentLead,
  isLeadsDetailsLoading: false,
  fetchLeadsDetails: () => {},
  pageName: "",
} as ILeadsContext);

interface UserProfileResponse {
  userProfile: LeadDTO;
}

export const LeadsProvider = ({ children, pageName }: any) => {
  const additionalHeaders = new Headers();
  if (pageName) {
    additionalHeaders.append("page-header", pageName);
  } else {
    return noHeadersResponse();
  }
  const { fetchToken } = GetAuthContext();
  const { userID, programID } = useParams() as {
    userID: string;
    sourceEntityID: string;
    programID: string;
  };
  const queryParams = new URLSearchParams();
  queryParams.append("userID", userID);
  if (programID && programID !== "default") {
    queryParams.append("programID", programID);
  }
  const {
    data: leadsData,
    isLoading: isLeadsDetailsLoading,
  }: UseFetchReturnType<UserProfileResponse> = useFetch(
    `/masterDashboard/userProfile?${queryParams.toString()}`,
    additionalHeaders
  );
  const [leadsDetails, setLeadsDetails] = useState<LeadDTO>(
    leadsData?.userProfile || initialLeadsState.currentLead
  );

  const fetchLeadsDetails = async (customerID: string, programID?: string) => {
    const additionalHeaders = new Headers();
    if (pageName) {
      additionalHeaders.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const queryParams = new URLSearchParams();
    queryParams.append("userID", userID);
    if (programID && programID !== "default") {
      queryParams.append("programID", programID);
    }
    const resp = await Get<{ userProfile: LeadDTO }>(
      `/masterDashboard/userProfile?${queryParams.toString()}`,
      fetchToken(),
      additionalHeaders
    );
    if (resp?.data?.userProfile) {
      setLeadsDetails(resp?.data?.userProfile);
    }
    return resp;
  };

  useEffect(() => {
    if (leadsDetails) {
      // masterContext.removeRetriggerItem(params?.userID);
    }
  }, [leadsDetails]);

  return (
    <LeadsContext.Provider
      value={{
        leadsDetails,
        isLeadsDetailsLoading,
        fetchLeadsDetails,
        pageName,
      }}
    >
      {children}
    </LeadsContext.Provider>
  );
};

export const withUserContext = (
  WrappedComponent: React.FC
): (() => JSX.Element) => {
  const WithUserContext = ({ pageName }: { pageName: string }) => {
    return (
      <LeadsProvider pageName={pageName}>
        <WrappedComponent />
      </LeadsProvider>
    );
  };
  return WithUserContext;
};

const GetLeadsContext = (): ILeadsContext => {
  const context: any = useContext(LeadsContext);
  if (context === undefined || context === null)
    throw new Error("Context is not defined");
  return context;
};

export default GetLeadsContext;
