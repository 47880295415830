import GetAuthContext from "../../../shared/context/AuthContext";
import { notify } from "../../../shared/context/AlertContext";
import { LoanApplicationService } from "../../../services/LoanApplication";
import GetMixpanelContext from "../../../shared/context/MixpanelContext";

interface IProps {
  setOpen: Function;
  reload: Function;
  loanApplicationID: string;
  pageName: string;
}
export default function ApproveKYCModal({
  loanApplicationID,
  setOpen,
  reload,
  pageName,
}: IProps) {
  const { fetchToken } = GetAuthContext();
  const { trackUserAction } = GetMixpanelContext();

  async function onSubmit(isDoubleName: boolean) {
    const response = await LoanApplicationService.approveLoanKYC({
      loanApplicationID,
      isDoubleName,
      token: fetchToken(),
      pageName,
    });

    if (response.status) {
      trackUserAction("approve_kyc", {
        loanApplicationID,
        isDoubleName,
      });
      setOpen(false);
      reload();
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  }

  return (
    <div>
      <form>
        <fieldset className="flex items-center justify-end">
          <button
            type="button"
            className="text-sm px-4 py-2 w-auto rounded-lg text-white bg-indigo-700 flex items-center justify-center font-medium mr-4"
            onClick={() => onSubmit(true)}
          >
            Approve with Dual name Indemnity
          </button>
          <button
            type="button"
            className="text-sm px-4 py-2 w-auto rounded-lg text-white bg-indigo-700 flex items-center justify-center font-medium"
            onClick={() => onSubmit(false)}
          >
            Approve
          </button>
        </fieldset>
      </form>
    </div>
  );
}
