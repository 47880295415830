import { CreditLineService } from "../../services/CreditLine";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

export default async function creditLineTransactionsLoader({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const { loanID } = params;
  const response = await CreditLineService.getCreditLineTransactions({
    token: getToken(),
    loanApplicationID: loanID as string,
    pageName,
  });

  return {
    response,
  };
}
