import { CommonService } from "../../services/Common";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

export default async function apiStatusLoader({
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const token = getToken();

  const response = await CommonService.getSourceEntityList({
    pageName,
    token,
  });

  return {
    sourceEntityList: response.data?.sourceList,
  };
}
