import { IAuthService } from "./authService";
import { APIResponse } from "../../shared/services/APIResponse";
import { left, right } from "../../shared/core/Either";
import { ExportsHistoryResponseDTO } from "../../dtos/ExportsDTO";
import { BaseAPI } from "../../shared/services/BaseAPI";
import { Result } from "../../shared/core/Result";

export interface IExportsService {
  getExportHistory(
    page: number,
    limit: number
  ): Promise<APIResponse<ExportsHistoryResponseDTO>>;
}

/**
 * @deprecated
 */
export class ExportsService extends BaseAPI implements IExportsService {
  constructor(authService: IAuthService) {
    super(authService);
  }

  public async getExportHistory(
    page: number,
    limit: number
  ): Promise<APIResponse<ExportsHistoryResponseDTO>> {
    try {
      const response = await this.get(
        `/masterDashboard/getExportHistory?limit=${limit}&page=${page}`
      );
      return right(Result.ok<ExportsHistoryResponseDTO>(response.data));
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }

  public async donwloadMISData({
    startDate,
    endDate,
    sourceEntityId,
  }: {
    startDate: string;
    endDate: string;
    sourceEntityId: string;
  }): Promise<APIResponse<ExportsHistoryResponseDTO>> {
    try {
      const response = await this.get(
        `/masterDashboard/downloadMIS?from=${startDate}&to=${endDate}&sourceEntityID=${sourceEntityId}`
      );
      if (response.status) {
        return right(Result.ok<ExportsHistoryResponseDTO>(response.data));
      } else {
        return left(response.error ? response.error : "Connection failed");
      }
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }

  public async downloadLoanApplicationMIS({
    startDate,
    endDate,
    sourceEntityId,
  }: {
    startDate: string;
    endDate: string;
    sourceEntityId: string;
  }): Promise<APIResponse<any>> {
    try {
      const response = await this.get(
        `/masterDashboard/downloadLoanApplicationMIS?from=${startDate}&to=${endDate}&sourceEntityID=${sourceEntityId}`
      );
      if (response.status) {
        return right(Result.ok<any>(response.data));
      } else {
        return left(response.error ? response.error : "Connection failed");
      }
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }

  public async downloadRejectedMIS({
    startDate,
    endDate,
    sourceEntityId,
  }: {
    startDate: string;
    endDate: string;
    sourceEntityId: string;
  }): Promise<APIResponse<any>> {
    try {
      const response = await this.get(
        `/masterDashboard/downloadRejectedMIS?from=${startDate}&to=${endDate}&sourceEntityID=${sourceEntityId}`
      );
      if (response.status) {
        return right(Result.ok<any>(response.data));
      } else {
        return left(response.error ? response.error : "Connection failed");
      }
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }

  public async disqualificationReport({
    startDate,
    endDate,
    productType,
    dateType,
  }: {
    startDate: string;
    endDate: string;
    productType: string;
    dateType: string;
  }): Promise<APIResponse<any>> {
    try {
      const response = await this.get(
        `/masterDashboard/disqualificationReport?from=${startDate}&to=${endDate}&productType=${productType}&dateFilter=${dateType}`
      );
      if (response.status) {
        return right(Result.ok<any>(response.data));
      } else {
        return left(response.error ? response.error : "Connection failed");
      }
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }

  public async documentReport({
    startDate,
    endDate,
    dateType,
  }: {
    startDate: string;
    endDate: string;
    dateType: string;
  }): Promise<APIResponse<any>> {
    try {
      const response = await this.get(
        `/masterDashboard/documentReport?fromDate=${startDate}&toDate=${endDate}&dateFilter=${dateType}`
      );
      if (response.status) {
        return right(Result.ok<any>(response.data));
      } else {
        return left(response.error ? response.error : "Connection failed");
      }
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }

  public async loanStatusReport({
    startDate,
    endDate,
    productType,
    dateType,
  }: {
    startDate: string;
    endDate: string;
    productType: string;
    dateType: string;
  }): Promise<APIResponse<any>> {
    try {
      const response = await this.get(
        `/masterDashboard/loanStatusReport?fromDate=${startDate}&toDate=${endDate}&productType=${productType}&dateFilter=${dateType}`
      );
      if (response.status) {
        return right(Result.ok<any>(response.data));
      } else {
        return left(response.error ? response.error : "Connection failed");
      }
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }

  public async insuranceReport({
    startDate,
    endDate,
    dateType,
  }: {
    startDate: string;
    endDate: string;
    dateType: string;
  }): Promise<APIResponse<any>> {
    try {
      const response = await this.get(
        `/masterDashboard/insuranceReport?fromDate=${startDate}&toDate=${endDate}&dateFilter=${dateType}`
      );
      if (response.status) {
        return right(Result.ok<any>(response.data));
      } else {
        return left(response.error ? response.error : "Connection failed");
      }
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }

  public async employmentReport({
    startDate,
    endDate,
    dateType,
  }: {
    startDate: string;
    endDate: string;
    dateType: string;
  }): Promise<APIResponse<any>> {
    try {
      const response = await this.get(
        `/masterDashboard/employmentReport?fromDate=${startDate}&toDate=${endDate}&dateFilter=${dateType}`
      );
      if (response.status) {
        return right(Result.ok<any>(response.data));
      } else {
        return left(response.error ? response.error : "Connection failed");
      }
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }

  public async funnelReport({
    startDate,
    endDate,
    productType,
    dateType,
  }: {
    startDate: string;
    endDate: string;
    productType: string;
    dateType: string;
  }): Promise<APIResponse<any>> {
    try {
      const response = await this.get(
        `/masterDashboard/funnelReport?fromDate=${startDate}&toDate=${endDate}&productType=${productType}&dateFilter=${dateType}`
      );
      if (response.status) {
        return right(Result.ok<any>(response.data));
      } else {
        return left(response.error ? response.error : "Connection failed");
      }
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }
}
