import { GetMemberListResponseDTO } from "../../dtos/MembersDTO";
import { APIResponse } from "../../shared/services/APIResponse";
import { left, right } from "../../shared/core/Either";
import { IAuthService } from "./authService";
import { BaseAPI } from "../../shared/services/BaseAPI";
import { Result } from "../../shared/core/Result";

export interface GetMemberListRequestDTO {
  page: number;
  limit: number;
  email?: string;
  name?: string;
  sourceEntityId?: string;
}

export interface IMembersService {
  getMemberList({
    page,
    limit,
    email,
    name,
    sourceEntityId,
  }: GetMemberListRequestDTO): Promise<APIResponse<GetMemberListResponseDTO>>;
}

export class MembersService extends BaseAPI implements IMembersService {
  constructor(authService: IAuthService) {
    super(authService);
  }

  public async getMemberList({
    page,
    limit,
    email,
    name,
    sourceEntityId,
  }: GetMemberListRequestDTO): Promise<APIResponse<GetMemberListResponseDTO>> {
    try {
      const response = await this.get(
        `/masterDashboard/listMembers?limit=${limit}&page=${page}${
          sourceEntityId ? `&sourceEntityIDs=${sourceEntityId}` : ""
        }${email ? `&email=${email}` : ""}${name ? `&name=${name}` : ""}`
      );
      return right(Result.ok<GetMemberListResponseDTO>(response.data));
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }

  public async resetPassword(email: string): Promise<APIResponse<any>> {
    try {
      const response = await this.post(`/masterDashboard/resetPassword`, {
        email,
      });
      if (response.status) {
        return right(Result.ok<any>(response.data));
      } else {
        return left(response.error ? response.error : "Connection failed");
      }
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }

  public async deleteMember(email: string): Promise<APIResponse<any>> {
    try {
      const response = await this.post(`/masterDashboard/deleteMember`, {
        email,
      });
      if (response.status) {
        return right(Result.ok<any>(response.data));
      } else {
        return left(response.error ? response.error : "Connection failed");
      }
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }
}
