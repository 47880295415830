export type FinboxToken = string;

export interface IAuthService {
  isAuthenticated(): boolean;
  getToken(): FinboxToken | null;
  setToken(token: FinboxToken): void;
  removeToken(): void;
}

export class AuthService implements IAuthService {
  public static finboxToken: string = "finbox-dashboard-token";
  public accessToken: FinboxToken | null;

  constructor() {
    this.accessToken = this.getToken();
  }

  public getToken() {
    let value = null;
    try {
      value = localStorage.getItem(AuthService.finboxToken) || "";
    } catch (error) {}
    return value;
  }

  public setToken(token: FinboxToken): void {
    localStorage.setItem(AuthService.finboxToken, token);
  }

  public removeToken(): void {
    localStorage.removeItem(AuthService.finboxToken);
  }

  isAuthenticated(): boolean {
    return this.getToken() !== null;
  }
}
