import classNames from "classnames";
import { useMatches, NavLink, useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useRef, useState } from "react";
import { Tab } from "../interface";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import React from "react";
import ScrollButton from "./ScrollButton";

interface IProps {
  tabs: Array<Tab>;
  baseURL: string;
  styles?: {
    container?: string;
    tab?: string;
  };
  hasScroll?: boolean;
}

function isInViewport(element) {
  const rect = element?.getBoundingClientRect();

  return rect
    ? rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
    : false;
}

function TabsOne({
  tabs = [],
  baseURL = "",
  styles = {},
  hasScroll = false,
}: IProps) {
  const navigate = useNavigate();
  const { sourceEntityID, userID } = useParams();
  const navbarRef = useRef();
  const matches = useMatches();
  const [scrollPosition, setScrollPosition] = useState(0);

  function onMobileSelect(e: ChangeEvent<HTMLSelectElement>) {
    const tabHref = e.currentTarget.value;
    navigate(tabHref);
  }

  const tabLinks = navbarRef.current?.querySelectorAll("a");
  const isLastElementVisible = isInViewport(tabLinks?.[tabLinks?.length - 1]);
  const shouldShowScrollRightIndicator = !isLastElementVisible;
  const shouldShowScrollLeftIndicator = scrollPosition > 0;
  const scrollOffset = 150;

  return (
    <div>
      <div className="md:hidden">
        <label
          htmlFor="tabs"
          className="sr-only"
        >
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-200 text-sm font-medium text-gray-700"
          defaultValue={tabs?.find((tab) => tab?.current)?.name ?? ""}
          onChange={onMobileSelect}
        >
          {tabs?.map((tab) => {
            const url = `${baseURL}${tab.href}`;

            return (
              <option
                key={tab.name}
                value={url}
              >
                {tab?.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="hidden sm:block relative">
        <nav
          ref={navbarRef}
          className={classNames(
            "flex max-h-full [&>button]:hover:block",
            hasScroll ? "overflow-hidden" : "overflow-auto",
            styles.container || ""
          )}
          aria-label="Index"
        >
          <ScrollButton
            scrollOffset={scrollOffset}
            direction="left"
            navbarRef={navbarRef}
            shouldShow={hasScroll && shouldShowScrollLeftIndicator}
            setScrollPosition={setScrollPosition}
          />
          {tabs?.map((tab) => {
            const url = `${baseURL}${tab.href}`;
            const matchedRoute = matches.find((m) => m.pathname === url);
            const isActive = matchedRoute ? true : false;
            return (
              <NavLink
                key={tab.name}
                to={url}
                className={classNames(
                  isActive
                    ? "text-gray-900 border-b-2 border-indigo-500 hover:text-gray-600 bg-white border-r border-r-gray-200 "
                    : "text-gray-600 hover:text-gray-600 bg-gray-100 border-r border-gray-200",
                  "relative min-w-max overflow-hidden  py-3 px-6 text-sm font-normal text-center hover:bg-gray-50 focus:z-10 rounded-none  ",
                  styles.tab || ""
                )}
                aria-current={isActive ? "page" : undefined}
                onClick={(e) => {
                  e.currentTarget.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                  });
                }}
              >
                <span
                  className={classNames(
                    "text-sm",
                    isActive ? "font-medium" : ""
                  )}
                >
                  {tab.name}
                </span>
              </NavLink>
            );
          })}

          <ScrollButton
            scrollOffset={scrollOffset}
            direction="right"
            navbarRef={navbarRef}
            shouldShow={hasScroll && shouldShowScrollRightIndicator}
            setScrollPosition={setScrollPosition}
          />
        </nav>
      </div>
    </div>
  );
}

export default React.memo(TabsOne);
