import { checkForUndefinedAndNull } from "./TextUtil";

type Attribute<T> = keyof T | undefined;

export const transformDTOToQueryParamString = <
  T extends Record<keyof T, unknown>
>(
  object: T
) => {
  const urlSearchParams = new URLSearchParams();
  Object.keys(object).map((key) => {
    if (object[key]) {
      urlSearchParams.set(key, object?.[key]);
    }
  });

  return urlSearchParams.toString();
  // return Object.entries(object).reduce(
  //   (acc, [key, val]) =>
  //     `${acc}${checkForUndefinedAndNull(val) ? `${key}=${val}&` : ""}`,
  //   "?"
  // );
};
