import { createContext, useCallback, useContext, useReducer } from "react";
import { PAGES_LIST } from "../../shared/utils/constants";
import reducer from "./reducer";
import { statusOptions } from "./constant";
import { useSearchParams } from "react-router-dom";

function getDefaultStatus(status: "ACTIVE" | "INACTIVE" | null) {
  if (!status) {
    return statusOptions[0];
  }

  return statusOptions.find((s) => s.value === status);
}

type ContextType = {
  pageState: any;
  updatePageState: (value: any) => void;
  pageName: string;
};

const contextDefaultValue = {
  pageState: {},
  updatePageState: () => {},
  pageName: "",
};

export const AdminContext = createContext<ContextType>(contextDefaultValue);

export const AdminContextProvider = ({ children }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageName = PAGES_LIST.ADMIN_PAGE;
  const defaultState = {
    // global
    selectedSourceEntity: {},

    // user
    user: {},
    roles: {},
    allGroups: [],
    roleOptions: [],
    groupOptions: [],
    platformOptions: [],
    roleHelperDefinitions: [],
    editUser: {},
    deleteUser: [],
    currentModal: "ADD",
    isModalOpen: false,

    // policy
    maskingOptions: [],
    customPolicy: [],
    actionPolicy: {},
    policy: {},
    status: getDefaultStatus(
      searchParams.get("status") as "ACTIVE" | "INACTIVE" | null
    ),

    // edit role
    editRoles: {},
    backFormatData: [],
  };
  const [pageState, dispatch] = useReducer(reducer, defaultState);

  const updatePageState = useCallback(
    (...args) => {
      if (import.meta.env.REACT_APP_ENV !== "PROD") {
        console.log(...args);
        console.groupEnd();
      }
      dispatch(...args);
    },
    [dispatch]
  );

  return (
    <AdminContext.Provider value={{ pageState, updatePageState, pageName }}>
      {children}
    </AdminContext.Provider>
  );
};

export const GetAdminContext = () => {
  const context = useContext(AdminContext);
  if (!context) throw new Error("AdminContext is not defined");
  return context;
};
