import { defer } from "react-router";
import { LoanApplicationService } from "../../services/LoanApplication";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";
import { LoansListParams } from "./interfaces";

export default async function loanApplicationLoader({
  getToken,
  request,
  pageName,
}: CustomLoaderFunctionArgs) {
  const url = new URL(request.url);
  const token = getToken();
  let searchParams = Object.fromEntries(url.searchParams);

  const filterData = JSON.parse(
    window?.localStorage?.getItem("filters") || "{}"
  );
  const storedFilters = filterData?.loanApplication ?? {};

  let params: LoansListParams = {
    page: +searchParams.page || 1,
    limit: 10,
    searchQuery: url.searchParams.get("searchText") ?? "",
    searchType: url.searchParams.get("searchType") ?? "",
    filters: {},
    statusText: url.searchParams.getAll("statusText"),
    dateFilter: url.searchParams.has("dateType")
      ? (url.searchParams.get("dateType") as LoansListParams["dateFilter"])
      : "updated_at",
    from: url.searchParams.has("fromDate")
      ? new Date(url.searchParams.get("fromDate") as string)
      : null,
    to: url.searchParams.has("toDate")
      ? new Date(url.searchParams.get("toDate") as string)
      : null,
    sourceEntityIDs:
      storedFilters?.platform?.map((source) => source?.value) || [],
    productType: storedFilters?.productType?.value || "",
    taskStatus: storedFilters?.taskStatus?.value || "",
    dsaIDs: storedFilters?.agents?.map((agent: any) => agent?.value) ?? [],
  };

  return defer({
    response: LoanApplicationService.getLoanList({
      ...params,
      token,
      pageName,
    }),
    storedFilters,
  });
}
