import { useLoaderData, useParams } from "react-router-dom";
import { useState } from "react";
import { addHours, addMinutes, format, formatDistance } from "date-fns";
import { Get } from "../../shared/services/Fetch";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { LoanApplicationService } from "../../services/LoanApplication";
import GetAuthContext from "../../shared/context/AuthContext";
import { notify } from "../../shared/context/AlertContext";
import { PageProps } from "../../services/Common/interfaces";
import { noHeadersResponse } from "../../shared/utils/Global";

export async function loanNotesLoader({ params, getToken, pageName }: any) {
  const { loanID } = params;
  const headers = new Headers();
  if (pageName) {
    headers.append("page-header", pageName);
  } else {
    return noHeadersResponse();
  }
  const response = await Get(
    `/masterDashboard/notes?loanApplicationID=${loanID}`,
    getToken(),
    headers
  );

  return {
    notesResponse: response,
  };
}

function LoanNotes({ pageName }: PageProps) {
  const { loanID } = useParams();
  const { notesResponse } = useLoaderData();
  const { fetchToken } = GetAuthContext();
  const [notes, setNotes] = useState(notesResponse?.data?.notes ?? []);

  const refreshNotes = async () => {
    let response = await LoanApplicationService.getNotes({
      loanApplicationID: loanID!,
      token: fetchToken(),
      pageName,
    });
    if (response.status) {
      setNotes(response.data?.notes || []);
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  };

  return (
    <div className="px-4 overflow-auto">
      <div className="flex items-center text-base font-medium">
        <h3 className="text-sm text-gray-700 font-medium w-max border-b-2 border-indigo-500 py-2 pr-2">
          Notes
        </h3>
        <button
          className="flex items-center text-xs text-gray-500"
          title="Refresh Bank Statements"
          onClick={refreshNotes}
        >
          <ArrowPathIcon className="w-4 h-4 ml-4 mr-2"></ArrowPathIcon>
        </button>
      </div>

      <div className="mt-8">
        {notes?.map((note: any, noteIdx: number) => {
          let createdAt = addHours(new Date(note.createdAt), 5);
          createdAt = addMinutes(createdAt, 30);
          return (
            <div
              className="border border-gray-200 p-4 rounded-lg mb-2"
              key={noteIdx}
            >
              <div className="flex flex-col justify-center">
                <p className="text-xs mb-4">{note.note}</p>
                <p className="text-xs text-gray-600 mb-4">
                  By: {note.createdBy}
                </p>
                <p
                  className="text-xs text-gray-500"
                  title={format(createdAt, "dd MMM, yyyy - hh:mm aa")}
                >
                  Created {formatDistance(new Date(), createdAt)} ago
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default LoanNotes;
