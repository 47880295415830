import { useEffect, useState } from "react";
import GetAuthContext from "../context/AuthContext";

export type reload = () => Promise<any> | "";

export type UseFetchReturnType<T> = {
  data: T | null;
  status: boolean;
  error: string;
  isLoading: boolean;
  reload?: reload;
};

const useFetch = <T>(
  path: string,
  additionalHeaders?: Headers
): UseFetchReturnType<T> => {
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState<{
    data: null | T;
    error: string;
    status: boolean;
  }>({
    data: null,
    error: "",
    status: false,
  });
  const { fetchToken } = GetAuthContext();

  const headers = new Headers(additionalHeaders);
  headers.append("token", fetchToken());
  const defaultOptions: RequestInit = {
    method: "GET",
    headers,
    redirect: "follow",
  };

  const fetcher = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(
        import.meta.env.REACT_APP_API_ENDPOINT + path,
        defaultOptions
      );
      const json = await res.json();
      setState({
        data: json.status ? json.data : null,
        status: json.status,
        error: json.error,
      });
      setIsLoading(false);
      return json;
    } catch (error) {
      setIsLoading(false);
      setState({
        data: null,
        status: false,
        error: "Something went wrong. Please try again later.",
      });
    }
    return {
      data: null,
      status: false,
      error: "Something went wrong. Please try again later.",
    };
  };

  useEffect(() => {
    fetcher();
  }, [path]);
  return {
    data: state.data,
    status: state.status,
    error: state.error,
    isLoading,
    reload: fetcher,
  };
};

export default useFetch;
