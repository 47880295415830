import { User } from "./interfaces";

export const DefaultApiResponse = {
  status: false,
  data: null,
  error: "",
};

export const USER_INITIAL_STATE: User = {
  error: "",
  isAuthenticated: false,
  isLoggingIn: false,
  isLoggingInSuccess: false,
  isLoggingInFailure: false,
  token: "",
  hideKYC: false
}
