import { CommonService } from "../../services/Common";
import PartnersService from "../../services/Partners";
import {
  PAGE_TYPES,
  PRODUCT_TYPES,
  SEARCH_TYPES,
} from "../../services/Partners/constants";
import { FINBOX_USER } from "../../shared/context/AuthContext";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";
import usePartnersStore from "../Partners/store";
import LZString from "lz-string";

type Mode = "onboarded" | "pending" | "rejected";

export const STATUS_MAP: Record<Mode, number> = {
  pending: 0,
  rejected: 2,
  onboarded: 1,
};

export default async function partnersChartLoader({
  request,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  console.log("Chart loader called!");
  const url = new URL(request.url);
  const { getChannelsList } = usePartnersStore.getState();
  const searchParams = new URLSearchParams(url.search);
  const pageTypeParam = searchParams.get("pageType") || "chart";
  const productTypeParam = searchParams.get("productType") || "all";
  const searchTypeParam = searchParams.get("searchType") ?? "";
  const searchTextParam = searchParams?.get("searchText") ?? "";
  const token = getToken();
  let productTypes = searchParams.get("productType") || "all";
  if (productTypes == "all") {
    productTypes = "";
  }
  const finboxUser = JSON.parse(
    LZString.decompress(localStorage?.getItem(FINBOX_USER)) ?? "{}"
  );
  const userType = finboxUser?.userType ?? "";
  await getChannelsList({
    token,
    searchType: searchTypeParam,
    searchText: decodeURIComponent(searchTextParam),
    pages: [],
    productType: productTypes,
    node_id: searchParams.get("node_id"),
    pageName,
    type: "full",
    userType: userType!,
  });

  const productType =
    PRODUCT_TYPES.find((pType) => pType.value === productTypeParam) ||
    PRODUCT_TYPES[0];
  const pageType =
    PAGE_TYPES.find((p) => p.value == pageTypeParam) || PAGE_TYPES[0];
  const searchType =
    SEARCH_TYPES.find((p) => p.value == searchTypeParam) || SEARCH_TYPES[0];

  return {
    searchText: searchTextParam,
    searchType,
    pageType,
    productType,
  };
}
