import { SubmitHandler, useForm } from "react-hook-form";
import Button from "../../atomic/Button";
import { MouseEventHandler, ReactNode } from "react";

interface IProps {
  onConfirm: any;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
  confirmButtonText?: ReactNode;
  cancelButtontext?: ReactNode;
}

export default function ConfirmationModal({
  onConfirm,
  onCancel,
  children,
  confirmButtonText = "Yes",
  cancelButtontext = "No",
}: IProps) {
  const { handleSubmit } = useForm();

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(onConfirm)}>
        {children}
        <fieldset className="flex items-center justify-end mt-4">
          <Button className="mr-4" type="button" onClick={onCancel}>
            {cancelButtontext}
          </Button>
          <Button type="submit" color="primary">
            {confirmButtonText}
          </Button>
        </fieldset>
      </form>
    </div>
  );
}
