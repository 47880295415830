import Cancel from "./Cancel";
import Change from "./Change";
import Timeline from "./Timeline";
import Intiate from "./Intiate";
import { DeviationProps } from "./interface";

export default function SliderContainer(props: DeviationProps) {
  let componentToRender;

  switch (props?.currentActiveSlider) {
    case "INITIATE":
      componentToRender = <Intiate {...props} />;
      break;
    case "CANCEL":
      componentToRender = <Cancel {...props} />;
      break;
    case "CHANGE":
      componentToRender = <Change {...props} />;
      break;
    case "TIMELINE":
      componentToRender = <Timeline {...props} />;
      break;
    default:
      componentToRender = null; // Handle invalid currentActiveSlider value
      break;
  }

  return <>{componentToRender}</>;
}
