import { IAuthService } from "./authService";
import { APIResponse } from "../../shared/services/APIResponse";
import { left, right } from "../../shared/core/Either";
import { BaseAPI } from "../../shared/services/BaseAPI";
import { Result } from "../../shared/core/Result";
import { transformDTOToQueryParamString } from "../../shared/utils/APIUtil";
import {
  GetActiveCreditLineBySearchQueryRequestDTO,
  GetActiveCreditLineRequestDTO,
  GetActiveCreditLineResponseDTO,
} from "../../dtos/ActiveCreditLineDTO";
import { noHeadersResponse } from "../../shared/utils/Global";

export interface IActiveCreditLineService {
  getActiveLines(
    dto: GetActiveCreditLineRequestDTO,
    pageName: string
  ): Promise<APIResponse<GetActiveCreditLineResponseDTO>>;
  getActiveLinesBySearchQuery(
    dto: GetActiveCreditLineBySearchQueryRequestDTO
  ): Promise<APIResponse<GetActiveCreditLineResponseDTO>>;
}

export class ActiveCreditLineService
  extends BaseAPI
  implements IActiveCreditLineService
{
  constructor(authService: IAuthService) {
    super(authService);
  }

  async getActiveLines(
    dto: GetActiveCreditLineRequestDTO,
    pageName: string
  ): Promise<APIResponse<GetActiveCreditLineResponseDTO>> {
    try {
      const headers: any = {};
      if (pageName) {
        headers["page-header"] = pageName;
      } else {
        return noHeadersResponse();
      }
      const response = await this.get(
        `/masterDashboard/getCreditLineList?${transformDTOToQueryParamString<GetActiveCreditLineRequestDTO>(
          dto
        )}`,
        undefined,
        headers
      );
      if (response.status) {
        return right(Result.ok<any>(response.data));
      } else {
        return left(response.error ? response.error : "Connection failed");
      }
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }

  async getActiveLinesBySearchQuery(
    dto: GetActiveCreditLineBySearchQueryRequestDTO,
    pageName: string
  ): Promise<APIResponse<GetActiveCreditLineResponseDTO>> {
    try {
      const headers: any = {};
      if (pageName) {
        headers["page-header"] = pageName;
      } else {
        return noHeadersResponse();
      }
      const response = await this.get(
        `/masterDashboard/searchOnlyGetCreditLineList?${transformDTOToQueryParamString<
          GetActiveCreditLineBySearchQueryRequestDTO | {}
        >(dto)}`,
        undefined,
        headers
      );
      if (response.status) {
        return right(Result.ok<any>(response.data));
      } else {
        return left(response.error ? response.error : "Connection failed");
      }
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }
}
