import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";
import { FinBoxResponse } from "../../shared/services/APIResponse";
import { Get } from "../../shared/services/Fetch";
import { noHeadersResponse } from "../../shared/utils/Global";

export default async function userPartnerDetailsLoader({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs): Promise<FinBoxResponse<any>> {
  const { userID } = params;
  const headers = new Headers();
  if (pageName) {
    headers.append("page-header", pageName);
  } else {
    return noHeadersResponse();
  }

  return await Get<any>(
    `/masterDashboard/partnerData?userID=${userID}`,
    getToken(),
    headers
  );
}
