import { DropdownWithSearchOption } from "../../components/atomic/DropdownWithSearch/interface";
import { AddEditFormTypes, ProductTypeOption } from "./interfaces";

export const PARTNER_TABLE_COLUMNS = [
  {
    key: "agentCode",
    name: "Agent Code",
    showInMobile: true,
    width: "120px",
  },
  {
    key: "agentName",
    name: "Agent Name",
    showInMobile: true,
    width: "120px",
  },
  {
    key: "nodeType",
    name: "Type",
    showInMobile: true,
    width: "140px",
    // rowStyles: "text-center",
    // headerStyles: "text-center",
  },
  {
    key: "mobile",
    name: "Mobile",
    showInMobile: true,
    width: "100px",
  },
  {
    key: "email",
    name: "Email",
    showInMobile: true,
    width: "100px",
  },
  {
    key: "status",
    name: "Status",
    showInMobile: true,
    width: "100px",
  },
  {
    key: "product",
    name: "Product Type",
    showInMobile: true,
    width: "100px",
  },
  {
    key: "addedOn",
    name: "Created At",
    showInMobile: true,
    width: "100px",
  },
  {
    key: "creditLink",
    name: "Credit Link",
    showInMobile: true,
    width: "200px",
  },
  {
    key: "options",
    name: "",
    showInMobile: true,
    width: "60px",
    rowStyles: "!overflow-visible",
  },
];

export const PRODUCT_TYPES: Array<ProductTypeOption> = [
  {
    value: "all",
    name: "All Product Types",
  },
  {
    value: "personal_loan",
    name: "Personal Loan",
  },
  {
    value: "business_loan",
    name: "Business Loan",
  },
  {
    value: "credit_line",
    name: "CreditLine",
  },
];

export const BULK_ACTION_CONFIG = {
  BULK_ADD_AGENTS: {
    title: "Bulk Create Agents",
    endpoint: "bulkAddSDSA",
    sampleFile:
      "https://finbox-cdn.s3.ap-south-1.amazonaws.com/platform-dashboard/bulk-create-agents-sample-new.csv",
  },
  BULK_REASSIGN_AGENTS: {
    title: "Bulk Reassign Agents",
    endpoint: "bulkReassignAgents",
    sampleFile:
      "https://finbox-cdn.s3.ap-south-1.amazonaws.com/platform-dashboard/bulk-reassign-agents-sample.csv",
  },
  BULK_DELETE_AGENTS: {
    title: "Bulk Delete Agents",
    endpoint: "bulkDeleteAgents",
    sampleFile:
      "https://finbox-cdn.s3.ap-south-1.amazonaws.com/platform-dashboard/bulk-delete-agents-sample.csv",
  },
  BULK_REASSIGN_MANAGERS: {
    title: "Change Reporting Managers",
    endpoint: "bulkReassignManagers",
    sampleFile:
      "https://finbox-cdn.s3.ap-south-1.amazonaws.com/platform-dashboard/bulk-change-reporting-managers-sample.csv",
  },
};

export const PARTNERS_STATUS = {
  "0": "Inactive",
  "1": "Active",
  "2": "Rejected",
  "3": "Deactivated",
  "4": "Deleted",
};

export const DSA_STATUS = [
  { value: "", name: "All Agents" },
  { value: "1", name: "Active" },
  { value: "3", name: "Deactivated" },
] as const;

export const DSA_TYPE_LABELS = {
  organization: "Partner",
  individual: "Partner Employee",
  internal_employee: "Internal Employee",
  collaborators: "Collaborator",
} as const;

export const MODALS = {
  ADD_AGENT: "ADD_AGENT",
  EDIT_AGENT: "EDIT_AGENT",
  DEACTIVATE_AGENT: "DEACTIVATE_AGENT",
  DELETE_AGENT: "DELETE_AGENT",
  REACTIVATE_AGENT: "REACTIVATE_AGENT",
  REASSIGN_MANAGER: "REASSIGN_MANAGER",
  SHOW_COLLABORATORS: "SHOW_COLLABORATORS",
  ADD_COLLABORATORS: "ADD_COLLABORATORS",
  BULK_REASSIGN_LEADS: "BULK_REASSIGN_LEADS",
  BULK_DELETE_AGENTS: "BULK_DELETE_AGENTS",
  BULK_REASSIGN_MANAGERS: "BULK_REASSIGN_MANAGERS",
  BULK_ADD_AGENTS: "BULK_ADD_AGENTS",
} as const;

export const PAGE_TYPES = [
  {
    name: "Chart",
    value: "chart",
  },
  {
    name: "List",
    value: "list",
  },
];

export const SEARCH_TYPES = [
  {
    name: "Mobile",
    value: "mobile",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Agent Code",
    value: "partnerCode",
  },
  {
    name: "Agent Name",
    value: "partnerName",
  },
];

export const DSA_NODE_TYPES = {
  INDIVIDUAL: "individual",
  ORGANIZATION: "organization",
  INTERNAL_EMPLOYEE: "internal_employee",
} as const;

export const CREATE_EDIT_FORM_TYPES: Array<
  DropdownWithSearchOption<AddEditFormTypes>
> = [
  {
    name: "Partner Employee",
    value: "individual",
  },
  {
    name: "Partner",
    value: "organization",
  },
  {
    name: "Internal Employee",
    value: "internal_employee",
  },
];
