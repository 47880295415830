import { useRef } from "react";
import CopyText from "../../atomic/CopyText";

interface IProps {
  webLink?: string;
  setOpen: React.Dispatch<boolean>;
}

export default function GenerateLinkModal({
  setOpen,
  webLink,
}: IProps): JSX.Element {
  const linkTextRef = useRef<HTMLInputElement>(null);

  // async function onCopyClick() {
  //   const value = linkTextRef.current?.value || "";
  //   if (value) {
  //     const copySuccess = await copyToClipboard(value);
  //     if (copySuccess) {
  //       notify({
  //         title: "Success",
  //         text: "Copied!",
  //         type: "success",
  //       });
  //     } else {
  //       notify({
  //         title: "Error",
  //         text: "Copy Failed!",
  //         type: "error",
  //       });
  //     }
  //   } else {
  //     notify({
  //       title: "Info",
  //       text: "No text to copy",
  //       type: "info",
  //     });
  //   }
  // }

  return (
    <div>
      <div className="py-4">
        <CopyText
          styles={{
            container: "bg-gray-100",
            input:
              "text-gray-800 bg-transparent w-full border border-dashed border-gray-300 rounded-lg rounded-r-none border-r-0 py-3 h-auto",
            button:
              "bg-indigo-300 border border-indigo-300 text-gray-50 py-3 h-auto",
          }}
          text={webLink || ""}
        ></CopyText>
        {/* <div className="flex items-center justify-between border border-dashed border-gray-300 p-4 rounded-lg bg-gray-100">
          <div className="text-xs text-gray-700">{webLink}</div>
          <Button className="text-gray-500" onClick={onCopyClick}>
            <ClipboardIcon className="w-5 h-5 ml-4" />
          </Button>
          <input
            type="text"
            className="hidden"
            ref={linkTextRef}
            value={webLink}
          />
        </div> */}
      </div>
    </div>
  );
}
