import React from "react";
import { useParams } from "react-router";
import { ShareIcon } from "@heroicons/react/24/outline";

import Button from "../../components/atomic/Button";
import { notify } from "../../shared/context/AlertContext";
import GetAuthContext from "../../shared/context/AuthContext";
import { LoanApplicationService } from "../../services/LoanApplication";
import PopoverPanel from "../../components/atomic/Dropdown/PopoverPanel";
import DropdownWithSearch from "../../components/atomic/DropdownWithSearch";

interface optionType {
  id: number;
  name: string;
  value: string;
  categoryL2?: any;
  optionalID: string;
}

type Props = {
  reload: () => void;
  pageName: string;
  document: {
    comment: string;
    docID: string;
    docType: string;
    urls: string[];
  };
};

export default function Share(props: Props) {
  const { fetchToken } = GetAuthContext();
  const { loanID } = useParams();
  const { pageName, document } = props;
  const [categoryOptions, setCategoryOptions] = React.useState(
    [] as optionType[]
  );
  const [selectedCategory, setSelectedCategory] = React.useState(
    {} as optionType
  );
  const [selectedSubCategory, setSelectedSubCategory] = React.useState(
    {} as optionType
  );

  const optionHandler = async () => {
    const params = {
      pageName,
      token: fetchToken(),
      loanApplicationID: loanID,
      serviceType: "additional_document_dropdown",
    };
    const response = await LoanApplicationService.getAdditionalDocsOpt(params);
    if (response?.status) {
      const options =
        response?.data?.map((d) => {
          return {
            value: d?.optionalID,
            ...d,
          };
        }) ?? [];
      setCategoryOptions(options as optionType[]);
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  };

  const subCategoryOtions = React.useMemo(() => {
    return (
      selectedCategory?.categoryL2?.map((sc) => {
        return {
          ...sc,
          value: sc?.optionalID,
        };
      }) ?? []
    );
  }, [selectedCategory]);

  const pushHandler = async () => {
    const params = {
      token: fetchToken(),
      pageName: pageName,
      payload: {
        loanApplicationID: loanID,
        docsInfo: {
          docID: document?.docID,
          comment: document?.comment,
          categoryID: selectedCategory?.value,
          subCategoryID: String(selectedSubCategory?.value),
        },
      },
    };
    const response = await LoanApplicationService.pushAdditionalDocs(params);
    if (response?.status) {
      notify({
        type: "success",
        title: "Success",
        text: "Document Shared Successfully!",
      });
      props.reload();
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  };

  return (
    <PopoverPanel
      closeAction={false}
      onClick={optionHandler}
      tooltipContent="Send document to LOS"
      icon={<ShareIcon className="h-4 w-4 !text-inherit hover:text-inherit" />}
      styles={{
        childContainer: "overflow-visible shadow-none !ring-0",
        panel:
          "w-full !w-[400px] !-left-32 border rounded-xl !p-4 bg-white !shadow-lg",
        button:
          "!px-2 !py-2 border !text-[#8D8D8E] !border-[#8D8D8E] hover:!border-indigo-700 hover:!text-indigo-700 bg-white !shadow-[0_1px_2px_0px_rgba(6, 24, 40, 0.05)]",
      }}
    >
      <div className="relative">
        <div className="text-sm font-medium text-[#373737]">
          You are pushing this document to LOS
        </div>
        <div className=" text-[#626262] font-normal text-xs">
          Please select the category of the document
        </div>
        <fieldset className="mt-4">
          <DropdownWithSearch
            key="Category"
            label="Category"
            multiple={false}
            hasSearch={false}
            hasSelectAll={false}
            placeholder="Select category"
            options={categoryOptions ?? []}
            selectedItems={selectedCategory}
            styles={{ container: "!w-full" }}
            onChange={(entity: any) => setSelectedCategory(entity)}
          />
        </fieldset>
        <fieldset className="mt-4">
          <DropdownWithSearch
            multiple={false}
            key="Sub-Category"
            hasSearch={false}
            label="Sub-Category"
            hasSelectAll={false}
            options={subCategoryOtions}
            placeholder="Select sub-category"
            styles={{ container: "!w-full" }}
            disabled={!selectedCategory?.value}
            selectedItems={selectedSubCategory}
            onChange={(entity: any) => setSelectedSubCategory(entity)}
          />
        </fieldset>
        <Button
          color="primary"
          onClick={pushHandler}
          className="w-full mt-5"
          disabled={!selectedCategory?.value || !selectedSubCategory?.value}
        >
          Push to LOS
        </Button>
      </div>
    </PopoverPanel>
  );
}
