import { DsaAgent } from "../../pages/Partners/interface";
import { FinBoxResponse } from "../../shared/services/APIResponse";
import { Delete, Get, Post } from "../../shared/services/Fetch";
import { Agent } from "../Common/interfaces";
import { noHeadersResponse } from "../../shared/utils/Global";
import { ServiceMethodArgs } from "../LoanApplication/interfaces";
import {
  Collaborator,
  CreateSdsaRequestParams,
  DeactivateNodeRequestParams,
  Partner,
  ReassignManagerRequestParams,
} from "./interfaces";

export default class PartnersService {
  static async getAgentList({
    token,
    searchQuery = "",
    productType = "",
    pageName,
    limit,
    sourceEntityID,
  }: ServiceMethodArgs<{
    searchQuery?: string;
    productType?: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    let URL = "/masterDashboard/agentList";
    let searchParams = new URLSearchParams();
    if (searchQuery) {
      searchParams.append("searchQuery", searchQuery);
    }
    if (productType) {
      searchParams.append("productType", productType);
    }
    if (sourceEntityID) {
      searchParams.append("sourceEntityID", sourceEntityID);
    }
    if (limit) {
      searchParams.append("limit", limit);
    }

    if (Array.from(searchParams.entries()).length > 0) {
      URL += `?${searchParams.toString()}`;
    }

    return await Get<{ agentList: Array<Agent> }>(URL, token, headers);
  }

  static async getListPartners({
    token,
    limit,
    page,
    status,
    searchType,
    productType,
    searchText,
    pageName,
  }: ServiceMethodArgs<{
    limit: number;
    page: number;
    status?: "0" | "1" | "3";
    searchType: string;
    searchText: string;
    productType: string;
    filters: Array<string>;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const searchParams = new URLSearchParams();
    if (limit) {
      searchParams.append("limit", String(limit));
    }

    if (page) {
      searchParams.append("page", String(page));
    }

    if (typeof status !== "undefined") {
      searchParams.append("status", String(status));
    }

    if (searchType && searchText) {
      searchParams.append(searchType, searchText);
    }

    if (productType && productType !== "all") {
      searchParams.append("productType", productType);
    }
    return Get<{ partners: Array<Partner>; total: number }>(
      "/masterDashboard/listPartners" + "?" + searchParams.toString(),
      token,
      headers
    );
  }

  static async listCollaborators({
    token,
    dsaID,
    sourceEntityID,
  }: ServiceMethodArgs<{
    dsaID: string;
    sourceEntityID: string;
  }>) {
    const params = new URLSearchParams();
    params.append("dsaID", dsaID);
    params.append("sourceEntityID", sourceEntityID);

    return Get<{ collaborators: Array<Collaborator> }>(
      `/masterDashboard/listCollaborators?${params.toString()}`,
      token
    );
  }

  static async listChannelHierarchy({
    token,
    searchType = "",
    searchText = "",
    pages = [],
    productType = "all",
    dsaID,
    pageName,
  }: ServiceMethodArgs<{
    searchType: string;
    searchText: string;
    pages: Array<string>;
    productType: string;
    dsaID: string | null;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const params = new URLSearchParams();

    if (searchType && searchText) {
      params.append(searchType, searchText);
    }

    if (productType) {
      params.append("productType", productType);
    }

    if (pages) {
      pages.map((page) => {
        params.append("pages", page);
      });
    }

    if (dsaID) {
      params.append("dsaID", dsaID);
    }

    return Get(
      `/masterDashboard/listChannelHierarchy?${params.toString()}`,
      token,
      headers
    );
  }

  static async getAgentDetails({
    token,
    dsaID,
    pageName,
  }: ServiceMethodArgs<{
    dsaID: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const params = new URLSearchParams();
    params.append("dsaID", dsaID);

    return Get<DsaAgent>(
      `/masterDashboard/getAgentDetails?${params.toString()}`,
      token,
      headers
    );
  }

  static async getListCollaborators({
    token,
    dsaID,
    pageName,
  }: ServiceMethodArgs<{
    dsaID: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const params = new URLSearchParams();
    params.append("dsaID", dsaID);

    return Get(
      `/masterDashboard/listCollaborators?${params.toString()}`,
      token,
      headers
    );
  }

  static async addCollaborators({
    token,
    dsaID,
    sourceEntityID,
    payload,
    pageName,
  }: ServiceMethodArgs<{
    dsaID: string;
    sourceEntityID: string;
    payload: {
      name: string;
      email: string;
      mobile: string;
      channelName: string;
      location: string;
      designation: string;
      collaboratorType: string;
      dsaID: string;
    };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    // const params = new URLSearchParams();

    return Post(
      `/masterDashboard/createCollaborator`,
      token,
      {
        ...payload,
        dsaID: dsaID,
      },
      headers
    );
  }

  static async createSDSA({
    token,
    name,
    agentCode,
    email,
    mobile,
    parentID,
    location,
    designation,
    channelName,
    collaboratorType,
    nodeType,
    pageName,
    isMasked,
  }: ServiceMethodArgs<CreateSdsaRequestParams>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const payload = {
      name,
      agentCode,
      email,
      mobile: mobile || "",
      parentID,
      location,
      designation,
      channelName,
      collaboratorType,
      nodeType,
      isMasked,
    };

    return Post<{ collaborators: Array<Collaborator>; message: string }>(
      `/masterDashboard/createSDSA`,
      token,
      payload,
      headers
    );
  }

  static async editSDSA({
    token,
    name,
    agentCode,
    mobile,
    managerDsaID,
    dsaUserType,
    location,
    channelName,
    designation,
    nodeType,
    dsaID,
    email,
    pageName,
    isMasked,
  }: ServiceMethodArgs<CreateSdsaRequestParams>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const payload = {
      name,
      agentCode,
      mobile: mobile || "",
      managerDsaID,
      dsaUserType,
      location,
      channelName,
      designation,
      nodeType,
      dsaID,
      email,
      isMasked,
    };

    return Post<{ collaborators: Array<Collaborator>; message: string }>(
      `/masterDashboard/editSDSA`,
      token,
      payload,
      headers
    );
  }

  static async reassignManager({
    token,
    dsaID,
    managerID,
    pageName,
  }: ServiceMethodArgs<ReassignManagerRequestParams>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const payload = {
      dsaID,
      managerID,
    };
    return Post(`/masterDashboard/reassignManager`, token, payload, headers);
  }

  static async deactivateNode({
    token,
    dsaID,
    email,
    sourceEntityID,
    pageName,
  }: ServiceMethodArgs<DeactivateNodeRequestParams>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const payload = {
      dsaID,
      email,
      sourceEntityID,
    };
    return Post(`/masterDashboard/deleteCollaborator`, token, payload, headers);
  }

  static async deleteAgent({
    token,
    agentToDelete,
    agentToReassign,
    pageName,
  }: ServiceMethodArgs<{
    agentToDelete: string;
    agentToReassign: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    return await Delete(
      `/masterDashboard/deleteAgent?deletedDSAID=${agentToDelete}&reassignDSAID=${agentToReassign}`,
      token,
      {},
      headers
    );
  }

  static async deactivateAgent({
    token,
    agentToDeactivate,
    pageName,
  }: ServiceMethodArgs<{
    agentToDeactivate: any;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    return await Post(
      `/masterDashboard/deactivateAgent?dsaID=${agentToDeactivate}`,
      token,
      {},
      headers
    );
  }

  static async reactivateAgent({
    token,
    dsaID,
    pageName,
  }: ServiceMethodArgs<{
    dsaID: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    return await Post(
      `/masterDashboard/reactivateAgent`,
      token,
      {
        dsaID,
      },
      headers
    );
  }

  static async deleteCollaborator<T>({
    dsaID,
    mobile,
    token,
    sourceEntityID,
    pageName,
  }: ServiceMethodArgs<{
    dsaID: string;
    mobile: string;
    sourceEntityID: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    return await Post<T>(
      `/masterDashboard/deleteCollaborator`,
      token,
      {
        dsaID,
        mobile,
        sourceEntityID,
      },
      headers
    );
  }

  static async getOrganizationDsaMetadataMapping({
    token,
    pageName,
  }: ServiceMethodArgs<{}>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    return await Get(
      `/masterDashboard/getOrganizationDsaMetadataMapping`,
      token,
      headers
    );
  }
}
