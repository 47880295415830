import { lazy } from "react";
import { ACTIONS_LIST, PAGES_LIST } from "./utils/constants";
import { composedLoader } from "./services/Fetch";
import {
  LoaderFunction,
  RouteObject,
  ShouldRevalidateFunction,
} from "react-router";
import salesDispositionLoader from "../pages/SalesDisposition/loader";
import userPartnerDetailsLoader from "../pages/UserPartnerDetails/loader";
import userBankStatementsLoader from "../pages/UserBankStatements/loader";
import { loanApplicationDetailsLoader } from "../pages/LoanApplicationDetails";
import { userDSAListLoader } from "../pages/CreateNewLead";
import PrivateComponent from "../components/composed/PrivateComponent";
import { loanTimelineLoader } from "../pages/LoanTimeline";
import loanKYCLoader from "../pages/LoanKYC/loader";
import { loanOfferLoader } from "../pages/LoanOffer";
import creditLineDetailsLoader from "../pages/CreditLineDetails/loader";
import creditLineTransactionsLoader from "../pages/CreditLineTransactions/loader";
import loanBankInfoLoader from "../pages/LoanBankInfo/loader";
import { loanPaymentsLoader } from "../pages/LoanPayments";
import { loanAdditionalDocsLoader } from "../pages/LoanAdditionalDocs";
import { loanNotesLoader } from "../pages/LoanNotes";
import leadsLoader from "../pages/Leads/loader";
import loanApplicationLoader from "../pages/LoanApplications/loader";
import reportsLoader from "../pages/Reports/loader";
import allTransactionsLoader from "../pages/AllTransactions/loader";
import { activeLineLoader } from "../pages/ActiveLine/states";
import overdraftLoader from "../pages/Overdraft/loader";
import selectedIntegrationsLoader from "../pages/SelectedIntegrations/loader";
import { getThemeListLoader } from "../pages/ThemeConfigTab/loader";
import { creditLinkLoader } from "../pages/CreditLinkTab/loader";
import adminLoader from "../pages/Admin/loader";
import userManagementLoader from "../pages/AdminUser/loader";
import policyLoader from "../pages/AdminPolicy/loader";
import apiStatusLoader from "../pages/ApiStatus/loader";
import { AdminContextProvider } from "../pages/Admin/Context";
import { CustomLoaderFunctionArgs } from "./router/RouteLoader";
import { PartnersProvider } from "../pages/Partners/PartnersContext";
import partnersLoader from "../pages/Partners/loader";
import { createAgentLoader } from "../pages/CreateAgent/loader";
import workflowTimelineLoader from "../pages/WorkflowTimeline/loader";
import loanTermDeviationLoader from "../pages/LoanTermDeviation/loader";
import partnersChartLoader from "../pages/PartnersChart/loader";
import partnersListLoader from "../pages/PartnersList/loader";

export function getLoader(
  loader: (args: CustomLoaderFunctionArgs) => Promise<any>,
  pageName: string
): LoaderFunction {
  return composedLoader(loader, pageName);
}

const routesConfig: Array<CurrentRouteConfig> = [
  {
    path: "/signin",
    filePath: "SignIn",
  },
  {
    path: "/signin-mobile",
    filePath: "SignInMobile",
  },
  {
    path: "/verify-account-details",
    filePath: "VerifyAccountDetails",
  },
  {
    path: "/password-expire",
    filePath: "PasswordExpire",
  },
  {
    path: "/authenticate",
    filePath: "MfaAuthentication",
  },
  {
    path: "/verify-otp",
    filePath: "VerifyOTP",
  },
  {
    path: "/verify-mobile-password",
    filePath: "VerifyMobilePassword",
  },
  {
    path: "/reset-password/:hash",
    filePath: "ResetPassword",
    requiredPage: PAGES_LIST.USER_PROFILE_PAGE,
  },
  {
    path: "/maintenance",
    filePath: "Maintenance",
  },
  {
    index: false,
    isPrivate: true,
    requiredPage: PAGES_LIST.LEAD_DETAILS_PAGE,
    showPermissionDenied: true,
    filePath: "LeadDetails",
    path: "/leads/details/:sourceEntityID/:programID/:userID",
    children: [
      {
        index: true,
        isPrivate: true,
        requiredPage: PAGES_LIST.LEAD_DETAILS_TAB,
        showPermissionDenied: true,
        filePath: "UserOverview",
      },
      {
        path: "sales-disposition",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LEADS_CASE_STATUS_TAB,
        filePath: "SalesDisposition",
        loader: salesDispositionLoader,
      },
      {
        path: "timeline",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LEAD_TIMELINE_TAB,
        filePath: "UserTimeline",
      },
      {
        path: "partner-details",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LEAD_PARTNER_DETAILS_TAB,
        filePath: "UserPartnerDetails",
        loader: userPartnerDetailsLoader,
      },
      {
        path: "business-details",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LEAD_BUSINESS_DETAILS_TAB,
        filePath: "UserBusinessDetails",
      },
      {
        path: "bank-statements",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LEAD_BANK_STATEMENTS_TAB,
        filePath: "UserBankStatements",
        loader: userBankStatementsLoader,
      },
    ],
  },
  {
    index: false,
    isPrivate: true,
    requiredPage: PAGES_LIST.LOAN_APPLICATION_DETAILS_PAGE,
    showPermissionDenied: true,
    filePath: "LoanApplicationDetails",
    path: "/loan-applications/details/:sourceEntityID/:loanID",
    loader: loanApplicationDetailsLoader,
    children: [
      {
        index: true,
        isPrivate: true,
        requiredPage: PAGES_LIST.LOAN_APPLICATION_DETAILS_TAB,
        showPermissionDenied: true,
        filePath: "LoanOverview",
      },
      {
        isPrivate: true,
        path: "loan-term-deviation",
        showPermissionDenied: true,
        filePath: "LoanTermDeviation",
        loader: loanTermDeviationLoader,
        requiredPage: PAGES_LIST.LOAN_APPLICATION_LOAN_TERM_DEVIATION_TAB,
      },
      {
        path: "sales-disposition",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LOAN_APPLICATION_CASE_STATUS_TAB,
        filePath: "SalesDisposition",
        loader: salesDispositionLoader,
      },
      {
        path: "timeline",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LOAN_APPLICATION_TIMELINE_TAB,
        filePath: "LoanTimeline",
        loader: loanTimelineLoader,
      },
      {
        isPrivate: true,
        path: "workflow-timeline",
        showPermissionDenied: true,
        filePath: "WorkflowTimeline",
        loader: workflowTimelineLoader,
        requiredPage: PAGES_LIST.LOAN_APPLICATION_WORKFLOW_TIMELINE_TAB,
      },
      {
        path: "application-form",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LOAN_APPLICATION_FORM_TAB,
        filePath: "LoanApplicationForm",
      },
      {
        path: "kyc",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LOAN_APPLICATION_KYC_TAB,
        filePath: "LoanKYC",
        loader: loanKYCLoader,
      },
      {
        path: "loan-offer",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LOAN_APPLICATION_LOAN_OFFER_TAB,
        filePath: "LoanOffer",
        loader: loanOfferLoader,
      },
      {
        path: "creditline-details",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LOAN_APPLICATION_CREDITLINE_DETAILS_TAB,
        filePath: "CreditLineDetails",
        loader: creditLineDetailsLoader,
      },
      {
        path: "creditline-transactions",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LOAN_APPLICATION_CREDITLINE_TRANSACTIONS_TAB,
        filePath: "CreditLineTransactions",
        loader: creditLineTransactionsLoader,
      },
      {
        path: "bank-info",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LOAN_APPLICATION_BANK_INFO_TAB,
        filePath: "LoanBankInfo",
        loader: loanBankInfoLoader,
      },
      {
        path: "partner-details",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LOAN_APPLICATION_PARTNER_INFO_TAB,
        filePath: "LoanPartnerDetails",
      },
      {
        path: "payments",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LOAN_APPLICATION_PAYMENTS_TAB,
        filePath: "LoanPayments",
        loader: loanPaymentsLoader,
      },
      {
        path: "bank-statements",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LOAN_APPLICATION_BANK_STATEMENTS_TAB,
        filePath: "LoanBankStatements",
      },
      {
        path: "business-details",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LOAN_APPLICATION_BUSINESS_DETAILS_TAB,
        filePath: "LoanBusinessDetails",
      },
      {
        path: "additional-docs",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LOAN_APPLICATION_ADDITIONAL_DOCS_TAB,
        filePath: "LoanAdditionalDocs",
        loader: loanAdditionalDocsLoader,
      },
      {
        path: "notes",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.LOAN_APPLICATION_NOTES_TAB,
        filePath: "LoanNotes",
        loader: loanNotesLoader,
      },
    ],
  },
  {
    path: "/create-agent",
    isPrivate: true,
    showPermissionDenied: true,
    requiredPage: PAGES_LIST.CREATE_AGENT_PAGE,
    filePath: "CreateAgent",
    loader: createAgentLoader,
    children: [
      {
        path: "choose-partner",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.CREATE_AGENT_PAGE,
        filePath: "ChoosePartner",
      },
      {
        path: "save-agent",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.CREATE_AGENT_PAGE,
        filePath: "SaveAgent",
      },
    ],
  },
  {
    path: "/leads",
    isPrivate: true,
    showPermissionDenied: true,
    requiredPage: PAGES_LIST.LEADS_LISTING_PAGE,
    filePath: "Leads",
    loader: leadsLoader,
    shouldRevalidate: ({ currentUrl }) => {
      return currentUrl.pathname.indexOf("leads") <= -1;
    },
  },
  {
    path: "/loan-applications",
    isPrivate: true,
    showPermissionDenied: true,
    requiredPage: PAGES_LIST.LOAN_APPLICATIONS_LISTING_PAGE,
    filePath: "LoanApplications",
    loader: loanApplicationLoader,
    shouldRevalidate: ({ currentUrl }) => {
      return currentUrl.pathname.indexOf("loan-applications") <= -1;
    },
  },
  {
    path: "/partners",
    isPrivate: true,
    showPermissionDenied: true,
    requiredPage: PAGES_LIST.PARTNERS_PAGE,
    filePath: "Partners",
    contextProvider: PartnersProvider,
    loader: partnersLoader,
    shouldRevalidate: ({ currentUrl }) => {
      return currentUrl.pathname.indexOf("partners") <= -1;
    },
    children: [
      {
        path: "chart",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.PARTNERS_PAGE,
        loader: partnersChartLoader,
        filePath: "PartnersChart",
        shouldRevalidate: ({ currentUrl }) => {
          return currentUrl.pathname.indexOf("partners/chart") <= -1;
        },
      },
      {
        path: "list",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.PARTNERS_PAGE,
        loader: partnersListLoader,
        filePath: "PartnersList",
        shouldRevalidate: ({ currentUrl }) => {
          return currentUrl.pathname.indexOf("partners/list") <= -1;
        },
      },
    ],
  },
  {
    path: "/home",
    isPrivate: true,
    showPermissionDenied: true,
    requiredPage: PAGES_LIST.MOBILE_HOME_PAGE,
    filePath: "Home",
  },
  {
    path: "/create-new-lead",
    isPrivate: true,
    showPermissionDenied: true,
    requiredPage: PAGES_LIST.CREATE_NEW_LEAD_PAGE,
    filePath: "CreateNewLead",
    loader: userDSAListLoader,
  },
  {
    path: "/reports",
    isPrivate: true,
    showPermissionDenied: true,
    requiredPage: PAGES_LIST.REPORTS_PAGE,
    filePath: "Reports",
    loader: reportsLoader,
  },
  {
    path: "/user-profile",
    isPrivate: true,
    showPermissionDenied: true,
    requiredPage: PAGES_LIST.USER_PROFILE_PAGE,
    children: [
      {
        path: "details",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.USER_PROFILE_PAGE,
        filePath: "UserProfileDetails",
      },
    ],
  },
  {
    path: "/products",
    children: [
      {
        path: "all-transactions",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.ALL_TRANSACTIONS_LISTING_PAGE,
        filePath: "AllTransactions",
        loader: allTransactionsLoader,
        shouldRevalidate: ({ currentUrl }) => {
          return currentUrl.pathname.indexOf("all-transactions") <= -1;
        },
      },
      {
        path: "active-line",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.ACTIVELINE_LISTING_PAGE,
        filePath: "ActiveLine",
        loader: activeLineLoader,
        shouldRevalidate: ({ currentUrl }) => {
          return currentUrl.pathname.indexOf("active-line") <= -1;
        },
      },
      {
        path: "overdraft",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.OVERDRAFT_LISTING_PAGE,
        filePath: "Overdraft",
        loader: overdraftLoader,
        shouldRevalidate: ({ currentUrl }) => {
          return currentUrl.pathname.indexOf("overdraft") <= -1;
        },
      },
    ],
  },
  {
    path: "/integrations",
    isPrivate: true,
    showPermissionDenied: true,
    requiredPage: PAGES_LIST.INTEGRATIONS_PAGE,
    filePath: "Integrations",
  },
  {
    path: "/integrations/:sourceEntityID",
    isPrivate: true,
    showPermissionDenied: true,
    requiredPage: PAGES_LIST.INTEGRATIONS_PAGE,
    filePath: "SelectedIntegrations",
    loader: selectedIntegrationsLoader,
    children: [
      {
        path: "webhooks",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.INTEGRATIONS_PAGE,
        filePath: "WebhookIntegration",
      },
      {
        path: "api-integrations",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.INTEGRATIONS_PAGE,
        filePath: "APIIntegrationTab",
      },
      {
        path: "theme-config",
        children: [
          {
            index: true,
            isPrivate: true,
            showPermissionDenied: true,
            requiredPage: PAGES_LIST.INTEGRATIONS_PAGE,
            filePath: "ThemeConfigTab",
            loader: getThemeListLoader,
          },
          {
            path: ":themeId",
            isPrivate: true,
            showPermissionDenied: true,
            requiredPage: PAGES_LIST.INTEGRATIONS_PAGE,
            filePath: "ThemeConfigAction",
          },
        ],
      },
      {
        path: "credit-link",
        isPrivate: true,
        showPermissionDenied: true,
        requiredPage: PAGES_LIST.INTEGRATIONS_PAGE,
        filePath: "CreditLinkTab",
        loader: creditLinkLoader,
      },
    ],
  },
  {
    path: "/admin",
    isPrivate: true,
    showPermissionDenied: true,
    requiredFunctions: [],
    requiredPage: PAGES_LIST.ADMIN_PAGE,
    filePath: "Admin",
    contextProvider: AdminContextProvider,
    loader: adminLoader,
    shouldRevalidate: ({ currentUrl }) => {
      return currentUrl.pathname.indexOf("/admin") <= -1;
    },
    children: [
      {
        path: "user",
        isPrivate: true,
        showPermissionDenied: true,
        requiredFunctions: [],
        requiredPage: PAGES_LIST.ADMIN_PAGE,
        filePath: "AdminUser",
        loader: userManagementLoader,
        shouldRevalidate: ({ currentUrl }) => {
          return currentUrl.pathname.indexOf("user") <= -1;
        },
      },
      {
        path: "policy",
        isPrivate: true,
        isUserType: true,
        showPermissionDenied: true,
        requiredFunctions: [],
        requiredPage: PAGES_LIST.ADMIN_PAGE,
        filePath: "AdminPolicy",
        loader: policyLoader,
        shouldRevalidate: ({ currentUrl }) => {
          return currentUrl.pathname.indexOf("policy") <= -1;
        },
      },
    ],
  },
  {
    path: "/analytics",
    isPrivate: true,
    showPermissionDenied: true,
    requiredFunctions: [],
    requiredPage: PAGES_LIST.ANALYTICS_PAGE,
    filePath: "ApiStatus",
    loader: apiStatusLoader,
    shouldRevalidate: ({ currentUrl }) => {
      return currentUrl.pathname.indexOf("analytics") <= -1;
    },
  },
];

interface CurrentRouteConfig {
  path?: string;
  shouldRevalidate?: ShouldRevalidateFunction;
  filePath?: string;
  contextProvider?: React.FC | ((args: any) => JSX.Element);
  isPrivate?: boolean;
  requiredPage?: string;
  showPermissionDenied?: boolean;
  requiredFunctions?: Array<(typeof ACTIONS_LIST)[keyof typeof ACTIONS_LIST]>;
  index?: boolean;
  loader?: (args: CustomLoaderFunctionArgs) => Promise<any>;
  children?: CurrentRouteConfig[];
  isUserType?: boolean;
}
export function getResolvedRoutes(
  routes: CurrentRouteConfig[] = routesConfig
): RouteObject[] {
  const resolvedRoutes: RouteObject[] = [];

  for (const currentRoute of routes) {
    const route: RouteObject = {
      path: currentRoute.path,
      shouldRevalidate: currentRoute.shouldRevalidate,
    };

    let Component: React.FC<{ pageName: string }> = () => null;

    if (currentRoute.filePath) {
      Component = lazy(
        () => import(`../pages/${currentRoute.filePath}/index.tsx`)
      );
    }

    const ContextProvider: React.FC<{ children: React.ReactNode }> =
      currentRoute.contextProvider || (({ children }) => <>{children}</>);

    const pageName = currentRoute.requiredPage;

    if (currentRoute.filePath) {
      if (currentRoute.isPrivate) {
        route.element = (
          <PrivateComponent
            requiredPage={pageName!}
            showPermissionDenied={currentRoute.showPermissionDenied}
            requiredFunctions={currentRoute?.requiredFunctions ?? []}
            isUserType={currentRoute?.isUserType ?? false}
          >
            {currentRoute.contextProvider ? (
              <ContextProvider>
                <Component pageName={pageName!} />
              </ContextProvider>
            ) : (
              <Component pageName={pageName!} />
            )}
          </PrivateComponent>
        );
      } else {
        route.element = currentRoute.contextProvider ? (
          <ContextProvider>
            <Component pageName={pageName!} />
          </ContextProvider>
        ) : (
          <Component pageName={pageName!} />
        );
      }
    }

    if (typeof currentRoute.index !== "undefined") {
      route.index = !!currentRoute.index;
    }

    if (currentRoute.loader) {
      route.loader = getLoader(currentRoute.loader, pageName!);
    }

    if (currentRoute.children) {
      route.children = getResolvedRoutes(currentRoute.children);
    }
    resolvedRoutes.push(route);
  }

  return resolvedRoutes;
}
