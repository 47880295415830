import { defer } from "react-router";
import { LoanApplicationService } from "../../services/LoanApplication";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

export default async function salesDispositionLoader({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const { loanID = "", sourceEntityID = "", userID = "" } = params;
  console.log("params => ", params);
  const token = getToken();

  const salesDispositionResponse =
    await LoanApplicationService.getSalesDisposition({
      token,
      pageName,
      userId: userID,
      loanApplicationID: loanID,
      sourceEntityId: sourceEntityID,
    });

  const salesDispositionStatus =
    await LoanApplicationService.getSalesDispositionStatus({
      token,
      pageName,
      sourceEntityId: sourceEntityID,
    });

  return defer({
    salesDispositionResponse,
    salesDispositionStatus,
  });
}
