/**
 * Constants that hold the Page UI configs
 */

import { SOURCES } from "../../shared/utils/constants";

export const MOBILE_USER_DETAILS_SECTION = {
  level_1: [
    {
      label: "Name",
      field: "name",
    },
    {
      label: "Customer ID",
      field: "customerID",
    },
  ],
  level_2: [
    {
      label: "Mobile",
      field: "mobile",
    },
    {
      label: "Email",
      field: "email",
    },
  ],
  level_3: [
    {
      label: "PAN",
      field: "pan",
    },
    {
      label: "Status",
      field: "status",
    },
  ],
  level_4: [
    {
      label: "DOB",
      field: "dob",
    },
    {
      label: "Gender",
      field: "gender",
    },
  ],
  level_5: [
    {
      label: "Firm Name",
      field: "firmName",
    },
    {
      label: "Constitution",
      field: "constitution",
    },
  ],
  level_6: [
    {
      label: "Business Address",
      field: "businessAddress",
    },
    {
      label: "Loan Application ID",
      field: "loanApplicationID",
    },
  ],
};

export const MOBILE_LEADS_AGENT_DETAILS_SECTION = {
  level_1: [
    {
      label: "Name",
      field: "dsaName",
    },
    {
      label: "Agent Code",
      field: "agentCode",
    },
  ],
  level_2: [
    {
      label: "Email",
      field: "dsaEmail",
    },
  ],
  level_3: [
    {
      label: "Mobile",
      field: "dsaMobile",
    },
  ],
};

export const LEADS_TABLE_COLUMNS = [
  {
    key: "name",
    name: "Name",
    showInMobile: true,
    width: "180px",
  },
  {
    key: "customerID",
    name: "Customer ID",
    showInMobile: true,
    width: "180px",
  },
  {
    key: "mobile",
    name: "Mobile",
    showInMobile: true,
    width: "180px",
  },
  {
    key: "status",
    name: "Status",
    showInMobile: true,
    width: "150px",
    headerStyles: "!text-center",
  },
  {
    key: "sourceEntityName",
    name: "Platform",
    showInMobile: true,
    width: "180px",
  },
  {
    key: "programName",
    name: "Program Name",
    showInMobile: true,
    width: "180px",
  },
  {
    key: "updatedAt",
    name: "Updated At",
    isDefault: true,
    width: "150px",
  },
  {
    key: "createdAt",
    name: "Created At",
    isDefault: false,
    showInMobile: true,
    width: "150px",
  },
  {
    key: "firmName",
    name: "Firm",
    isDefault: false,
    width: "150px",
  },
  {
    key: "agentCode",
    name: "Agent Code",
    isDefault: false,
    width: "150px",
  },
];

export const LEAD_DETAILS_TABS = [
  {
    name: "Details",
    href: "/",
    current: false,
  },
  {
    name: "Case Status",
    href: "/sales-disposition",
    current: true,
  },
  {
    name: "Timeline",
    href: "/timeline",
    current: false,
  },
  {
    name: "Partner Details",
    href: "/partner-details",
    current: false,
  },
  {
    name: "Business Details",
    href: "/business-details",
    current: false,
  },
  {
    name: "Bank Statements",
    href: "/bank-statements",
    current: false,
  },
];

export const LEAD_BANK_STATEMENT_COLUMNS = [
  {
    key: "bankName",
    name: "Bank",
    showInMobile: true,
  },
  {
    key: "statementID",
    name: "Statement ID",
    showInMobile: true,
  },
  {
    key: "pdfPassword",
    name: "PDF Password",
    showInMobile: true,
  },
  {
    key: "statementURL",
    name: "Download",
    showInMobile: true,
  },
  {
    key: "createdAt",
    name: "Created At",
    showInMobile: true,
  },
];

export const LEAD_PERFIOS_TRANSACTION_DATA_COLUMNS = [
  {
    key: "perfios_transaction_id",
    name: "Statement Transaction ID",
    showInMobile: true,
  },
  {
    key: "created_at",
    name: "Created At",
    showInMobile: true,
  },
];

export const LEAD_PERFIOS_CALLBACK_DATA_COLUMNS = [
  {
    key: "entity_id",
    name: "Entity ID",
    showInMobile: true,
  },
  {
    key: "entity_id",
    name: "Entity ID",
    showInMobile: true,
  },
  {
    key: "entity_id",
    name: "Entity ID",
    showInMobile: true,
  },
  {
    key: "entity_id",
    name: "Entity ID",
    showInMobile: true,
  },
  {
    key: "entity_id",
    name: "Entity ID",
    showInMobile: true,
  },
];
export const USER_BUSINESS_DETAILS_FIELDS = {
  firmName: {
    label: "Firm Name",
    allowCopy: false,
    category: "business-details",
  },
  constitution: {
    label: "Constitution",
    allowCopy: false,
    category: "business-details",
  },
  businessPAN: {
    label: "Business PAN",
    allowCopy: false,
    category: "business-details",
  },
  last12MonthsSale: {
    label: "Last 12 months Sale",
    allowCopy: false,
    category: "business-details",
  },
  dateOfIncorporation: {
    label: "Date of Incorporation",
    allowCopy: false,
    category: "business-details",
  },
  businessAddressType: {
    label: "Business Address Type",
    allowCopy: false,
    category: "business-details",
  },
  businessAddress: {
    label: "Business Address",
    allowCopy: false,
    category: "business-details",
  },
  businessCategory: {
    label: "Business Category",
    allowCopy: false,
    category: "business-details",
  },
  gstinList: {
    label: "GSTIN",
    allowCopy: false,
    category: "business-details",
  },
  businessNature: {
    label: "Nature of Business",
    allowCopy: false,
    category: "business-details",
  },
  industryType: {
    label: "Industry",
    allowCopy: false,
    category: "business-details",
    sourceEntityIDs: [SOURCES.IIFLID, SOURCES.IIFLBLID, SOURCES.ABFLID],
  },
  subIndustryType: {
    label: "Sub Industry",
    allowCopy: false,
    category: "business-details",
    sourceEntityIDs: [SOURCES.IIFLID, SOURCES.IIFLBLID, SOURCES.ABFLID],
  },
  udyamList: {
    label: "Udyam",
    allowCopy: false,
    category: "business-details",
  },
  registrationDate: {
    label: "Registration Date",
    allowCopy: false,
    category: "business-details",
  },
  companyType: {
    label: "Company Type",
    allowCopy: false,
    category: "business-details",
    sourceEntityIDs: [SOURCES.IIFLID, SOURCES.IIFLBLID],
  },
  riskClassification: {
    label: "Risk Classification",
    allowCopy: false,
    category: "business-details",
    sourceEntityIDs: [SOURCES.IIFLID, SOURCES.IIFLBLID],
  },
  riskScore: {
    label: "Risk Score",
    allowCopy: false,
    category: "business-details",
    sourceEntityIDs: [SOURCES.IIFLID, SOURCES.IIFLBLID],
  },
};
