import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import PartnersService from "../../services/Partners";
import { DsaAgent, ListChannelHierarchyParams } from "./interface";
import { buildTree, updateSingleNodeChildren } from "./selectors";
import { SEARCH_TYPES } from "../../services/Partners/constants";
import {
  BulkActionNames,
  Collaborator,
  ModalTypes,
} from "../../services/Partners/interfaces";
import { checkIfSuperDsaUser } from "../../shared/utils/PermissionUtil";

const defaultState = {
  activeProfile: {},
  activeCollaboratorProfile: {},
  channelsList: [],
  channelsMap: new Map(),
  currentOpenModal: "",
  currentBulkAction: "",
  partnersList: [],
  searchText: "",
  searchType: SEARCH_TYPES[0].value,
  selectedDsaID: "",
  targetDsaID: "",
  productType: "all",
  totalPages: 0,
  collaboratorsList: [],
  status: undefined,
  designations: [],
  channels: [],
  locations: [],
  isProfileOpen: false,
  isShowCollaboratorView: false,
  newDsaID: "",
};

const usePartnersStore = create<{
  activeProfile: Partial<DsaAgent>;
  isProfileOpen: boolean;
  activeCollaboratorProfile: Partial<DsaAgent>;
  channelsList: Array<DsaAgent>;
  channelsMap: Map<string, any>;
  currentOpenModal: ModalTypes | "";
  currentBulkAction: BulkActionNames | "";
  partnersList: Array<DsaAgent>;
  searchText: string;
  searchType: string;
  selectedDsaID: string;
  targetDsaID: string;
  productType: string;
  totalPages: number;
  status: string | undefined;
  designations: Array<any>;
  channels: Array<any>;
  locations: Array<any>;
  newDsaID: string;
  isShowCollaboratorView: boolean;
  collaboratorsList: Array<Collaborator>;
  getChannelsList: ({ ...args }: ListChannelHierarchyParams) => Promise<void>;
  getPartnersList: ({ ...args }) => Promise<void>;
  deactivateDsa: ({ ...args }: any) => Promise<void>;
  reactivateAgent: ({ ...args }: any) => Promise<void>;
  deleteDsa: ({ ...args }: any) => Promise<void>;
  setCurrentOpenModal: (value: ModalTypes | "") => void;
  getAgentDetails: ({ ...args }) => Promise<string>;
  getListCollaborators: ({ ...args }) => Promise<string>;
  getOrganizationDsaMetadataMapping: ({ ...args }) => Promise<void>;
  clear: () => void;
}>()(
  immer((set) => ({
    activeProfile: {},
    activeCollaboratorProfile: {},
    channelsList: [],
    channelsMap: new Map(),
    currentOpenModal: "",
    currentBulkAction: "",
    partnersList: [],
    searchText: "",
    searchType: SEARCH_TYPES[0].value,
    selectedDsaID: "",
    targetDsaID: "",
    productType: "all",
    totalPages: 0,
    collaboratorsList: [],
    status: undefined,
    designations: [],
    channels: [],
    locations: [],
    isProfileOpen: false,
    isShowCollaboratorView: false,
    newDsaID: "",
    getChannelsList: async ({
      token,
      searchType,
      searchText,
      pages,
      productType,
      dsaID,
      pageName,
      type,
      userType,
    }: ListChannelHierarchyParams) => {
      const { channelsMap } = usePartnersStore.getState();
      const response = await PartnersService.listChannelHierarchy({
        token,
        searchType,
        searchText: decodeURIComponent(searchText),
        pages,
        productType: productType !== "all" ? productType : "",
        dsaID,
        pageName,
      });

      if (response.status) {
        let tree: Array<DsaAgent> = [];
        let map = new Map();
        if (type == "partial" && dsaID) {
          const slicedArray = response?.data?.filter((el, index) => index != 0);
          [tree, map] = updateSingleNodeChildren(
            dsaID,
            slicedArray ?? [],
            channelsMap
          );
        } else {
          try {
            if (checkIfSuperDsaUser(userType)) {
              const currentUser = response.data.find(
                (agent) => agent.isCurrentUser
              );
              if (currentUser) {
                const list = response.data?.map((agent) =>
                  !agent.isCurrentUser
                    ? { ...agent, pseudoOwnerID: currentUser.dsaID }
                    : agent
                );

                [tree, map] = buildTree(list, new Map());
              }
            } else {
              [tree, map] = buildTree(response.data, new Map());
            }
          } catch (error) {
            console.log(error);
          }
        }
        set((state) => {
          state.channelsMap = new Map(map);
          state.channelsList = [];
          tree.map((node: DsaAgent) => {
            state.channelsList.push(node);
          });

          return state;
        });
      } else {
        console.log("fetch error");
        console.log(response.error);
      }
    },
    getPartnersList: async ({
      limit,
      page,
      token,
      searchType,
      searchText,
      filters,
      status,
      productType,
      pageName,
    }) => {
      const response = await PartnersService.getListPartners({
        limit: 10,
        page,
        token,
        searchType,
        searchText,
        filters,
        status,
        productType,
        pageName,
      });
      if (response.status) {
        set((state) => {
          if (response?.data?.partners) {
            state.partnersList = [];
            response?.data.partners.map((p) => {
              state.partnersList.push(p);
            });
          }
          state.totalPages = response.data?.total ?? 0;

          return state;
        });
      }
    },
    deactivateDsa: async ({}) => {},
    reactivateAgent: async ({}) => {},
    deleteDsa: async ({}) => {},
    setCurrentOpenModal: (value: ModalTypes | "") =>
      set((state) => {
        state.currentOpenModal = value;
        if (!value) {
          state.selectedDsaID = "";
          state.targetDsaID = "";
        }

        return state;
      }),
    getAgentDetails: async ({ pageName, dsaID, token }) => {
      const response = await PartnersService.getAgentDetails({
        pageName,
        dsaID,
        token,
      });
      if (response?.status) {
        set(() => ({
          activeProfile: response?.data as DsaAgent,
          isProfileOpen: true,
          isShowCollaboratorView: false,
          activeCollaboratorProfile: {},
          selectedDsaID: dsaID,
        }));
        return "";
      } else {
        return response?.error;
      }
    },
    getListCollaborators: async ({ dsaID, token, pageName }) => {
      const response = await PartnersService.getListCollaborators({
        token,
        dsaID,
        pageName,
      });
      if (response?.status) {
        set((state) => ({
          collaboratorsList: response?.data?.collaborators,
        }));
        return "";
      } else {
        return response?.error;
      }
    },
    getOrganizationDsaMetadataMapping: async ({ token, pageName }) => {
      const response = await PartnersService.getOrganizationDsaMetadataMapping({
        token,
        pageName,
      });
      if (response?.status) {
        set(() => ({
          designations: response?.data?.designation ?? [],
          locations: response?.data?.location ?? [],
          channels: response?.data?.channel ?? [],
        }));
      }
    },
    clear: () => {
      set(() => {
        return defaultState;
      });
    },
  }))
);

export default usePartnersStore;
