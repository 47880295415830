import { ChevronRightIcon, HomeIcon } from "@heroicons/react/24/outline";
import { BreadCrumb } from "../interface";
import { Link } from "react-router-dom";

interface IProps {
  breadcrumbs: Array<BreadCrumb>;
}

export default function BreadcrumbsOne({ breadcrumbs }: IProps) {
  const isMobile = window.innerWidth < 640;
  const homeRoute = isMobile ? "/home" : "/leads";
  return (
    <>
      <ol role="list" className="flex items-center space-x-1">
        <li>
          <div className="flex items-center">
            <Link
              to={homeRoute}
              className="text-sm font-medium text-gray-700 hover:text-gray-700"
            >
              Home
            </Link>
          </div>
        </li>
        {breadcrumbs.map((item, itemIdx) => (
          <li key={itemIdx}>
            <div className="flex items-center min-w-max truncate">
              <ChevronRightIcon
                className="h-3 w-3 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <Link
                to={item.href ?? "/"}
                className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-700"
                aria-current={item.current ? "page" : undefined}
                title={item.name}
              >
                {isMobile && item?.name?.length > 18
                  ? `${item.name.slice(0, 17)}...`
                  : item.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </>
  );
}
