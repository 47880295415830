import { IAuthService } from "./authService";
import { APIResponse } from "../../shared/services/APIResponse";
import { left, right } from "../../shared/core/Either";
import { DashboardUserDTO } from "../../dtos/DashboardUserDTO";
import { BaseAPI } from "../../shared/services/BaseAPI";
import { Result } from "../../shared/core/Result";

/**
 * @deprecated
 */
export interface IUsersService {
  getUserProfile(): Promise<APIResponse<DashboardUserDTO>>;
}

export class UsersService extends BaseAPI implements IUsersService {
  constructor(authService: IAuthService) {
    super(authService);
  }

  public async getUserProfile(): Promise<APIResponse<DashboardUserDTO>> {
    try {
      const response = await this.get(`/masterDashboard/getPermissions`);
      return right(Result.ok<DashboardUserDTO>(response.data));
    } catch (err: any) {
      return left(
        err.response ? err.response.data.message : "Connection failed"
      );
    }
  }
}
