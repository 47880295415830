import { produce } from "immer";
import { RoleHelperDefinition } from "./interface";

export const ACTIONS = {
  SET_SELECTED_ORGANIZATION: "SET_SELECTED_ORGANIZATION",

  // user
  SET_USER: "SET_USER",
  MODAL_SETUP: "MODAL_SETUP",
  CURRENT_MODAL: "CURRENT_MODAL",
  SET_DELETE_USER: "SET_DELETE_USER",
  SET_SELECT_ROLE: "SET_SELECT_ROLE",
  SET_GROUPS_DATA: "SET_GROUPS_DATA",
  SET_ROLE_OPTIONS: "SET_ROLE_OPTIONS",
  RESET_PAGE_STATE: "RESET_PAGE_STATE",
  SET_GROUP_OPTIONS: "SET_GROUP_OPTIONS",
  SET_PLATFORM_OPTIONS: "SET_PLATFORM_OPTIONS",
  SET_ROLE_HELPER_DATA: "SET_ROLE_HELPER_DATA",

  // policy
  SET_POLICY_DATA: "SET_POLICY_DATA",
  SET_CUSTOM_POLICY: "SET_CUSTOM_POLICY",
  SET_ACTION_POLICY: "SET_ACTION_POLICY",
  SET_MASKING_OPTIONS: "SET_MASKING_OPTIONS",

  SET_USERS_STATUS: "SET_USERS_STATUS",
  SET_EDIT_ROLE_DATA: "SET_EDIT_ROLE_DATA",
  SET_BACK_FORMAT_DATA: "SET_BACK_FORMAT_DATA",
  SET_ALL_GROUPS: "SET_ALL_GROUPS",
} as const;

interface Payload {
  selectedSourceEntity: any;

  user: any;
  roles: any;
  allGroups: any;
  roleOptions: any;
  groupOptions: any;
  platformOptions: any;
  editUser: any;
  deleteUser: any;
  isModalOpen: boolean;
  currentModal: string;
  roleHelperDefinitions: RoleHelperDefinition[];

  maskingOptions: any;
  customPolicy: any;
  actionPolicy: any;
  policy: any;
  editRoles: any;
  backFormatData: any;
}

type Action = {
  payload: Partial<Payload>;
  type: keyof typeof ACTIONS;
};

function reducer(draft: any, action: Action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.SET_SELECTED_ORGANIZATION: {
      draft.selectedSourceEntity = payload;
      return draft;
    }

    case ACTIONS.SET_ROLE_HELPER_DATA: {
      draft.roleHelperDefinitions = payload?.roleHelperDefinitions;
      // draft.allGroups = payload?.allGroups;
      if (payload.selectedSourceEntity) {
        draft.selectedSourceEntity = payload?.selectedSourceEntity;
      }
      return draft;
    }

    case ACTIONS.SET_ALL_GROUPS: {
      draft.allGroups = payload?.allGroups;

      return draft;
    }

    case ACTIONS.SET_SELECT_ROLE: {
      draft.roles = payload;
      return draft;
    }

    case ACTIONS.SET_ROLE_OPTIONS: {
      draft.roleOptions = payload;
      return draft;
    }

    case ACTIONS.SET_PLATFORM_OPTIONS: {
      draft.platformOptions = payload;
      return draft;
    }

    case ACTIONS.SET_GROUP_OPTIONS: {
      draft.groupOptions = payload;
      return draft;
    }

    case ACTIONS.MODAL_SETUP: {
      draft.isModalOpen = payload;
      return draft;
    }

    case ACTIONS.CURRENT_MODAL: {
      draft.currentModal = payload || "ADD";
      return draft;
    }

    case ACTIONS.SET_USER: {
      draft.user = payload;
      return draft;
    }

    case ACTIONS.SET_DELETE_USER: {
      draft.deleteUser = payload;
      return draft;
    }

    case ACTIONS.SET_MASKING_OPTIONS: {
      draft.maskingOptions = payload;
      return draft;
    }

    case ACTIONS.SET_CUSTOM_POLICY: {
      draft.customPolicy = payload;
      return draft;
    }

    case ACTIONS.SET_ACTION_POLICY: {
      draft.actionPolicy = payload;
      return draft;
    }

    case ACTIONS.SET_POLICY_DATA: {
      draft.policy = payload;
      return draft;
    }

    case ACTIONS.SET_USERS_STATUS: {
      draft.status = payload;
      return draft;
    }

    case ACTIONS.SET_EDIT_ROLE_DATA: {
      draft.editRoles = payload;
      return draft;
    }

    case ACTIONS.SET_BACK_FORMAT_DATA: {
      draft.backFormatData = payload;
      return draft;
    }

    case ACTIONS.RESET_PAGE_STATE: {
      return draft;
    }

    default:
      return draft;
  }
}

export default produce(reducer);
