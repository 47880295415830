import { Fragment } from "react";
import classNames from "classnames";
import { Popover, Transition } from "@headlessui/react";

import Button from "../../components/atomic/Button";

const WARNING = {
  accepted: {
    text: "Update Loan Offer",
    btnText: "Approve Offer",
    message:
      "After updating offer, changes will reflect in customer loan journey.",
  },
  rejected: {
    text: "Reject Deviation Request",
    btnText: "Reject Offer",
    message: `Once the current request is rejected, a new deviation can be raised again.`,
  },
};

export default function Confirm({ action }: { action: string }) {
  return (
    <>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button className="outline-none">
              <Button color="primary" disabled={!Boolean(action)}>
                Submit
              </Button>
            </Popover.Button>
            <Transition
              as={Fragment}
              leaveTo="opacity-0 translate-y-1"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leaveFrom="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              enter="transition ease-out duration-200"
            >
              <Popover.Panel className="absolute left-[-190px] top-[-150px] -translate-x-1/2 transform px-4 sm:px-0 w-[360px]">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="bg-white p-6">
                    <div className="font-medium text-[#373737]">
                      {WARNING[action]?.text}
                    </div>
                    <div className="mt-2 text-sm font-normal text-[#626262]">
                      {WARNING[action]?.message}
                    </div>
                    <Button
                      type="submit"
                      color="primary"
                      className={classNames(
                        `w-full mt-8`,
                        action === "rejected" ? "!bg-[#B42318]" : ""
                      )}
                    >
                      {WARNING[action]?.btnText}
                    </Button>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
}
