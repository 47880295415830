import { SettingsService } from "../../services/Settings";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

export default async function selectedIntegrationsLoader({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const { sourceEntityID } = params;
  if (sourceEntityID) {
    const response = await SettingsService.getIntegrationDetails({
      token: getToken(),
      sourceEntityID,
      pageName,
    });

    return { response };
  } else {
    throw Error("No sourceEntityID provided!");
  }
}
