import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { LeadsService } from "../../../services/Leads";
import { notify } from "../../../shared/context/AlertContext";
import GetAuthContext from "../../../shared/context/AuthContext";
import GetMixpanelContext from "../../../shared/context/MixpanelContext";
import Input from "../FormElements/Input";

interface IProps {
  setOpen: Function;
  reload: Function;
  userID: string;
  pageName: string;
}

type FormInputs = {
  reason: string;
};

export default function ExpireUserModal({
  userID,
  setOpen,
  reload,
  pageName,
}: IProps) {
  const { fetchToken } = GetAuthContext();
  const { trackUserAction } = GetMixpanelContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      reason: "",
    },
  });

  async function onSubmit(data: FormInputs) {
    const payload = {
      userID,
      reason: data.reason,
    };
    const response = await LeadsService.expireUser({
      payload,
      token: fetchToken(),
      pageName: pageName!,
    });

    if (response.status) {
      trackUserAction("expire_user", {
        userID,
      });
      notify({
        title: "Success",
        text: "User Expired Successfully",
        type: "success",
      });

      reload();
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
    setOpen(false);
  }

  return (
    <>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 text-center sm:ml-3 sm:mt-0 sm:text-left">
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Are you sure you want to expire this lead? This action is not
              reversible.{" "}
            </p>
          </div>
        </div>
      </div>
      <form className="flex flex-col mt-4" onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="mb-4 px-2">
          <Input
            type="text"
            height={"10px"}
            placeholder="Reason for Expiry"
            register={register("reason", {
              required: true,
            })}
            error={errors.reason}
            errorText={errors?.reason?.type === "required" ? "required" : ""}
          />
        </fieldset>
        <fieldset className="flex items-center justify-end">
          <button
            type="button"
            className="text-sm px-4 py-2 w-auto rounded-lg bg-white border border-gray-300 text-gray-700 flex items-center justify-center font-medium "
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
          >
            Confirm
          </button>
        </fieldset>
      </form>
    </>
  );
}
