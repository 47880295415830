import { enableMapSet, produce } from "immer";
import { UserDSA } from "../../services/Partners/interfaces";
import { DsaAgent } from "./interface";
enableMapSet();

export function getProcessedHierarchy(
  channels: Array<DsaAgent>,
  initialData: Array<DsaAgent> = []
): Array<DsaAgent> {
  const updatedChannels = [...initialData, ...channels];
  const list: Record<string, DsaAgent> = {};

  // Filtering and de-duplicating in one go
  const finalChannels = updatedChannels.filter((c) => {
    if (!list[c.dsaID]) {
      list[c.dsaID] = c;
      return true;
    }
    return false;
  });

  const topLevelDsas = getTopLevelDsas(finalChannels);
  return topLevelDsas.map((topLevelDsa) => {
    const copy = { ...topLevelDsa }; // Shallow copy
    copy.children = NestChildren(finalChannels, topLevelDsa.dsaID, "dsaID");
    return copy;
  });
}

// }

function getTopLevelDsas(channels: Array<DsaAgent>) {
  return channels.filter((c) => c.isCurrentUser);
}

function NestChildren(
  data: Array<DsaAgent>,
  parentKey: string,
  comparisonKey: string
): Array<DsaAgent> {
  const children: Array<DsaAgent> = [];

  for (let i = 0; i < data.length; i++) {
    const node = { ...data[i] }; // Shallow copy
    if (node.ownerID === parentKey) {
      const nodeChildren = NestChildren(data, node.dsaID, comparisonKey);
      node.children = nodeChildren?.length > 0 ? nodeChildren : []; // Updating the copy, not the original
      children.push(node);
    }
  }
  return children;
}

export function getFormattedAgentList(agentList: Array<UserDSA>) {
  return agentList?.map((agent: UserDSA) => {
    return {
      ...agent,
      name: (
        <div>
          {agent.dsaName} {`(${agent.agentCode})`}
          <p className="text-xs">
            {agent.lenderName} -{" "}
            {agent.productType
              .split("_")
              .map((str: string) => str.slice(0, 1).toUpperCase())
              .join("")}
          </p>
        </div>
      ),
      value: agent.dsaID,
    };
  });
}

// export function buildTree(
//   list: Array<DsaAgent>,
//   channelsMap = new Map<string, DsaAgent & { children: Array<DsaAgent> }>()
// ): [Array<DsaAgent>, Map<string, DsaAgent & { children: Array<DsaAgent> }>] {
//   // Populate the map with nodes first
//   let map = new Map(channelsMap);
//   for (const item of list) {
//     if (!map.has(item.dsaID)) {
//       map.set(item.dsaID, { ...item, children: [] });
//     }
//     if (item.ownerID && map.has(item.ownerID)) {
//       const newState = produce(map.get(item.ownerID), (draftState) => {
//         draftState?.children.push(map.get(item.dsaID)!);
//       });
//       map.set(item.ownerID, newState);
//     }
//   }

//   // create the roots array from the map by picking nodes marked as the current user.
//   let roots: Array<DsaAgent> = [];
//   for (const [key, value] of map.entries()) {
//     if (value.isCurrentUser) {
//       roots.push(value);
//     }
//   }
//   return [roots, map];
// }
export function buildTree(
  list: Array<DsaAgent>,
  channelsMap = new Map<string, DsaAgent & { children: Array<DsaAgent> }>()
): [Array<DsaAgent>, Map<string, DsaAgent & { children: Array<DsaAgent> }>] {
  let map = new Map(channelsMap);

  // Initialize all items in the map with an empty children array
  list.forEach((item) => {
    if (!map.has(item.dsaID)) {
      map.set(item.dsaID, { ...item, children: [] });
    }
  });

  // Establish parent-child relationships
  list.forEach((childItem) => {
    if (childItem.pseudoOwnerID && map.has(childItem.pseudoOwnerID)) {
      let parentItem = map.get(childItem.pseudoOwnerID);
      if (
        !parentItem.children.some((child) => child.dsaID === childItem.dsaID)
      ) {
        parentItem.children.push(map.get(childItem.dsaID));
      }
    } else if (childItem.ownerID && map.has(childItem.ownerID)) {
      let parentItem = map.get(childItem.ownerID);
      if (
        !parentItem.children.some((child) => child.dsaID === childItem.dsaID)
      ) {
        parentItem.children.push(map.get(childItem.dsaID));
      }
    }
  });

  // Extract roots
  let roots: Array<DsaAgent> = [];
  map.forEach((value, _) => {
    if (value.isCurrentUser) {
      roots.push(value);
    }
  });

  return [roots, map];
}

export function updateSingleNodeChildren(
  targetNodeID: string,
  updatedChildren: Array<DsaAgent>,
  map: Map<string, DsaAgent & { children: Array<DsaAgent> }>
  // roots: Array<DsaAgent>
): [Array<DsaAgent>, Map<string, DsaAgent & { children: Array<DsaAgent> }>] {
  let updatedMap = produce(map, (draft) => {
    const parentNode = draft.get(targetNodeID);
    if (parentNode) {
      // Update the children for the target node in the map.
      parentNode.children = [
        ...(parentNode.children ?? []),
        ...updatedChildren.map((child) => {
          draft.set(child.dsaID, child);
          return {
            ...(draft.get(child.dsaID) || child), // Merge with existing or use new child
            ...child,
          };
        }),
      ];
      draft.set(targetNodeID, parentNode);
    }
  });

  const roots = Array.from(updatedMap.entries())
    .filter(([key, value]) => value.isCurrentUser)
    .map(([key, value]) => value);

  const updatedRoots = getDataFromMap(roots, updatedMap);

  return [updatedRoots, updatedMap];
}

function getDataFromMap(items, map) {
  return items.map((item) => {
    const data = { ...(map.get(item.dsaID) ?? {}) };

    // Only proceed if there are children
    if (data.children && data.children.length > 0) {
      // Recursively update children
      data.children = getDataFromMap(data.children, map);
    }

    return data;
  });
}
