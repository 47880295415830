import { SettingsService } from "../../services/Settings";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

export async function creditLinkLoader({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const { sourceEntityID = "" } = params;
  const token = getToken();
  return await SettingsService.getCreditLinks({
    token,
    sourceEntityID,
    pageName,
  });
}
