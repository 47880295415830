import CalculateEMI from "./CalculateEMI";
import Input from "../../components/composed/FormElements/Input";

const VALIDATIONS = {
  ROI: {
    min: {
      value: 18,
      message: `Interest rate cannot be below 18%`,
    },
    max: {
      value: 100,
      message: `Interest rate cannot be more than 100%`,
    },
  },
  MONTHS: {
    min: {
      value: 12,
      message: `Tenure cannot be below 12 Months`,
    },
    pattern: {
      value: /^(0|[1-9]\d*)$/,
      message: "Tenure must be a whole number greater than 0",
    },
  },
  PROCESSINGFEE: {
    pattern: {
      value: /^(0|[1-9]\d*)$/,
      message: "Processing Fee cannot be decimal or less than 0",
    },
  },
};

export default function IntiateForm({
  fields,
  errors,
  isValid,
  register,
  textArea,
  loanOffer,
  getValues,
}: any) {
  return (
    <>
      {Object?.keys(fields || {})?.length && (
        <div>
          {Object?.keys(fields)?.map((field) => {
            return (
              <div
                className="grid grid-cols-2 gap-5 mb-5 last:mb-0"
                key={field}
              >
                <fieldset className="">
                  <Input
                    type="text"
                    disabled={true}
                    name={`original.${field}`}
                    label={`Original ${fields[field]?.label}`}
                    register={register(`original.${field}`, {
                      value: fields?.[field]?.originalValue ?? "",
                    })}
                  />
                </fieldset>
                <fieldset className="">
                  <Input
                    step="any"
                    type="number"
                    name={`proposed.${field}`}
                    label={`Proposed ${fields[field]?.label}`}
                    placeholder="Leave Empty if no proposal"
                    register={register(`proposed.${field}`, {
                      value: fields?.[field]?.proposedValue ?? "",
                      ...(VALIDATIONS[fields?.[field]?.validation] ?? {}),
                    })}
                    errorText={errors?.proposed?.[field]?.message || ""}
                  />
                </fieldset>
              </div>
            );
          })}
        </div>
      )}
      <fieldset className="w-full mt-5 pt-5 border-t">
        <CalculateEMI
          isValid={isValid}
          loanOffer={loanOffer}
          getValues={getValues}
        />
        <Input
          type="text"
          multiple={true}
          name={textArea?.name}
          label={textArea?.label}
          register={register(textArea?.name, {})}
          placeholder="comments if you have any suggestions that can improve the Proposal."
        />
      </fieldset>
    </>
  );
}
