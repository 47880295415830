import { Get, Post } from "../../shared/services/Fetch";
import { noHeadersResponse } from "../../shared/utils/Global";
import { ServiceMethodArgs } from "../LoanApplication/interfaces";
import {
  Roles,
  Group,
  UserData,
  GetAllUser,
  UserDetails,
  GroupPermissions,
  GroupMaskingLevels,
  RoleHelperDefinitions,
} from "./interface";

export class AdminService {
  static async getAllUsers({
    token,
    filter,
    page = 1,
    status = "",
    pageName = "",
    limit = 10,
    searchText,
    filters = {},
    searchType = "name",
    filterType = "rbacGroupName",
    switchedOrgID = null,
  }: ServiceMethodArgs<GetAllUser>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    headers.append("Content-Type", "application/json");

    const payload = {} as any;
    payload["page"] = page;
    payload["limit"] = limit;

    if (searchText) {
      payload[searchType] = searchText;
    }

    if (filter) {
      payload[filterType] = filter;
    }

    if (status) {
      payload["status"] = status;
    }
    if (switchedOrgID) {
      payload["switchedOrgID"] = switchedOrgID;
    }

    return await Post<{ userData: Array<UserData>; total: number }>(
      `/masterDashboard/rbac/getAllUsers`,
      token,
      payload,
      headers
    );
  }

  static async getUsersPlatformAccessFilterView({
    limit = 8,
    page = 1,
    name = "",
    email = "",
    mobile = "",
    sourceEntityIDs = null,
    groups = null,
    // isPlatformOrGroupSelected = false,
    dateRangeFilterType = "",
    fromDate = "",
    toDate = "",
    userStatus = "",
    switchedOrgID = null,
    status = "",
    token,
    pageName = "",
  }: ServiceMethodArgs<any>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    headers.append("Content-Type", "application/json");

    const queryParams = {
      limit,
      page,
      name,
      email,
      mobile,
      // sourceEntityIDs,
      // groups,
      // isPlatformOrGroupSelected,
      dateRangeFilterType,
      fromDate,
      toDate,
      userStatus,
      switchedOrgID,
      status,
    };

    // Remove properties with empty values
    Object.keys(queryParams).forEach((key) => {
      if (queryParams[key] === "" || queryParams[key] === null) {
        delete queryParams[key];
      }
    });

    const searchParams = new URLSearchParams();
    Object?.keys(queryParams)?.map((key) =>
      searchParams.append(key, queryParams[key])
    );

    sourceEntityIDs?.forEach((sourceEntityID: any) => {
      searchParams.append("sourceEntityIDs", sourceEntityID);
    });

    groups?.forEach((group: any) => {
      searchParams.append("groups", group);
    });

    const url = `/masterDashboard/rbac/getPlatformAccessFilterView?${searchParams.toString()}`;

    return await Get<{ userData: Array<UserData>; total: number }>(
      url,
      token,
      headers
    );
  }

  static async exportUsersView({
    token,
    name = "",
    email = "",
    mobile = "",
    toDate = "",
    pageName = "",
    fromDate = "",
    groups = null,
    userStatus = "",
    isUserView = false,
    isFilterView = false,
    switchedOrgID = null,
    sourceEntityIDs = null,
    dateRangeFilterType = "",
    isUserViewWithCheckbox = false,
  }: ServiceMethodArgs<any>) {

    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    headers.append("Content-Type", "application/json");

    const payload = {
      name,
      email,
      mobile,
      toDate,
      groups,
      fromDate,
      isUserView,
      userStatus,
      isFilterView,
      switchedOrgID,
      sourceEntityIDs,
      dateRangeFilterType,
      isUserViewWithCheckbox,
    };
    return await Post<{ userData: Array<UserData>; total: number }>(
      `/masterDashboard/rbac/exportUsersView`,
      token,
      payload,
      headers
    );
  }

  static async getAllGroups({
    token,
    pageName = "",
    switchedOrgID = null,
  }: ServiceMethodArgs<{ switchedOrgID?: string | null }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const payload: any = {};
    if (switchedOrgID) {
      payload["switchedOrgID"] = switchedOrgID;
    }

    return await Post<{ groups: Array<Group> }>(
      "/masterDashboard/rbac/getAllGroups",
      token,
      payload,
      headers
    );
  }

  static async exportAllGroupsPolicy({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{
    payload: {
      exportSendingEmail: string;
      switchedOrgID?: string;
    };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/exportAllGroupsPolicy`,
      token,
      payload,
      headers
    );
  }

  static async getGroupPermission({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{
    payload: {
      groupName: string;
      switchedOrgID?: string;
    };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/getGroupPermission`,
      token,
      payload,
      headers
    );
  }

  static async getAllMaskingLevels({
    token,
    pageName = "",
  }: ServiceMethodArgs<{}>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get<{ groupMaskingLevels: Array<GroupMaskingLevels> }>(
      "/masterDashboard/rbac/getAllMaskingLevels",
      token,
      headers
    );
  }

  static async exportGroupsPolicy({
    token,
    pageName = "",
    payload,
  }: ServiceMethodArgs<{ payload: { switchedOrgID: string } }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post<{ groupMaskingLevels: Array<GroupMaskingLevels> }>(
      "/masterDashboard/rbac/exportGroupsPolicy",
      token,
      payload,
      headers
    );
  }

  static async createGroup({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{
    payload: {
      groupName: string;
      groupPermissions: Array<GroupPermissions>;
      maskingLevel: string;
      switchedOrgID: string;
    };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/createGroup`,
      token,
      payload,
      headers
    );
  }

  static async addGroupPermission({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{
    payload: {
      groupName: string;
      groupPermissions: Array<GroupPermissions>;
      switchedOrgID: string;
    };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/addGroupPermission`,
      token,
      payload,
      headers
    );
  }

  static async deleteGroupPermission({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{
    payload: {
      groupName: string;
      groupPermissions: Array<GroupPermissions>;
      switchedOrgID: string;
    };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/deleteGroupPermission`,
      token,
      payload,
      headers
    );
  }

  static async deleteGroup({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{
    payload: {
      groupName: string;
      switchedOrgID: string;
    };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/deleteGroup`,
      token,
      payload,
      headers
    );
  }

  static async updateMaskingLevel({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{
    payload: {
      groupName: string;
      newMaskingLevel: string;
      switchedOrgID: string;
    };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/updateMaskingLevel`,
      token,
      payload,
      headers
    );
  }

  static async createRole({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{
    payload: {
      masterUserID: string;
      roles: Array<Roles>;
      switchedOrgID: string;
    };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/createRole`,
      token,
      payload,
      headers
    );
  }

  static async updateRole({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{
    payload: {
      masterUserID: string;
      roles: Array<Roles>;
      switchedOrgID: string;
    };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/updateRole`,
      token,
      payload,
      headers
    );
  }

  static async deleteRole({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{
    payload: {
      masterUserID: string;
      roles: Array<Roles>;
      switchedOrgID: string;
    };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/deleteRole`,
      token,
      payload,
      headers
    );
  }

  static async getAllRoles({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{
    payload: { masterUserID: string; switchedOrgID?: string | null };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/getAllRoles`,
      token,
      payload,
      headers
    );
  }

  static async getRoleHelperData({
    token,
    pageName = "",
    switchedOrgID = null,
  }: ServiceMethodArgs<{ switchedOrgID?: string | null }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const payload: any = {};
    if (switchedOrgID) {
      payload["switchedOrgID"] = switchedOrgID;
    }

    return await Post<{ roleHelperDefinitions: Array<RoleHelperDefinitions> }>(
      "/masterDashboard/rbac/getRoleHelperData",
      token,
      payload,
      headers
    );
  }

  static async createUser({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{
    payload: {
      userDetails: Array<UserDetails>;
      switchedOrgID: string;
    };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/createUser`,
      token,
      payload,
      headers
    );
  }

  static async createTechAdmin({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{ payload: { userDetails: Array<UserDetails> } }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/createTechAdmin`,
      token,
      payload,
      headers
    );
  }

  static async deleteUser({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{
    payload: { masterUserIDs: string[] };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    return await Post(
      `/masterDashboard/rbac/deleteUser`,
      token,
      payload,
      headers
    );
  }

  static async activateUser({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{
    payload: { masterUserIDs: string };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/activateUser`,
      token,
      payload,
      headers
    );
  }

  static async addPlatformAccessToUsers({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{
    payload: {
      sourceEntityID: string;
      groupName: string;
      allUser: boolean;
      masterUserIDs: string[];
      switchedOrgID: string;
    };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/addPlatformAccess`,
      token,
      payload,
      headers
    );
  }

  static async removePlatformsAccessFromUsers({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{
    payload: {
      sourceEntityIDs: string[];
      allUser: boolean;
      masterUserIDs: string[];
      switchedOrgID: string;
    };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/removePlatformsAccess`,
      token,
      payload,
      headers
    );
  }

  static async promoteUserToAdminSettings({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{
    payload: { masterUserID: string };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/promoteUserToAdminSettings`,
      token,
      payload,
      headers
    );
  }

  static async demoteUserAdminSettings({
    token,
    payload,
    pageName = "",
  }: ServiceMethodArgs<{
    payload: { masterUserID: string };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/demoteUserAdminSettings`,
      token,
      payload,
      headers
    );
  }

  static async updateMfaStatus({ token, mfaStatus, pageName, masterUserID }) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rbac/updateMFAStatus`,
      token,
      {
        mfaStatus,
        masterUserIDs: [masterUserID],
      },
      headers
    );
  }
}
