import { FieldError, FieldErrors } from "react-hook-form";

export const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()\[\]\\.,;:+\s@"]+(\.[^<>()\[\]\\.,;:+\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};
export const validatePinCode = (pincode: string): boolean => {
  if (!pincode) return false;
  const re = /^[1-9][0-9]{5}$/;
  return re.test(pincode.toLowerCase());
};
export const validateMobile = (mobile: string): boolean => {
  if (!mobile) return false;
  const re = /^[6-9]\d{9}$/;
  return mobile.length === 10 && !isNaN(Number(mobile)) && re.test(mobile);
};
export const validateMobileNew = (mobile: string): boolean => {
  if (!mobile) return false;
  const re = /^[6-9]\d{9}$/;

  return !isNaN(Number(mobile)) && re.test(mobile) && mobile.length <= 10;
};
export const validatePan = (panVal: string): boolean => {
  var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  return regpan.test(panVal);
};
export const validateBusinessPan = (panVal: string): boolean => {
  var regpan =
    /^[a-zA-Z]{3}[ABCFGHJLTabcfghjlt]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
  return regpan.test(panVal);
};
export const validateGst = (gstNum: string): boolean => {
  const re =
    /^\d{2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?[1-9A-Z]{1}[1-9ZABE-J]{1}[a-zA-Z0-9]{1}$/;
  return re.test(gstNum);
};
export const validateUrl = (url: string): boolean => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(url);
};
export const validatePassword = (password: string): boolean => {
  const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

  return re.test(password);
};

export const validateAgentCode = (agentCode: string): boolean => {
  const re = /^[a-zA-Z0-9_-]{1,36}$/;

  return re.test(agentCode);
};

const VALIDATION_ERRORS = {
  email: {
    validate: "invalid email",
    required: "email is required",
  },
  password: {
    validate: "invalid password",
    required: "password is required",
    minLength: "password has to have minimum of 8 characters",
    maxLength: "password has to be between 8 - 16 characters",
  },
  mobile: {
    validate: "invalid mobile number",
    isValid: "invalid mobile number",
    required: "mobile number is required",
    maxLength: "mobile number must be 10 digits",
    isANumber: "mobile number must consist of numbers only",
  },
  otp: {
    required: "otp is required",
    validate: "otp has to be 6 digits",
  },
  withdrawAmount: {
    isNumber: "should be a number",
    isNotZero: "amount has to be greater than 0",
    required: "required",
  },
  transactionID: {
    required: "required",
    hasNoSpaces: "cannot have spaces",
  },
  note: {
    required: "required",
    hasOnlySpace: "cannot have only spaces",
  },
  awbNo: {
    required: "required",
    hasOnlySpace: "cannot have spaces",
  },
  invoiceNo: {
    required: "required",
    hasOnlySpace: "cannot have spaces",
  },
  invoiceIDs: {
    required: "required",
    hasOnlySpace: "cannot have spaces",
  },
  merchantGstNumber: {
    required: "required",
    hasOnlySpace: "cannot have spaces",
    validate: "invalid GST no.",
  },
  merchantBankAccountNumber: {
    required: "required",
    hasOnlySpace: "cannot have spaces",
  },
  merchantIfscCode: {
    required: "required",
    hasOnlySpace: "cannot have spaces",
  },
  merchantName: {
    required: "required",
    hasOnlySpace: "cannot have spaces",
  },
  name: {
    required: "required",
    hasOnlySpace: "cannot have only spaces",
  },
  userID: {
    required: "required",
    validate:
      "use 1-36 characters: letters, numbers, underscores, or hyphens only.",
  },
  agentCode: {
    required: "required",
    validate:
      "use 1-36 characters: letters, numbers, underscores, or hyphens only.",
  },
};

export const getErrorText = <T extends string>(
  fieldName: T,
  errors,
  validationErrorsList: object = {}
): string => {
  let errorText = "";
  // handle predefined errors
  if (errors?.[fieldName]?.type) {
    if (validationErrorsList?.[fieldName]) {
      errorText =
        validationErrorsList?.[fieldName]?.[errors[fieldName]?.type] ??
        `please enter a valid ${fieldName}`;
    } else {
      errorText =
        VALIDATION_ERRORS?.[fieldName]?.[errors[fieldName]?.type] ??
        `please enter a valid ${fieldName}`;
    }
  }

  if (fieldName === "common") {
    errorText = errors?.common?.message ?? "";
  }

  return errorText;
};

export function checkIfOnlySpecialCharacters(value: string) {
  return /^[\s\-\*\$\%\(\)@#\^\!\=&\~]*$/g.test(value);
}
