import { useState, memo } from "react";
import { Controller } from "react-hook-form";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import {
  DocumentTextIcon,
  PhotoIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import Label from "../../atomic/Label";

type Props = {
  accept: string;
  maxFiles: number;
  onDropAccepted: (files: File[]) => void;
  onDropRejected: () => void;
  control: any;
  setValue: any;
  label: string;
  name: string;
  styles?: any;
  customText: React.ReactNode;
  outsideView: boolean;
  isRequired?: boolean;
};

const FileUploadInput = memo(function FileUploadInput({
  accept,
  maxFiles,
  onDropAccepted,
  onDropRejected,
  control,
  setValue,
  label,
  name,
  styles = {},
  customText,
  outsideView = false,
  isRequired = false,
}: Props) {
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);

  const deleteFile = (index: number) => {
    const updatedFiles = acceptedFiles.filter(
      (_, indx: number) => index !== indx
    );
    setValue(name, updatedFiles as unknown as FileList, {
      shouldValidate: true,
    });
    setAcceptedFiles(updatedFiles);
    onDropAccepted(updatedFiles);
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (val) => {
          return val?.length !== 0;
        },
      }}
      render={({ field: { onChange, onBlur }, fieldState }) => {
        return (
          <Dropzone
            onDrop={(files) => {
              if (outsideView) {
                setAcceptedFiles((prevFiles) => [...prevFiles, ...files]);
              } else {
                setAcceptedFiles(files);
              }
              setValue(name, files as unknown as FileList, {
                shouldValidate: true,
              });
            }}
            onDropAccepted={(files) => {
              if (!outsideView) {
                setAcceptedFiles(files);
              }
              onDropAccepted(files);
            }}
            onDropRejected={onDropRejected}
            maxFiles={maxFiles || 1}
            accept={accept}
          >
            {({ getRootProps, getInputProps, open }) => (
              <div>
                <Label
                  name="file"
                  label={label}
                  requiredIcon={isRequired}
                />
                <div
                  {...getRootProps()}
                  className={classNames(
                    "w-full min-h-[250px] rounded-md border-dashed border border-gray-200 px-4 flex items-center justify-center text-sm bg-gray-100",
                    styles?.inputContainer
                  )}
                >
                  <input
                    {...getInputProps({
                      id: "spreadsheet",
                      onChange,
                      onBlur,
                    })}
                  />

                  {outsideView || acceptedFiles?.length === 0 ? (
                    <p className="">
                      <button
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          open();
                        }}
                      >
                        {customText ? customText : "Choose a file"}
                      </button>{" "}
                      {!customText && "or drag and drop"}
                    </p>
                  ) : null}

                  {!outsideView && (
                    <div className="flex flex-col items-center justify-center text-sm mb-1">
                      {acceptedFiles.length
                        ? acceptedFiles.map((file, index) => (
                            <FileIcon
                              key={index}
                              fileName={file?.name}
                            />
                          ))
                        : null}
                    </div>
                  )}
                </div>

                {outsideView && (
                  <div>
                    {acceptedFiles?.map((file, index) => (
                      <FileIcon
                        key={index}
                        deleteFile={() => {
                          deleteFile(index);
                        }}
                        fileName={file?.name}
                        style={{
                          container:
                            "p-4 rounded-xl mt-3 border justify-between",
                          title: "w-60",
                        }}
                      />
                    ))}
                  </div>
                )}

                {fieldState.error && (
                  <div className="mt-1">
                    <span
                      className="text-red-600 text-xs"
                      role="alert"
                    >
                      {fieldState.error.message}
                    </span>
                  </div>
                )}
              </div>
            )}
          </Dropzone>
        );
      }}
    />
  );
});

export const FileIcon = ({
  fileName,
  style,
  deleteFile,
}: {
  fileName: string;
  deleteFile?: () => void;
  style?: { icon?: string; container?: string; title?: string };
}) => {
  const getFileIcon = (fileType: string) => {
    return fileType === "image" ? (
      <PhotoIcon
        className={classNames("h-5 w-5 text-inherit", style?.icon ?? "")}
      />
    ) : (
      <DocumentTextIcon
        className={classNames("h-5 w-5 text-inherit", style?.icon ?? "")}
      />
    );
  };

  const fileExtension = fileName?.split(".").pop()?.toLowerCase() as string;
  const fileType = ["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(
    fileExtension
  )
    ? "image"
    : "document";

  return (
    <div className={classNames("flex items-center", style?.container ?? "")}>
      <div className="flex items-center">
        <div>{getFileIcon(fileType)}</div>
        <div
          className={classNames(
            "text-xs ml-1 w-32 overflow-hidden overflow-ellipsis whitespace-nowrap",
            style?.title ?? ""
          )}
        >
          {fileName}
        </div>
      </div>
      {deleteFile && (
        <TrashIcon
          onClick={deleteFile}
          className="h-5 w-5 text-[#667085] cursor-pointer hover:text-[#B42318]"
        />
      )}
    </div>
  );
};

export default FileUploadInput;
