import CopyText from "../../atomic/CopyText";

export default function GenerateEMandateModal({ setOpen, url = "" }) {
  return (
    <div>
      <p className="text-sm mb-4 text-gray-800">Web Link Generated!</p>
      <CopyText
        text={url}
        styles={{
          container: "",
          input:
            "w-full bg-gray-100 border border-gray-300 border-dashed text-gray-700 rounded-tl-lg rounded-bl-lg",
        }}
      ></CopyText>
    </div>
  );
}
