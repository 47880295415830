import { Fragment, SetStateAction, Dispatch, PropsWithChildren } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";

interface IProps extends PropsWithChildren {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>> | ((args: boolean) => void);
  title?: string;
  description?: string;
  styles?: {
    panel?: string;
    childrenContainer?: string;
  };
  headerHide?: boolean;
  closeOutside?: boolean;
}

export default function DialogOne({
  open,
  title,
  setOpen,
  children,
  description,
  headerHide = false,
  styles = {},
  closeOutside = true,
}: IProps) {
  function onClose() {
    setOpen(false);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => closeOutside && onClose()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  "relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 mx-0 md:m-4 mb-0 rounded-b-none lg:rounded-b-lg w-full",
                  styles?.panel || "sm:max-w-sm md:max-w-2xl"
                )}
              >
                {!headerHide && (
                  <div className="p-4 xs:p-2 border-b">
                    <div>
                      <button
                        className="float-right"
                        onClick={() => setOpen(false)}
                      >
                        <XMarkIcon className="w-5 h-5 text-gray-700" />
                      </button>
                    </div>
                    {title && (
                      <Dialog.Title>
                        <p className="text-base font-medium mb-1">{title}</p>
                      </Dialog.Title>
                    )}
                    {description && (
                      <Dialog.Description>
                        <p className="text-xs text-gray-500">{description}</p>
                      </Dialog.Description>
                    )}
                  </div>
                )}
                <div
                  className={classNames("p-4", styles?.childrenContainer ?? "")}
                >
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
