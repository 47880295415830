export interface ActiveCreditLineDTO {
  userID: string;
  maxLimit: number;
  availableLimit: number;
  loanApplicationID: string;
  activationDate: string;
  lastTransactionPartnerID: string;
  lastTransactionAt: string;
  lastTransactionAmount: number;
  loanApplicationNo: string;
  name: string;
  mobile: string;
  firmName: string;
  sourceEntityID: string;
  sourceEntityName: string;
}

export interface GetActiveCreditLineRequestDTO {
  page: number;
  limit: number;
  sourceEntityIDs: string | undefined;
}

export enum GetActiveCreditLineSearchType {
  MOBILE = "mobile",
  NAME = "name",
  CUSTOMER_ID = "userID",
  EMAIL = "email",
  PAN = "pan",
  PARTNER_CODE = "partnerCode",
  LOAN_APPLICATION_ID = "loanApplicationNum",
  FIRM_NAME = "firmName",
}

export interface GetActiveCreditLineBySearchQueryRequestDTO
  extends GetActiveCreditLineRequestDTO {
  mobile?: string;
  name?: string;
  userID?: string;
  email?: string;
  pan?: string;
  partnerCode?: string;
  firmName?: string;
  loanApplicationNum?: string;
  pageName?: string;
}

export interface GetActiveCreditLineResponseDTO {
  creditLines: ActiveCreditLineDTO[];
  total: number;
}
