import { Delete, Get, Patch, Post } from "../../shared/services/Fetch";
import { noHeadersResponse } from "../../shared/utils/Global";
import { SourceEntity } from "../Common/interfaces";
import { ServiceMethodArgs } from "../LoanApplication/interfaces";
import {
  AddMemberResponse,
  DeleteMembersResponse,
  DeleteSourceResponse,
  GetIntegrationDetailsResponse,
  GetMemberResponse,
  ListMembersResponse,
  ResetPasswordResponse,
  UpdateWebhookURLResponse,
} from "./interfaces";

export class SettingsService {
  static async listMembers({
    limit,
    page,
    searchQuery,
    sourceEntityIDs,
    searchType,
    token,
    pageName,
  }: ServiceMethodArgs<{
    limit: number;
    page: number;
    searchQuery: string;
    sourceEntityIDs: Array<string>;
    searchType: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    let params = new URLSearchParams({
      page: String(page),
      limit: String(limit),
    });
    if (sourceEntityIDs?.length > 0) {
      sourceEntityIDs.map((sourceEntity) => {
        params.append("sourceEntityIDs", sourceEntity);
      });
    }

    if (searchQuery && searchType) {
      params.append(searchType, searchQuery);
    }

    return await Get<ListMembersResponse>(
      `/masterDashboard/listMembers?${params.toString()}`,
      token,
      headers
    );
  }

  static async deleteMember({
    email,
    token,
    pageName,
  }: ServiceMethodArgs<{ email: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post<DeleteMembersResponse>(
      "/masterDashboard/deleteMember",
      token,
      {
        email,
      },
      headers
    );
  }

  static async deleteSource({
    email,
    sourceEntityIDs,
    token,
    pageName,
  }: ServiceMethodArgs<{
    email: string;
    sourceEntityIDs: Array<SourceEntity>;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post<DeleteSourceResponse>(
      "/masterDashboard/deleteSource",
      token,
      {
        email,
        sourceEntityIDs,
      },
      headers
    );
  }

  static async deleteTheme({
    sourceEntityID,
    token,
    themeID,
    pageName,
  }: ServiceMethodArgs<{
    themeID: string;
    sourceEntityID: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Delete(
      "/masterDashboard/theme",
      token,
      {
        sourceEntityID,
        themeID,
      },
      headers
    );
  }

  static async setActiveTheme({
    sourceEntityID,
    token,
    themeID,
    pageName,
  }: ServiceMethodArgs<{
    themeID: string;
    sourceEntityID: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      "/masterDashboard/setTheme",
      token,
      {
        sourceEntityID,
        themeID,
      },
      headers
    );
  }

  static async creditLinkOperation({
    token,
    method = "POST",
    header = [],
    body,
    pageName,
  }: ServiceMethodArgs<{
    method?: string;
    sourceEntityID?: string;
    header?: any;
    type?: string;
    body: any;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    header.forEach((header: any, key: any) => headers.append(key, header));

    if (method === "PATCH") {
      return await Patch(
        `/masterDashboard/updateCreditLinkdetails`,
        token,
        body,
        headers,
        true
      );
    }
    return await Post(
      `/masterDashboard/addCreditLinkdetails`,
      token,
      body,
      headers,
      true
    );
  }

  static async getCreditLinks({
    sourceEntityID,
    token,
    pageName,
  }: ServiceMethodArgs<{
    sourceEntityID: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/getCreditLinkdetails?sourceEntityID=${sourceEntityID}`,
      token,
      headers
    );
  }

  static async getThemes({
    sourceEntityID,
    token,
    pageName,
  }: ServiceMethodArgs<{
    sourceEntityID: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/themeList?sourceEntityID=${sourceEntityID}`,
      token,
      headers
    );
  }

  static async addTheme({
    body,
    token,
    pageName,
  }: ServiceMethodArgs<{
    body: any;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(`/masterDashboard/addTheme`, token, { ...body }, headers);
  }

  static async resetPassword({
    token,
    email,
    pageName,
  }: ServiceMethodArgs<{ email: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post<ResetPasswordResponse>(
      "/masterDashboard/resetPassword",
      token,
      {
        email,
      },
      headers
    );
  }

  static async getMember({
    token,
    email,
    pageName,
  }: ServiceMethodArgs<{ email: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get<GetMemberResponse>(
      `/masterDashboard/getMember?email=${email}`,
      token,
      headers
    );
  }

  static async getIntegrationDetails({
    sourceEntityID,
    token,
    pageName,
  }: ServiceMethodArgs<{ sourceEntityID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get<GetIntegrationDetailsResponse>(
      `/masterDashboard/getIntegrationDetails?sourceEntityID=${sourceEntityID}`,
      token,
      headers
    );
  }

  static async addMember({
    email,
    mobile,
    name,
    permissions,
    token,
    pageName,
  }: ServiceMethodArgs<{
    email: string;
    mobile: string;
    name: string;
    permissions: Array<any>;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post<AddMemberResponse>(
      `/masterDashboard/addMember`,
      token,
      {
        email,
        mobile,
        name,
        permissions,
      },
      headers
    );
  }

  static async updateWebookURL({
    headerValuePair,
    sourceEntityID,
    webhookURL,
    token,
    pageName,
  }: ServiceMethodArgs<{
    headerValuePair: Array<{ header: string; value: string }>;
    sourceEntityID: string;
    webhookURL: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post<UpdateWebhookURLResponse>(
      `/masterDashboard/updateWebhookURL`,
      token,
      {
        headerValuePair,
        sourceEntityID,
        webhookURL,
      },
      headers
    );
  }
}
