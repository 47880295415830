import { AutocompleteOption } from "../../components/atomic/Dropdown/AutocompleteCombobox";
import { ExportsHistoryResponseDTO } from "../../dtos/ExportsDTO";
import { CommonService } from "../../services/Common";
import ReportsService from "../../services/Reports";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

const computeReportPermissions = (permissions: any) => {
  const REPORTS_PERMISSIONS: any = {};
  for (const sourceEntityID of Object.keys(permissions)) {
    for (const report of permissions[sourceEntityID].filter(
      (key: any) => key != "NullField"
    )) {
      if (!(report in REPORTS_PERMISSIONS)) {
        REPORTS_PERMISSIONS[report] = {};
      }
      if (!(sourceEntityID in REPORTS_PERMISSIONS[report])) {
        REPORTS_PERMISSIONS[report][sourceEntityID] = true;
      }
    }
  }
  return REPORTS_PERMISSIONS;
};

export default async function reportsLoader({
  getToken,
  pageName,
  getPermissions,
}: CustomLoaderFunctionArgs) {
  let sourceEntityListOption: AutocompleteOption[] = [];
  let preLoadedExportData: ExportsHistoryResponseDTO = {
    exports: [],
    total: 0,
  };
  const permissions = getPermissions();
  const REPORTS_PERMISSIONS = computeReportPermissions(permissions[pageName]);

  const token = getToken();
  const [sourceEntityListResponse, exportHistoryResponse] = await Promise.all([
    CommonService.getSourceEntityList({
      token,
      pageName,
    }),
    ReportsService.getExportHistory({
      page: 1,
      limit: 10,
      token,
      pageName,
    }),
  ]);

  if (sourceEntityListResponse?.status) {
    let { sourceList } = sourceEntityListResponse.data;
    sourceEntityListOption = sourceList.map((el) => ({
      id: el.sourceEntityID,
      name: el.sourceEntityName,
    }));
  }
  if (exportHistoryResponse?.status) {
    preLoadedExportData = exportHistoryResponse.data;
  }

  return {
    preLoadedExportData,
    sourceEntityListOption,
    REPORTS_PERMISSIONS,
  };
}
