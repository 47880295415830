import { defer } from "react-router";
import { OverdraftService } from "../../services/OverDraft";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";
import { PlatformInteface } from "./interfaces";

export default async function overdraftLoader({
  getToken,
  request,
  pageName,
}: CustomLoaderFunctionArgs) {
  const token = getToken();
  const url = new URL(request.url);

  const filterData = JSON.parse(
    window?.localStorage?.getItem("filters") || "{}"
  );
  const overdraftPage = filterData?.overdraftPage;

  return defer({
    billsResponse: OverdraftService.getOverdraftBills({
      token,
      page: url.searchParams.has("page")
        ? Number(url.searchParams.get("page"))
        : 1,
      limit: 10,
      statusText: url.searchParams.getAll("statusText"),
      searchQuery: url.searchParams.get("searchText") || "",
      searchType: url.searchParams.get("searchType") || "",
      from: url.searchParams.has("from")
        ? new Date(url.searchParams.get("from") as string)
        : null,
      to: url.searchParams.has("to")
        ? new Date(url.searchParams.get("to") as string)
        : null,
      dateType: url.searchParams.get("dateType") ?? "updated_at",
      sourceEntityIDs:
        overdraftPage?.platform?.map(
          (source: PlatformInteface) => source?.value
        ) || [],
      pageName,
    }),
    storedFilters: overdraftPage,
  });
}
