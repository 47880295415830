import { Tooltip } from "react-tooltip";
import Button from "../../components/atomic/Button";
import { useEffect, useMemo, useState } from "react";
import { useFetcher, useOutletContext, useParams } from "react-router-dom";

import { ListItem } from "../LeadDetails";
import { Get, Post } from "../../shared/services/Fetch";
import GetAuthContext from "../../shared/context/AuthContext";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";
import AddAdditionalDocsModal from "../../components/composed/LoanApplicationPageModals/AddAdditionalDocsModal";
import { PageProps } from "../../services/Common/interfaces";
import { noHeadersResponse } from "../../shared/utils/Global";
import PrivateComponent from "../../components/composed/PrivateComponent";
import { ACTIONS_LIST } from "../../shared/utils/constants";
import { WORKFLOWS_WITH_DOCS } from "./constants";
import { CollapsibleSidebar } from "../../components/atomic/CollapsibleSidebar";
import Title from "../LoanTermDeviation/Title";
import { DocumentPlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import Share from "./Share";

export async function loanAdditionalDocsLoader({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const { loanID } = params;
  const headers = new Headers();
  if (pageName) {
    headers.append("page-header", pageName);
  } else {
    return noHeadersResponse();
  }
  return await Get(
    `/masterDashboard/docs?loanApplicationID=${loanID}`,
    getToken(),
    headers
  );
}

function LoanAdditionalDocs({ pageName }: PageProps) {
  const fetcher = useFetcher();
  const { fetchToken } = GetAuthContext();
  const { loanID, sourceEntityID } = useParams();
  const [isShowAddAdditionalDocsModal, setIsShowAddAdditionalDocsModal] =
    useState<boolean>(false);
  const { loanDetails, workflowLoaderData, reloadWorkflowDetails } =
    useOutletContext();

  const activeWorkflows = useMemo(() => {
    const workflows = WORKFLOWS_WITH_DOCS.filter((workflowWithDoc) =>
      workflowLoaderData?.workflows
        ?.filter(
          (workflow) => workflow.workflowID === workflowWithDoc.workflowID
        )
        .some(
          (workflow) =>
            workflowWithDoc.status.includes(workflow.status) &&
            (workflowWithDoc.actions
              ? workflowWithDoc.actions.some(
                  (action) => workflow.actions[action]
                )
              : true)
        )
    ).map((workflow) => {
      return {
        name: workflow.name,
        value: workflow.key,
        workflowID: workflow.workflowID,
      };
    });
    workflows.push({
      name: "Other",
      key: "other",
    });
    return workflows;
  }, [workflowLoaderData]);

  const URL = `/loan-applications/details/${sourceEntityID}/${loanID}/additional-docs`;

  useEffect(() => {
    fetcher.load(URL);
  }, []);

  async function deleteDocs(docID: string) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const response = await Post(
      `/masterDashboard/deleteDocs`,
      fetchToken(),
      {
        loanApplicationID: loanID,
        docID,
      },
      headers
    );

    if (response.status) {
      fetcher.load(URL);
    } else {
      console.log(response.error);
    }
  }

  async function onAddDocumentClick() {
    setIsShowAddAdditionalDocsModal(true);
  }

  const reloadAdditionalDocsData = async () => {
    fetcher.load(URL);
  };
  const reloadWorkflowAndAdditionalDocs = () => {
    reloadAdditionalDocsData();
    if (activeWorkflows && activeWorkflows.length > 0) {
      reloadWorkflowDetails();
    }
  };

  const { documents = [] } = fetcher?.data?.data ?? {};
  if (fetcher.state !== "loading" && fetcher.state !== "submitting") {
    return (
      <>
        <div className="px-4 relative">
          <h3 className="text-sm text-gray-700 font-medium w-max border-b-2 border-indigo-500 py-2 pr-2">
            Additional Docs
          </h3>
          <div className="flex items-center justify-between font-medium absolute top-2 right-4">
            <PrivateComponent
              requiredPage={pageName!}
              sourceEntityId={sourceEntityID}
              requiredFunctions={[ACTIONS_LIST.ADD_DOCUMENTS]}
            >
              <Button
                outline
                color="primary"
                className="flex border-[1.5px] items-center"
                onClick={onAddDocumentClick}
              >
                <DocumentPlusIcon className="w-5 h-5 mr-2" />
                Add Document
              </Button>
            </PrivateComponent>
          </div>

          <div className="mt-8">
            {documents?.map((document: any, documentIdx: number) => {
              return (
                <div
                  className="p-4 rounded-lg border border-gray-200 mb-4 relative"
                  key={documentIdx}
                >
                  <ListItem
                    className="mb-4 flex flex-col"
                    label="Document ID"
                    value={document.docID}
                  />
                  <ListItem
                    label="Comment"
                    className="mb-4"
                    value={document.comment || "none"}
                  />
                  <div>
                    <p className="text-xs font-medium text-gray-500 mb-1">
                      Documents
                    </p>
                    <ul className="pl-4">
                      {document.urls.map((url: any, urlIdx: number) => (
                        <li
                          className="list-disc text-indigo-700"
                          key={urlIdx}
                        >
                          <a
                            href={url}
                            target="_self"
                            className="text-xs text-indigo-700"
                          >
                            Document {urlIdx + 1}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="absolute top-3 right-3">
                    <div className="flex items-center">
                      <PrivateComponent
                        requiredPage={pageName!}
                        sourceEntityId={sourceEntityID}
                        requiredFunctions={[
                          ACTIONS_LIST.PUSH_ADDITIONAL_DOCUMENT,
                          ACTIONS_LIST.CATEGORY_DROPDOWN,
                        ]}
                      >
                        <Share
                          pageName={pageName}
                          document={document}
                          reload={reloadAdditionalDocsData}
                        />
                      </PrivateComponent>
                      <PrivateComponent
                        requiredPage={pageName!}
                        sourceEntityId={sourceEntityID}
                        requiredFunctions={[ACTIONS_LIST.DELETE_DOCUMENTS]}
                      >
                        <Button
                          outline
                          onClick={() => {
                            deleteDocs(document.docID);
                          }}
                          data-tooltip-id="deleteTooltip"
                          data-tooltip-content="Delete Document"
                          className="!px-2 !text-[#8D8D8E] !border-[#8D8D8E] hover:!border-red-500 hover:!text-red-500 ml-2 !shadow-[0_1px_2px_0px_rgba(6, 24, 40, 0.05)]"
                        >
                          <TrashIcon className="h-4 w-4 !text-inherit hover:text-inherit" />
                        </Button>
                      </PrivateComponent>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <CollapsibleSidebar
          show={isShowAddAdditionalDocsModal}
          setShow={setIsShowAddAdditionalDocsModal}
        >
          <>
            <div className="mb-6">
              <Title
                title="Upload Documents"
                close={() => setIsShowAddAdditionalDocsModal(false)}
              />
            </div>
            <AddAdditionalDocsModal
              pageName={pageName}
              userID={loanDetails.userID}
              loanApplictionID={loanID || ""}
              key={isShowAddAdditionalDocsModal}
              workflowRequests={activeWorkflows}
              reload={reloadWorkflowAndAdditionalDocs}
              setOpen={setIsShowAddAdditionalDocsModal}
            />
          </>
        </CollapsibleSidebar>
        <Tooltip
          id="deleteTooltip"
          className="!bg-[#212121] !text-xs !font-semibold !rounded-lg"
        />
      </>
    );
  } else {
    return (
      <div className="text-sm">Additional Documents will appear here.</div>
    );
  }
}

export default LoanAdditionalDocs;
