import React from "react";
import classNames from "classnames";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { CollapsibleSidebarProps } from "./types";

export const CollapsibleSidebar = (props: CollapsibleSidebarProps) => {
  React.useLayoutEffect(() => {
    const addFixedToBody = () => {
      document.body.style.position = "fixed";
      document.body.style.top = "0";
      document.body.style.left = "0";
      document.body.style.right = "0";
      document.body.style.bottom = "0";
    };

    const removeFixedFromBody = () => {
      document.body.style.position = "";
    };

    if (props.show) {
      addFixedToBody();
    } else {
      removeFixedFromBody();
    }

    return () => {
      removeFixedFromBody();
    };
  }, [props.show]);

  return (
    <>
      {props.show ? (
        <div
          onClick={() => props.setShow(false)}
          className="top-0 right-0 w-screen h-screen bg-black !bg-opacity-50 fixed"
        />
      ) : null}
      <div
        className={classNames(
          "top-0 right-0 min-w-[500px] w-[35vw] bg-white border-l p-[40px] fixed h-full z-40 ease-in-out duration-300 overflow-y-auto",
          props.show ? "translate-x-0 " : "translate-x-full",
          props?.style?.container ?? ""
        )}
      >
        {!!props?.title && (
          <div className="flex justify-between items-center text-[#343F59] text-xl">
            <p>{props.title}</p>
            <XMarkIcon
              className="w-5 h-5 cursor-pointer"
              onClick={() => props.setShow(false)}
            />
          </div>
        )}
        {props.children}
      </div>
    </>
  );
};
