export async function getAllGroups() {
  return await Promise.resolve({
    data: {
      groups: [
        {
          groupName: "Credit",
          masking: "L1",
          maskingLevelMeaning: "mask email",
          isDefaultGroup: true,
        },
        {
          groupName: "Sales",
          masking: "L2",
          maskingLevelMeaning: "mask email",
          isDefaultGroup: true,
        },
        {
          groupName: "Technical Admin",
          masking: "L1",
          maskingLevelMeaning: "mask email",
          isDefaultGroup: false,
        },
      ],
    },
    error: null,
    status: true,
    message: "success",
  });
}

export async function getPageAPIMapping() {
  return await Promise.resolve({
    data: {
      groupPermissions: [
        {
          pageName: "LOAN APPLICATION PAGE",
          functionalAPIs: ["Reject Loan", "Approve Loan"],
        },
      ],
    },
    error: null,
    status: true,
    message: "success",
  });
}

export async function getAllMaskingLevels() {
  return await Promise.resolve({
    data: {
      groupMaskingLevels: [
        {
          masking: "L1",
          maskingLevelMeaning: "Mask email",
        },
      ],
    },
    error: null,
    status: true,
    message: "success",
  });
}

export async function getGroupPermissions() {
  return {
    data: [
      {
        pageName: "LOAN APPLICATION PAGE",
        isActive: true,
        functionalAPIs: [
          {
            name: "Reject Loan",
            isActive: true,
          },
          {
            name: "Reject Loan 2",
            isActive: true,
          },
          {
            name: "Reject Loan 3",
            isActive: true,
          },
          {
            name: "Approve Loan",
            isActive: false,
          },
        ],
      },
      {
        pageName: "LEAD APPLICATION PAGE",
        isActive: true,
        functionalAPIs: [
          {
            name: "SAVE LEAD",
            isActive: true,
          },
          {
            name: "EDIT LEAD STATUS",
            isActive: true,
          },
        ],
      },
    ],
  };
}
