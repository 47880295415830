import React, { Component, ReactNode } from "react";
import Button from "../atomic/Button";

type Props = {
  title: string;
  icon?: ReactNode;
  isDisabled?: boolean;
  onClick: () => void;
  tooltip?: {
    id: string;
    message: string;
  };
};

export default function ActionButton({
  title,
  icon,
  onClick,
  tooltip,
  isDisabled = false,
}: Props) {
  return (
    <>
      <Button
        onClick={onClick}
        disabled={isDisabled}
        className="w-full flex items-center justify-start truncate bg-white text-[#626262] text-sm font-medium hover:bg-indigo-700 hover:ring-indigo-700 hover:text-white"
      >
        {!!icon && <span className="w-max">{icon}</span>}
        {title}
      </Button>
    </>
  );
}
