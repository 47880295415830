import { defer } from "react-router";

import { Loader } from "../Admin/interface";
import { AdminService } from "../../services/Admin";

export default async function userManagementLoader({
  request,
  getToken,
  pageName,
}: Loader) {
  const token = getToken();
  const url = new URL(request.url);
  let searchParams = Object.fromEntries(url.searchParams);

  const params = {
    limit: 10,
    filters: {},
    page: +searchParams.page || 1,
    searchText: url.searchParams.get("searchText") ?? "",
    searchType: url.searchParams.get("searchType") ?? "",
    mobile: url.searchParams.get("mobile") ?? "",
    status: url.searchParams.get("status") ?? "ACTIVE",
    switchedOrgID: url.searchParams.get("platformId"),
  } as any;

  if (url?.searchParams?.has("filter")) {
    params["filterType"] = "rbacGroupName";
    params["filter"] = url.searchParams.get("filter");
  }

  const response = await AdminService.getAllUsers({
    token,
    ...params,
    pageName,
  });

  return defer({
    userResponse: response ?? {},
  });
}
