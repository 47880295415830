import classNames from "classnames";
import React, { ReactNode, useState } from "react";
import { copyToClipboard } from "../../../shared/utils/DateUtil";
import { DocumentCheckIcon } from "@heroicons/react/24/outline";

interface CopyTextProps {
  text: ReactNode;
  styles?: {
    container?: string;
    input?: string;
    button?: string;
  };
  copyButton?: ReactNode;
  copyingButton?: ReactNode;
}

const CopyText = ({
  text,
  styles,
  copyButton,
  copyingButton,
}: CopyTextProps) => {
  const [copyButtonText, setCopyButtonText] = useState(copyButton ?? "Copy");

  const onCopy = async () => {
    if (navigator?.clipboard) {
      navigator?.clipboard?.writeText(text);
    } else {
      await copyToClipboard(text);
    }
    setCopyButtonText(
      copyingButton ?? <DocumentCheckIcon className="h-4 w-auto" />
    );
    setTimeout(() => {
      setCopyButtonText(copyButton ?? "Copy");
    }, 2000);
  };

  return (
    <div
      className={classNames(
        "flex items-center justify-between w-full rounded-md text-xs",
        styles?.container || "bg-indigo-50  text-indigo-400"
      )}
    >
      <input
        className={classNames(
          "min-w-fit outline-none h-8 pl-3 pr-1 text-sm",
          styles?.input || "text-indigo-600 bg-transparent"
        )}
        value={text}
        title={text}
        readOnly
      />
      <button
        type="button"
        className={classNames(
          " rounded-r-md h-8 w-20 text-xs",
          styles?.button || "bg-indigo-600 text-white"
        )}
        onClick={onCopy}
      >
        {copyButtonText}
      </button>
    </div>
  );
};

export default CopyText;
