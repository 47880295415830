import { ReactNode } from "react";
import classNames from "classnames";

type Props = {
  name: string;
  style?: string;
  label: ReactNode;
  requiredIcon?: boolean;
};

export default function Label(props: Props) {
  const { name, label, style, requiredIcon } = props;
  return (
    <label
      htmlFor={name}
      className={classNames(
        "block text-xs font-medium text-gray-700 mb-2",
        style ? style : ""
      )}
    >
      {label}
      {requiredIcon && (
        <span className="text-inherit text-red-400 ml-1">*</span>
      )}
    </label>
  );
}

export const labelModifier = (
  text: string,
  isRequired = false,
  isOptional = false,
  additionalText = ""
) => (
  <>
    {isRequired && <span className="text-[10px] text-red-500 mr-0.5">*</span>}
    {text}
    {isOptional && (
      <span className="text-gray-500 ml-0.5 text-[11px]">{"(optional)"}</span>
    )}
    {additionalText && (
      <span className="text-gray-500 ml-0.5 text-[11px]">{`(${additionalText})`}</span>
    )}
  </>
);
