import classNames from "classnames";
import { XMarkIcon } from "@heroicons/react/24/outline";

import Button from "../../components/atomic/Button";
import { ChevronLeft } from "@untitled-ui/icons-react";

type Props = {
  title: string;
  back?: boolean;
  close?: () => void;
  backAction?: () => void;
};

export default function Title({
  close,
  title,
  backAction,
  back = false,
}: Props) {
  return (
    <div
      className={classNames("flex items-center", back ? "" : "justify-between")}
    >
      {back && (
        <Button outline onClick={backAction} className="border-none !p-0 mr-5">
          <ChevronLeft className="h-6 w-6 text-[#4C4C4D]" />
        </Button>
      )}
      <div className="text-xl font-medium text-[#343F59]">{title}</div>
      {!back && (
        <Button onClick={close} outline className="border-none">
          <XMarkIcon className="h-6 w-6 text-[#4C4C4D]" />
        </Button>
      )}
    </div>
  );
}
