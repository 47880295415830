import {IAuthService} from "../../services/apis/authService";

export abstract class BaseAPI {
  protected baseUrl: string;
  public authService: IAuthService;
  protected constructor(authService: IAuthService) {
    this.authService = authService;
    this.baseUrl = import.meta.env.REACT_APP_API_ENDPOINT;
  }

  protected async get(
    url: string,
    params?: any,
    headers: any = {}
  ): Promise<any> {
    const response = await fetch(
      `${import.meta.env.REACT_APP_API_ENDPOINT}${url}`,
      {
        method: "GET",
        headers: {
          ...headers,
          token: this.authService.getToken(),
        },
      }
    );
    return await response.json();
  }

  protected async post(
    url: string,
    data?: any,
    params?: any,
    headers: any = {}
  ): Promise<any> {
    const response = await fetch(
      `${import.meta.env.REACT_APP_API_ENDPOINT}${url}`,
      {
        method: "GET",
        headers: {
          ...headers,
          token: this.authService.getToken(),
        },
        body: JSON.stringify(data),
      }
    );
    return await response.json();
  }
}
