import { CreditLineService } from "../../services/CreditLine";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

export default async function creditLineDetailsLoader({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const { loanID } = params;
  const [creditLineDetailsResponse, preLimitResponse] = await Promise.all([
    await CreditLineService.getCreditLineDetails({
      token: getToken(),
      loanApplicationID: loanID as string,
      pageName,
    }),
    await CreditLineService.getPreLimit({
      token: getToken(),
      loanApplicationID: loanID as string,
      pageName,
    }),
  ]);

  return {
    creditLineDetailsResponse,
    preLimitResponse,
  };
}
