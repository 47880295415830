import React from "react";
import { useParams } from "react-router-dom";

import Title from "./Title";
import { DeviationProps } from "./interface";
import Button from "../../components/atomic/Button";
import { PAGES_LIST } from "../../shared/utils/constants";
import { notify } from "../../shared/context/AlertContext";
import GetAuthContext from "../../shared/context/AuthContext";
import Input from "../../components/composed/FormElements/Input";
import { LoanApplicationService } from "../../services/LoanApplication";

export default function Cancel(props: DeviationProps) {
  const { fetchToken } = GetAuthContext();
  const { loanID } = useParams();
  const [cancelComment, setCancelComment] = React.useState("");
  const loanTermInfo = props?.timelineInfo?.[0] ?? {};

  const submitHandler = async (data: any) => {
    const payloadInfo = {
      ...loanTermInfo,
      action: "cancelled",
      generalComments: cancelComment,
    };
    const payload = {
      token: fetchToken(),
      payload: payloadInfo,
      loanApplicationID: loanID,
      pageName: PAGES_LIST.LOAN_APPLICATION_LOAN_TERM_DEVIATION_TAB,
    };
    const response = await LoanApplicationService.saveLoanTermDeviation(
      payload
    );

    if (response?.status) {
      notify({
        type: "success",
        title: "Success",
        text: "Cancelled Loan Term Deviation Proposal Successfully",
      });
      setTimeout(() => window.location.reload(), 1000);
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  };

  return (
    <>
      <Title
        title="Cancel Proposal"
        close={() => props?.setIsSliderOpen(false)}
      />
      <div className="bg-[#FFFCF5] p-4 rounded-xl text-[#B54708] text-sm border border-[#FEC84B] shadow-sm shadow-[#1018280d] mt-6">
        <img
          alt="warning"
          src="/warning.png"
          className="h-8 w-8"
        />
        <div className="font-semibold mt-2">
          Just to let you know this might be a problem
        </div>
        <div className="font-normal mt-1">
          This action is irreversible. Once cancelled, no further actions can be
          taken on this proposal.
        </div>
      </div>
      <div className="mt-6 pt-6 border-t">
        <fieldset className="w-full">
          <Input
            type="text"
            multiple={true}
            value={cancelComment}
            name="Cancellation Reason"
            label="Cancellation Reason"
            onChange={(e: any) => {
              setCancelComment(e?.target?.value);
            }}
            placeholder="comments if you have any suggestions that can improve the Proposal."
          />
        </fieldset>
        <Button
          onClick={submitHandler}
          disabled={!Boolean(cancelComment)}
          className="!bg-[#B42318] text-white mt-6"
        >
          Cancel Proposal
        </Button>
      </div>
    </>
  );
}
