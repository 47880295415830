import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { formatDistance } from "date-fns/esm";
import { enIN } from "date-fns/locale";
import Input from "../../../components/composed/FormElements/Input";
import enUS from "date-fns/esm/locale/en-US/index.js";
import { addHours, addMinutes, format } from "date-fns";
import { getErrorText } from "../../../shared/utils/FormUtil";

interface NotesSlideoutProps {
  title: string;
  open: boolean;
  setOpen: Function;
  notes: any[];
  onSubmit: Function;
}

export default function NotesSlideout({
  title = "",
  open = false,
  setOpen,
  notes = [],
  onSubmit,
}: NotesSlideoutProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  async function onFormSubmit(formData: any) {
    if (formData.note) {
      await onSubmit({ note: formData.note?.trim() });
      reset();
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={(state) => {
          setOpen(state);
          reset();
        }}
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-xs md:max-w-md">
                  <form
                    className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                    onSubmit={handleSubmit(onFormSubmit)}
                  >
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {title}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md text-white focus:outline-none"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-white">
                            Get started by adding a new note.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4">
                          <div className="space-y-2 pt-4 pb-4">
                            <div>
                              {notes.map((note, itemIdx) => {
                                let createdAt = addHours(
                                  new Date(note.createdAt),
                                  5
                                );
                                createdAt = addMinutes(createdAt, 30);
                                return (
                                  <div
                                    key={itemIdx}
                                    className="border-b border-gray-200 py-4"
                                  >
                                    <p className="text-xs text-gray-700 mb-2">
                                      {note.note}
                                    </p>
                                    <div className="flex items-center justify-between">
                                      <p className="truncate w-1/2 text-xs text-gray-400">
                                        By {note.createdBy}
                                      </p>
                                      <p
                                        className="w-1/2 text-right min-w-max text-xs text-gray-400"
                                        title={format(
                                          createdAt,
                                          "dd MMM, yyyy - hh:mm aa"
                                        )}
                                      >
                                        Created{" "}
                                        {formatDistance(createdAt, new Date(), {
                                          locale: enIN,
                                        })}{" "}
                                        ago
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="justify-end px-4 py-4">
                      <Input
                        register={register("note", {
                          required: true,
                          validate: {
                            hasOnlySpace: (val) => val.trim()?.length > 0,
                          },
                        })}
                        label="Add a note (Press Enter to save)"
                        styles={{
                          container: "w-full mb-4 md:mr-4 md:mb-0 disabled",
                          input: "text-xs placeholder:text-xs border",
                          label: "text-xs",
                        }}
                        placeholder=""
                        error={errors.note}
                        errorText={getErrorText("note", errors)}
                      ></Input>
                    </div>

                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-6 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none "
                        onClick={() => {
                          setOpen(false);
                          reset();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-6 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none "
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
