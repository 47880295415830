import classNames from "classnames";
import { Outlet, useParams } from "react-router-dom";
import { ReactNode, useEffect, useState } from "react";
import {
  ForwardIcon,
  BackwardIcon,
  UserMinusIcon,
  SquaresPlusIcon,
  PencilSquareIcon,
  CurrencyRupeeIcon,
  ArrowUturnLeftIcon,
  ClipboardDocumentIcon,
  ExclamationTriangleIcon,
  ArrowPathRoundedSquareIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";

import { LeadDTO } from "../../dtos/LeadDTO";
import { Get } from "../../shared/services/Fetch";
import { LeadsService } from "../../services/Leads";
import { TabsOne } from "../../components/atomic/Tabs";
import { notify } from "../../shared/context/AlertContext";
import { copyToClipboard } from "../../shared/utils/DateUtil";
import GetAuthContext from "../../shared/context/AuthContext";
import DialogOne from "../../components/atomic/Dialog/DialogOne";
import ActionButton from "../../components/composed/ActionButton";
import { leadDetailsFields, leadDetailSidebarFields } from "./constant";
import { BreadCrumb } from "../../components/atomic/Breadcrumbs/interface";
import GenerateLinkModal from "../../components/composed/GenerateLinkModal";
import { TimelineObject } from "../../components/atomic/ActivityTimeline/interface";
import DetailsSidebar from "../../components/composed/DetailsSidebar/DetailsSidebar";
import {
  ACTIONS_LIST,
  SOURCE_ENTITY_IDS,
  USER_STAGE_FILTERS,
} from "../../shared/utils/constants";
import { ProductType } from "../../services/Partners/interfaces";
import PageHeader from "../../components/atomic/Header/PageHeader";
import GetMixpanelContext from "../../shared/context/MixpanelContext";
import { LoanApplicationService } from "../../services/LoanApplication";
import SkipBankConnectModal from "../../components/composed/SkipBankConnectModal";
import InitiateNewApplicationModal from "../../components/composed/InitiateNewApplicationModal";
import DeactivateAgentModal from "../../components/composed/PartnersPageModals/DeactivateAgentModal";
import {
  getTabsByProductType,
  noHeadersResponse,
  removeRetriggerItem,
  updateRetriggerList,
} from "../../shared/utils/Global";
import {
  checkIfDsaUser,
  checkIfPlatformDsa,
} from "../../shared/utils/PermissionUtil";

import ExpireUserModal from "../../components/composed/ExpireUserModal";
import GetLeadsContext, { withUserContext } from "./states/LeadsContext";
import ManualOfferModal from "../../components/composed/ManualOfferModal";
import PrivateComponent from "../../components/composed/PrivateComponent";
import ConfirmationDialog from "../../components/composed/ConfirmationModal/ConfirmationDialog";

interface IListItemProps {
  value: ReactNode;
  className?: string;
  allowCopy?: boolean;
  isExcludeEmpty?: boolean;
  label: string | JSX.Element;
}

interface WebLinkResponse {
  url: string;
}

type OfferDetails = {
  amount: number;
  tenure: number;
  maxEMI: number;
  reason: string;
  interest: number;
  processingFee: number;
  processingFeeType: string;
};

type Payload = {
  token: string;
  payload: {
    userID: string;
    action: string;
    offerDetails: OfferDetails;
  };
};

export function ListItem({
  label,
  value = "",
  className,
  allowCopy,
}: IListItemProps) {
  async function onCopyClick(value: any) {
    if (value) {
      const copySuccess = await copyToClipboard(value);
      if (copySuccess) {
        notify({
          text: "Copied!",
          type: "success",
          title: "Success",
        });
      } else {
        notify({
          type: "error",
          title: "Error",
          text: "Copy Failed!",
        });
      }
    } else {
      notify({
        type: "info",
        title: "Info",
        text: "No text to copy",
      });
    }
  }

  return value ? (
    <div className={classNames("sm:col-span-1 relative", className || "")}>
      <div className="text-xs font-medium text-gray-500">{label}</div>
      <div className="mt-1 text-xs text-gray-700">{value}</div>
      {!!allowCopy && (
        <>
          <button
            className="absolute top-4 right-0 px-2 text-gray-500"
            onClick={() => onCopyClick(value)}
            title="Copy field value"
          >
            <ClipboardDocumentIcon className="w-5 h-5 ml-4" />
          </button>
        </>
      )}
    </div>
  ) : null;
}

function LeadDetails() {
  const { sourceEntityID = "", userID = "", programID } = useParams();
  const { setCurrentScreen, trackUserAction } = GetMixpanelContext();

  const { fetchToken, userState, getPageConfig } = GetAuthContext();
  const { permissions } = userState ?? {};
  const pageConfig = getPageConfig();
  const [retriggerClicked, setRetriggerClicked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isMoreLoading, setIsMoreLoading] = useState<boolean>(false);
  const [userActivity, setUserActivity] = useState<Array<TimelineObject>>([]);
  const [page, setPage] = useState<number>(1);
  const [showMoreAvailable, setShowMoreAvailable] = useState<boolean>(true);
  const [userProfile, setUserProfile] = useState<LeadDTO>();
  const [isGenerateLinkModalOpen, setIsGenerateLinkModalOpen] =
    useState<boolean>(false);
  const [webLink, setWebLink] = useState<string>("");
  const [leadsDetails, setLeadsDetails] = useState<any>({});
  const [showSkipBankConnectModal, setShowSkipBankConnectModal] =
    useState<boolean>(false);
  const [showInitiateNewApplicationModal, setShowInitiateNewApplicationModal] =
    useState<boolean>(false);
  const [showReassignLeadModal, setShowReassignLeadModal] =
    useState<boolean>(false);
  const [showExpireUserModal, setShowExpireUserModal] =
    useState<boolean>(false);
  const isMobileView = window.innerWidth < 640;
  const [waitStateData, setWaitStateData] = useState<any>(null);
  const [waitLoading, setWaitLoading] = useState(false);
  const [loanApplicationDetails, setLoanApplicationDetails] = useState(null);

  // tools state
  const [revertDisqualificationOpen, setRevertDisqualificationOpen] =
    useState<boolean>(false);
  const [manualOfferOpen, setManualOfferOpen] = useState<boolean>(false);
  const [showEditLoanOffer, setShowEditLoanOffer] = useState<boolean>(false);
  const [manualOfferPayload, setManualOfferPayload] = useState({} as Payload);
  const { pageName } = GetLeadsContext();

  useEffect(() => {
    setCurrentScreen("Lead Details Page", {
      sourceEntityID,
      userID,
    });
  }, []);

  // const { leadsDetails, fetchLeadsDetails } = GetLeadsContext();
  let timer = 0;

  let retriggerList = localStorage.getItem("retriggerList") ?? {};

  useEffect(() => {
    getInitialData().then(() => {
      console.log("Fetched initial data");
    });

    return () => {};
  }, []);

  useEffect(() => {
    if (page > 1) {
      getTimeline().then(() => console.log("Reloaded Timeline"));
    }
  }, [page]);

  const retriggerDeviceAPI = async (pageName: string) => {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const response = await Get(
      `/masterDashboard/retriggerDevice?userID=${userID}`,
      fetchToken(),
      headers
    );
    if (response.status) {
      updateRetriggerList(userID);
      pollUserData();
    }
  };

  const manualOfferSubmit = async (offer: Payload) => {
    const response = await LeadsService?.disqualifyRevert(offer);
    if (response?.status) {
      notify({
        type: "success",
        title: "Success",
        text: "Revert Disqualification is done.",
      });
      reloadLeadDetails();
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  };

  async function getInitialData() {
    if (
      retriggerList?.["list"]?.find(
        (id: string) => id == leadsDetails?.customerID
      )
    ) {
      await pollUserData();
    } else {
      const payload: any = {
        token: fetchToken(),
        userID,
        pageName,
      };
      if (programID !== "default") {
        payload["programID"] = programID;
      }
      let response = (await LeadsService.getUserProfile(payload)) as any;
      let profile = null;
      if (response.status) {
        if (response.data?.userProfile) {
          profile = response.data.userProfile;
          setUserProfile(response.data?.userProfile);
          setLeadsDetails(response.data?.userProfile);
        }
      } else {
        notify({
          title: "Failed to fetch",
          text: response.error,
        });
      }

      if (profile?.loanApplicationIDs?.[0]) {
        response = await LoanApplicationService.getWaitState({
          token: fetchToken(),
          loanApplicationID: profile?.loanApplicationIDs?.[0],
          pageName,
        });

        if (response.status) {
          setWaitStateData(response.data);
        }

        response = await LoanApplicationService.getLoanDetails({
          pageName,
          token: fetchToken(),
          loanApplicationID: profile?.loanApplicationIDs?.[0],
        });

        if (response.status) {
          setLoanApplicationDetails(response.data);
        }
      }
    }
    await getTimeline();
  }

  async function pollUserData() {
    setRetriggerClicked(true);
    const payload: any = {
      token: fetchToken(),
      userID,
      pageName,
    };
    if (programID !== "default") {
      payload["programID"] = programID;
    }
    const response = (await LeadsService.getUserProfile(payload)) as any;
    if (response.status) {
      if (response.data?.userProfile) {
        setUserProfile(response.data?.userProfile);
        setLeadsDetails(response.data?.userProfile);
      }
      if (response.data?.userProfile?.fisScore === -1 && timer < 10) {
        timer++;
        setTimeout(pollUserData, 20000);
      } else {
        setRetriggerClicked(false);
        removeRetriggerItem(userID);
      }
    }
  }

  async function reloadWaitState() {
    if (userProfile?.loanApplicationIDs?.[0]) {
      const response = await LoanApplicationService.getWaitState({
        token: fetchToken(),
        loanApplicationID: userProfile?.loanApplicationIDs[0],
        pageName,
      });

      if (response.status) {
        setWaitStateData(response.data);
      }
    }
  }

  const resolveWaitState = async (suggestedActionType: string) => {
    setWaitLoading(true);
    try {
      const headers = new Headers();
      if (pageName) {
        headers.append("page-header", pageName);
      } else {
        return noHeadersResponse();
      }
      const data = await Get(
        `/masterDashboard/${suggestedActionType}?loanApplicationID=${userProfile?.loanApplicationIDs?.[0]}`,
        fetchToken(),
        headers
      );
      if (data.status) {
        let status = await pollWaitState();
        if (status === "completed") {
          await reloadLeadDetails();
          await reloadWaitState();
        }
      } else if (data.error) {
        notify({
          title: "Error",
          text: data.error,
        });
      } else {
        notify({
          title: "Error",
          text: "Failed resolve issue. Please Contact FinBox Team",
        });
      }
    } catch (error) {
      notify({
        title: "Error",
        text: "Failed resolve issue. Please Contact FinBox Team",
      });
    }
    setWaitLoading(false);
  };

  const pollWaitState = async () => {
    let count = 0;
    const MAX_COUNT = 20;
    const callWaitState = async (
      resolve: (msg: "timeout" | "completed") => void,
      reject: () => void
    ) => {
      setTimeout(async () => {
        if (count >= MAX_COUNT) {
          return resolve("timeout");
        }
        if (waitStateData?.waitState) {
          await reloadWaitState();
          count = count + 1;
          callWaitState(resolve, reject);
        } else {
          return resolve("completed");
        }
      }, 3000);
    };
    return new Promise(callWaitState);
  };

  async function reloadLeadDetails() {
    const payload: any = {
      token: fetchToken(),
      userID,
      pageName,
    };
    if (programID !== "default") {
      payload["programID"] = programID;
    }
    const response = (await LeadsService.getUserProfile(payload)) as any;

    if (response.status) {
      if (response.data?.userProfile) {
        setUserProfile(response.data?.userProfile);
        setLeadsDetails(response.data?.userProfile);
      }
    }
  }

  async function getTimeline() {
    if (userActivity.length === 0) setIsLoading(true);
    else setIsMoreLoading(true);

    const payload = {
      userID,
      page,
      token: fetchToken(),
      pageName,
    };

    if (programID && programID !== "default") {
      payload["programID"] = programID;
    }
    const response = await LeadsService.getUserActivity(payload);
    const { status, data, error } = response;
    if (error) {
      notify({
        title: "Error",
        text: error,
      });
    }

    if (status && data) {
      if (userActivity.length > 0) {
        setUserActivity((prev) => {
          return [...prev, ...(data?.userActivityHistory ?? {})];
        });
      } else {
        setUserActivity(data?.userActivityHistory);
      }
      if (data?.userActivityHistory?.length === 0) {
        setShowMoreAvailable(false);
      }
    }
    if (userActivity.length === 0) setIsLoading(false);
    else setIsMoreLoading(false);
  }

  const disqualificationHandler = async () => {
    const response = await LeadsService?.disqualifyRevert({
      token: fetchToken(),
      payload: {
        userID: userID || "",
        action: "REVERT",
      },
      pageName,
    });
    if (response?.status) {
      notify({
        type: "success",
        title: "Success",
        text: "Revert Disqualification is done.",
      });
      reloadLeadDetails();
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  };

  async function generateLink() {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const params = new URLSearchParams();
    if (userID) {
      params.append("userID", userID);
    }

    if (programID && programID !== "default") {
      if (programID === SOURCE_ENTITY_IDS.TDLBNPLID.programID) {
        params.append("program", SOURCE_ENTITY_IDS.TDLBNPLID.programName);
      }
    }
    const response = await Get<WebLinkResponse>(
      `/masterDashboard/generateWebLink?${params.toString()}`,
      fetchToken(),
      headers
    );

    if (response.status) {
      setIsGenerateLinkModalOpen(true);
      setWebLink(response.data?.url || "");
      if (response.data?.url) {
        trackUserAction("resume_journey", {
          userID,
          sourceEntityID,
          webLink: response.data.url,
        });
      }
    } else {
      notify({
        title: "Error",
        text: response?.error,
      });
    }
  }

  const revokeDeleteRequest = async () => {
    const response = await LoanApplicationService.revokeDeleteRequest({
      userID: userID as string,
      token: fetchToken(),
      pageName,
    });

    if (response.status) {
      reloadLeadDetails();
      trackUserAction("revoke_delete_request", {
        userID,
        sourceEntityID,
      });
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  };

  const getCibilPdfReport = async () => {
    const response = await LoanApplicationService.getCibilPdfReport({
      userID,
      token: fetchToken(),
      pageName,
    });
    if (response?.status) {
      window.open(response.data?.cibilReport, "_blank");
      notify({
        title: "Success",
        type: "success",
        text: "Successfully downloaded CIBIL Report",
      });
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  };

  const getCIBILReportsExcel = async () => {
    const response = (await LoanApplicationService.getCIBILReportsExcel({
      userID: userID,
      token: fetchToken(),
      pageName,
    })) as any;
    if (response?.status) {
      notify({
        title: "Success",
        type: "success",
        text: "Successfully downloaded Bureau Report",
      });
      window.open(response.data?.zipUrl, "_blank");
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
  };

  const breadcrumbs: Array<BreadCrumb> = [
    { name: "Leads", href: "/leads", current: false },
  ];

  if (leadsDetails?.customerID && !isMobileView) {
    breadcrumbs.push(
      {
        name: leadsDetails?.sourceEntityName,
        href: "#",
        current: true,
      },
      {
        name: leadsDetails?.customerID,
        href: "#",
        current: true,
      }
    );
  } else {
    breadcrumbs.push({
      name: leadsDetails?.name || leadsDetails?.customerID,
      href: `/leads/details/${sourceEntityID}/${programID}/${userID}`,
      current: true,
    });
  }

  const isAllowedToResumeJourney = leadsDetails?.allowSessionLink ?? false;

  let isDSAUser = checkIfDsaUser(userState?.userType ?? "");
  let isPlatformDSAUser = checkIfPlatformDsa(userState?.userType ?? "");
  let isAllowedToExpireUser = loanApplicationDetails
    ? !["LOAN_REJECTED", "DISBURSED"].includes(loanApplicationDetails.status)
    : true;

  const actionItems = [
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.RESUME_JOURNEY]}
        >
          <ActionButton
            title={"Resume Journey"}
            onClick={async () => {
              await generateLink();
            }}
            icon={
              <ArrowPathRoundedSquareIcon className="w-4 h-4 mr-2 text-inherit" />
            }
          />
        </PrivateComponent>
      ),
      isShow: isAllowedToResumeJourney,
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.INITIATE_NEW_APPLICATION]}
        >
          <ActionButton
            title={"Initiate New Application"}
            onClick={() => setShowInitiateNewApplicationModal(true)}
            icon={<PencilSquareIcon className="w-4 h-4 mr-2 text-inherit" />}
          />
        </PrivateComponent>
      ),
      isShow: true,
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.REASSIGN_LEADS]}
        >
          <ActionButton
            title="Reassign Lead"
            onClick={() => setShowReassignLeadModal(true)}
            icon={<SquaresPlusIcon className="w-4 h-4 mr-2 text-inherit" />}
          />
        </PrivateComponent>
      ),
      isShow: isDSAUser || isPlatformDSAUser,
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.REVOKE_DELETE_REQUEST]}
        >
          <ActionButton
            onClick={revokeDeleteRequest}
            title="Revoke Delete Request"
            icon={<BackwardIcon className="w-4 h-4 mr-2 text-inherit" />}
          />
        </PrivateComponent>
      ),
      isShow: true,
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.SKIP_BANK_CONNECT]}
        >
          <ActionButton
            title="Skip Bank Connect"
            onClick={() => setShowSkipBankConnectModal(true)}
            icon={<ForwardIcon className="w-4 h-4 mr-2 text-inherit" />}
          />
        </PrivateComponent>
      ),
      isShow: true,
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.EXPIRE_USER]}
        >
          <ActionButton
            title="Expire User"
            onClick={() => setShowExpireUserModal(true)}
            icon={<UserMinusIcon className="w-4 h-4 mr-2 text-inherit" />}
          />
        </PrivateComponent>
      ),
      isShow: isAllowedToExpireUser,
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.DOWNLOAD_CIBIL_PDF_REPORT]}
        >
          <ActionButton
            title="Download CIBIL"
            onClick={getCibilPdfReport}
            icon={<ArrowDownTrayIcon className="w-4 h-4 mr-2 text-inherit" />}
          />
        </PrivateComponent>
      ),
      isShow: true,
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.DOWNLOAD_CIBIL_EXCEL_REPORT]}
        >
          <ActionButton
            title="Download Bureau Report"
            onClick={getCIBILReportsExcel}
            icon={<ArrowDownTrayIcon className="w-4 h-4 mr-2 text-inherit" />}
          />
        </PrivateComponent>
      ),
      isShow: true,
    },
  ];

  const IIFLActions = [
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.REVERT_DISQUALIFICATION]}
        >
          <ActionButton
            title="Revert Disqualification"
            onClick={() => setRevertDisqualificationOpen(true)}
            icon={<ArrowUturnLeftIcon className="w-4 h-4 mr-2 text-inherit" />}
          />
        </PrivateComponent>
      ),
      isShow: leadsDetails?.status === "USER_DISQUALIFIED",
    },
    {
      value: (
        <PrivateComponent
          requiredPage={pageName}
          sourceEntityId={sourceEntityID}
          requiredFunctions={[ACTIONS_LIST.REVERT_DISQUALIFICATION]}
        >
          <ActionButton
            title="Give Manual offer"
            onClick={() => setShowEditLoanOffer(true)}
            icon={<CurrencyRupeeIcon className="w-4 h-4 mr-2 text-inherit" />}
          />
        </PrivateComponent>
      ),
      isShow: leadsDetails?.status === "USER_DISQUALIFIED",
    },
  ];

  const sidebarItems = leadDetailSidebarFields.map((field) => {
    return {
      name: leadDetailsFields?.[field]?.label ?? "",
      value: leadsDetails?.[field] ?? "-",
      key: field,
    };
  });

  const tabs = getTabsByProductType(
    "leads",
    userProfile?.productType ?? "personal_loan",
    permissions,
    pageConfig?.[pageName]?.tabs
  );

  return (
    <>
      <div className="min-h-full">
        <PageHeader
          title=""
          breadcrumbs={breadcrumbs}
        />
        {(waitStateData?.waitState || waitStateData?.failedState) && (
          <div className="mt-4">
            <div
              className={classNames(
                "rounded-lg w-full p-4 flex flex-wrap md:flex-nowrap items-center justify-between border border-dashed",
                waitStateData?.waitState
                  ? "bg-[#fff3df] border-[#cccccc]"
                  : "bg-[#ffe2e2] border-[#bd8787"
              )}
            >
              <div className="w-full mb-1 md:mb-0 md:w-1/2">
                {waitStateData?.waitState && (
                  <>
                    <span
                      className={classNames(
                        "font-semibold text-sm text-[#df9c00]"
                      )}
                    >
                      Application in wait state
                    </span>
                    <br />
                  </>
                )}
                {waitStateData?.reason && (
                  <span
                    className={classNames(
                      "text-xs font-medium",
                      waitStateData?.waitState
                        ? "text-[#a39075]"
                        : "text-[#a75d5d]"
                    )}
                  >
                    {waitStateData?.reason}
                  </span>
                )}
              </div>
              <div className="w-full md:w-1/2 flex items-center justify-end">
                {waitStateData?.suggestedAction && (
                  <div
                    className={classNames(
                      "text-xs",
                      waitStateData?.waitState
                        ? "text-[#a39075]"
                        : "text-[#a75d5d]"
                    )}
                  >
                    {waitLoading
                      ? "Pushing data to lender"
                      : waitStateData?.suggestedAction}
                    .
                    {waitStateData?.suggestedActionType && (
                      <button
                        className="block underline leading-6"
                        onClick={() =>
                          resolveWaitState(waitStateData?.suggestedActionType)
                        }
                      >
                        {waitLoading ? "Loading..." : "Click here to retrigger"}
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <main className="py-0 md:pt-6 md:pb-3">
          <div className="mx-auto max-w-3xl lg:max-w-full flex items-start justify-between md:space-x-2">
            {/* Sidebar */}
            <DetailsSidebar
              items={sidebarItems}
              actions={actionItems}
              details={leadsDetails}
              IIFLActions={IIFLActions}
              config={USER_STAGE_FILTERS[leadsDetails?.status?.toLowerCase()]}
              status={
                USER_STAGE_FILTERS?.[leadsDetails?.status?.toLowerCase()]
                  ?.label ?? ""
              }
            />

            <div className="w-full md:w-[80%]">
              {/* Description list*/}
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white md:rounded-lg md:border md:border-gray-200">
                  <div className="rounded-bl-none rounded-br-none px-2 md:px-0 hidden md:block">
                    <TabsOne
                      tabs={tabs}
                      baseURL={`/leads/details/${sourceEntityID}/${programID}/${userID}`}
                      styles={{
                        tab: "min-w-max px-8 flex-1",
                      }}
                    />
                  </div>
                  <div className="border-gray-200 px-2 md:px-4 py-5 lg:4 h-600 overflow-auto">
                    <Outlet
                      context={{
                        page,
                        setPage,
                        leadsDetails,
                        userActivity,
                        retriggerClicked,
                        reloadLeadDetails,
                        retriggerDeviceAPI,
                        hasMorePages: showMoreAvailable,
                      }}
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </main>
      </div>
      <DialogOne
        title="Resume Journey"
        open={isGenerateLinkModalOpen}
        setOpen={setIsGenerateLinkModalOpen}
        description="Use this link to resume new loan journey"
      >
        <GenerateLinkModal
          webLink={webLink}
          setOpen={setIsGenerateLinkModalOpen}
        />
      </DialogOne>

      <DialogOne
        description=""
        title="Skip Bank Connect?"
        open={showSkipBankConnectModal}
        setOpen={setShowSkipBankConnectModal}
      >
        <SkipBankConnectModal
          userID={userID as string}
          reload={() => reloadLeadDetails()}
          setOpen={setShowSkipBankConnectModal}
        />
      </DialogOne>

      <DialogOne
        description=""
        open={showInitiateNewApplicationModal}
        setOpen={setShowInitiateNewApplicationModal}
        title="Are you sure to you want to initiate a new application?"
      >
        <InitiateNewApplicationModal
          userID={userID as string}
          reload={() => reloadLeadDetails()}
          setOpen={setShowInitiateNewApplicationModal}
        />
      </DialogOne>

      <DialogOne
        title="Reassign Lead"
        open={showReassignLeadModal}
        setOpen={setShowReassignLeadModal}
        description="Please select the agent to whom all Leads and DSAs will be reassigned"
      >
        <DeactivateAgentModal
          setOpen={setShowReassignLeadModal}
          selectedPartner={{
            product: userProfile?.productType as ProductType,
          }}
          pageName={pageName}
          modalType="reassign"
          reload={() => reloadLeadDetails()}
        />
      </DialogOne>

      <ConfirmationDialog
        open={revertDisqualificationOpen}
        onConfirm={disqualificationHandler}
        setOpen={setRevertDisqualificationOpen}
        confirmButtonText={"Revert Disqualification"}
        description={`Are you sure you want to revert disqualification and move the user to the beginning of the current stage? This action is not reversible.`}
      />

      <ConfirmationDialog
        open={manualOfferOpen}
        setOpen={setManualOfferOpen}
        confirmButtonText={"Give Manual offer"}
        onConfirm={() => manualOfferSubmit(manualOfferPayload)}
        description={`Are you sure you want to revert disqualification and move the user with a manual offer? This action is not reversible.`}
      />

      <DialogOne
        open={showEditLoanOffer}
        title="Manual Loan offer"
        setOpen={setShowEditLoanOffer}
      >
        <ManualOfferModal
          isOpen={setShowEditLoanOffer}
          confirmPopUp={(data) => {
            setManualOfferPayload(data);
            setManualOfferOpen(true);
          }}
        />
      </DialogOne>
      <DialogOne
        title={
          <div className="flex flex-row items-center">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationTriangleIcon
                aria-hidden="true"
                className="h-6 w-6 text-yellow-600"
              />
            </div>
            <h1 className="ml-2">Expire User</h1>
          </div>
        }
        open={showExpireUserModal}
        setOpen={setShowExpireUserModal}
      >
        <ExpireUserModal
          userID={userID}
          pageName={pageName!}
          setOpen={setShowExpireUserModal}
          reload={() => reloadLeadDetails()}
        />
      </DialogOne>
    </>
  );
}

export default withUserContext(LeadDetails);
