import { format } from "date-fns";
import {
  LoansListParams,
  LoansListResponse,
} from "../../pages/LoanApplications/interfaces";

import { DocInfo, ServiceMethodArgs } from "./interfaces";
import { Get, Post } from "../../shared/services/Fetch";
import { noHeadersResponse } from "../../shared/utils/Global";

export class LoanApplicationService {
  constructor() {}

  static async getLoanList({
    token,
    limit = 10,
    page = 1,
    statusText,
    searchType,
    searchQuery,
    filters = {},
    productType = "",
    sourceEntityIDs = [],
    dateFilter,
    to = null,
    from = null,
    pageName,
    dsaIDs = [],
    taskStatus,
  }: ServiceMethodArgs<LoansListParams>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    let params = new URLSearchParams({ ...(filters as object) });
    params.append("limit", String(limit));
    params.append("page", String(page));
    let url = "getLoanList";

    if (statusText) {
      statusText
        .filter((s) => s !== "TOTAL" && s !== "total")
        .map((s) => {
          params.append("statusText", s.toUpperCase());
        });
    }

    if (to && from) {
      params.append("to", format(to, "yyyy-MM-dd"));
      params.append("from", format(from, "yyyy-MM-dd"));
    }

    if (searchType && searchQuery) {
      params.append(searchType, searchQuery);
      url = "searchOnlyGetLoanList";
    }
    if (productType) {
      params.append("loanType", productType);
    }
    if (sourceEntityIDs?.length > 0) {
      sourceEntityIDs?.map((entityID) =>
        params.append("sourceEntityIDs", entityID)
      );
    }
    if (dsaIDs?.length > 0) {
      dsaIDs?.map((dsaID) => params.append("dsaID", dsaID));
    }
    if (taskStatus) {
      params.append("workflowFilter", taskStatus);
    }
    params.append("filter", dateFilter);
    return await Get<LoansListResponse>(
      `/masterDashboard/${url}?${params.toString()}`,
      token || "",
      headers
    );
  }

  static async getFilterCounts({
    page = 1,
    searchType = "",
    searchQuery = "",
    filters = {},
    statusText = [],
    token = "",
    limit = 10,
    dateFilter,
    productType = "",
    sourceEntityIDs = [],
    to = null,
    from = null,
    pageName,
    dsaIDs = [],
    taskStatus,
  }: ServiceMethodArgs<LoansListParams>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    let params = new URLSearchParams({ ...(filters as object) });
    params.append("limit", String(limit));
    params.append("page", String(page));
    if (statusText) {
      statusText
        .filter((s) => s !== "TOTAL" && s !== "total")
        .map((s) => {
          params.append("statusText", s);
        });
    }
    if (searchType && searchQuery) {
      params.append(searchType, searchQuery);
    }
    if (to && from) {
      params.append("to", format(to, "yyyy-MM-dd"));
      params.append("from", format(from, "yyyy-MM-dd"));
    }
    if (productType) {
      params.append("loanType", productType);
    }
    if (sourceEntityIDs?.length > 0) {
      sourceEntityIDs?.map((entityID) =>
        params.append("sourceEntityIDs", entityID)
      );
    }
    if (dsaIDs?.length > 0) {
      dsaIDs?.map((dsaID) => params.append("dsaID", dsaID));
    }

    if (taskStatus) {
      params.append("workflowFilter", taskStatus);
    }

    params.append("filter", dateFilter);

    let URL = `/masterDashboard/loanStatusAggregateCount?${params.toString()}`;
    return await Get<any>(URL, token, headers);
  }

  static async getLoanApplicationList(params: LoansListParams) {
    return await LoanApplicationService.getLoanList(params);
  }

  static async addNote({
    note,
    token,
    pageName,
  }: ServiceMethodArgs<{ note: any }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const response = await Post(
      `/masterDashboard/addNote`,
      token,
      {
        note,
        loanApplicationID: note.loanApplicationID,
      },
      headers
    );

    return response;
  }

  static async getNotes({
    loanApplicationID,
    token,
    pageName,
  }: ServiceMethodArgs<{ loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/notes?loanApplicationID=${loanApplicationID}`,
      token,
      headers
    );
  }

  static async getAdditionalDocsOpt({
    token,
    pageName,
    serviceType,
    loanApplicationID,
  }: ServiceMethodArgs<{ loanApplicationID: string; serviceType: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/dropdownL2?serviceType=${serviceType}&loanApplicationID=${loanApplicationID}`,
      token,
      headers
    );
  }

  static async pushAdditionalDocs({
    token,
    pageName,
    payload,
  }: ServiceMethodArgs<{
    payload: { loanApplicationID: string; docsInfo: DocInfo };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    return await Post(
      `/masterDashboard/pushAdditionalDocs`,
      token,
      payload,
      headers
    );
  }

  static async getCibilPdfReport({
    userID,
    token,
    pageName,
  }: ServiceMethodArgs<{ userID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/getCibilPDFReport?userID=${userID}`,
      token,
      headers
    );
  }

  static async getCIBILReportsExcel({
    userID,
    token,
    pageName,
  }: ServiceMethodArgs<{ userID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/cibilReportsExcel?userID=${userID}`,
      token,
      headers
    );
  }

  static async getLoanOffer({
    loanApplicationID,
    token,
    pageName,
  }: ServiceMethodArgs<{ loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    return await Get(
      `/masterDashboard/getLoanOffer?loanApplicationID=${loanApplicationID}`,
      token,
      headers
    );
  }

  static async getOfferNegotiationEMI({
    token,
    payload,
    pageName,
  }: ServiceMethodArgs<{
    payload: {
      amount: string;
      tenure: string;
      interest: string;
      loanApplicationID: string;
    };
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    return await Post(
      `/masterDashboard/getOfferNegotiationEMI`,
      token,
      payload,
      headers
    );
  }

  static async getOfferNegotiationWFStatus({
    loanApplicationID,
    token,
    pageName,
  }: ServiceMethodArgs<{ loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    return await Get(
      `/masterDashboard/getOfferNegotiationWFStatus?loanApplicationID=${loanApplicationID}`,
      token,
      headers
    );
  }

  static async getLoanTermDeviation({
    token,
    pageName,
    loanApplicationID,
  }: ServiceMethodArgs<{ loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/getOfferNegotiation?loanApplicationID=${loanApplicationID}`,
      token,
      headers
    );
  }

  static async saveLoanTermDeviation({
    token,
    payload,
    pageName,
    loanApplicationId,
  }: ServiceMethodArgs<{ payload: any; loanApplicationId: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const urlSearchParams = new URLSearchParams();

    if (loanApplicationId) {
      urlSearchParams.append("loanApplicationId", loanApplicationId);
    }

    const response = await Post(
      `/masterDashboard/addOfferNegotiation`,
      token,
      payload,
      headers
    );
    return response;
  }

  static async getLoanDetails({
    token,
    pageName,
    loanApplicationID,
  }: ServiceMethodArgs<{ loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/getLoanDetails?loanApplicationID=${loanApplicationID}`,
      token,
      headers
    );
  }

  static async getSalesDisposition({
    token,
    userId,
    pageName,
    sourceEntityId,
    loanApplicationID,
  }: ServiceMethodArgs<{
    userId: string;
    sourceEntityId: string;
    loanApplicationID: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    return await Get(
      `/masterDashboard/getSalesDisposition?sourceEntityId=${sourceEntityId}${
        userId ? `&userId=${userId}` : ""
      }&loanApplicationId=${loanApplicationID}`,
      token,
      headers
    );
  }

  static async getSalesDispositionStatus({
    token,
    pageName,
    sourceEntityId,
  }: ServiceMethodArgs<{ sourceEntityId: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    return await Get(
      `/masterDashboard/getSalesDispositionConfig?sourceEntityId=${sourceEntityId}`,
      token,
      headers
    );
  }

  static async saveSalesDisposition({
    data,
    token,
    pageName,
    loanApplicationId,
  }: ServiceMethodArgs<{ data: any; loanApplicationId: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const urlSearchParams = new URLSearchParams();

    if (loanApplicationId) {
      urlSearchParams.append("loanApplicationId", loanApplicationId);
    }

    const response = await Post(
      `/masterDashboard/addSalesDisposition?${urlSearchParams.toString()}`,
      token,
      data,
      headers
    );
    return response;
  }

  static async uploadDocs({
    token,
    formData,
    pageName,
  }: ServiceMethodArgs<{ formData: any }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    return await Post(`/masterDashboard/uploadDocs`, token, formData, headers);
  }

  static async getKYCDetails({
    loanApplicationID,
    token,
    pageName,
  }: ServiceMethodArgs<{ loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/getKYCDetails?loanApplicationID=${loanApplicationID}`,
      token,
      headers
    );
  }

  static async getLoanKYC({
    loanApplicationID,
    token,
    pageName,
  }: ServiceMethodArgs<{ loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/getLoanKYC?loanApplicationID=${loanApplicationID}`,
      token,
      headers
    );
  }

  static async retriggerKYCEngine({
    loanApplicationID,
    token,
    pageName,
  }: ServiceMethodArgs<{ loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/retriggerKYCEngine?loanApplicationID=${loanApplicationID}`,
      token,
      headers
    );
  }

  static async getBankDetails({
    loanApplicationID,
    token,
    pageName,
  }: ServiceMethodArgs<{ loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/getBankDetails?loanApplicationID=${loanApplicationID}`,
      token,
      headers
    );
  }

  static async changeAccountName({
    data,
    token,
    pageName,
  }: ServiceMethodArgs<{ data: any }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/changeAccountName`,
      token,
      data,
      headers,
      true
    );
  }

  static async updateKYCDoc({
    formData,
    token,
    pageName,
  }: ServiceMethodArgs<{ formData: FormData }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/updateKYCDoc`,
      token,
      formData,
      headers
    );
  }

  static async getLoanActivity({
    loanApplicationID,
    token,
    page = 1,
    pageName,
  }: ServiceMethodArgs<{ loanApplicationID: string; page: number }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/loanActivity?loanApplicationID=${loanApplicationID}&page=${page}&limit=8`,
      token,
      headers
    );
  }

  static async approveLoanKYC({
    loanApplicationID,
    token,
    isDoubleName,
    pageName,
  }: ServiceMethodArgs<{ isDoubleName: boolean; loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      "/masterDashboard/approveLoanKyc",
      token,
      {
        loanApplicationID,
        isDoubleName,
      },
      headers
    );
  }

  static async rejectLoan({
    loanApplicationID,
    reason,
    token,
    pageName,
  }: ServiceMethodArgs<{ reason: string; loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rejectLoan`,
      token,
      {
        loanApplicationID,
        rejectReason: reason,
      },
      headers
    );
  }

  static async revokeDeleteRequest({
    token,
    userID,
    pageName,
  }: ServiceMethodArgs<{ userID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      "/masterDashboard/revokeDelete",
      token,
      {
        userID,
      },
      headers
    );
  }

  static async cancelApplication({
    reason,
    loanApplicationID,
    token,
    pageName,
  }: ServiceMethodArgs<{
    reason: string;
    loanApplicationID: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      "/masterDashboard/cancelLoan",
      token,
      {
        loanApplicationID,
        cancellationReason: reason,
      },
      headers
    );
  }

  static async getWaitState({
    token,
    loanApplicationID,
    pageName,
  }: ServiceMethodArgs<{ loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/getWaitState?loanApplicationID=${loanApplicationID}`,
      token,
      headers
    );
  }

  static async updateName<T>({
    name,
    userID,
    token,
    pageName,
  }: ServiceMethodArgs<{ name: string; userID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post<T>(
      "/masterDashboard/updateName",
      token,
      {
        name: name,
        userID: userID,
      },
      headers
    );
  }

  static async updateFathersName<T>({
    fathersName,
    loanApplicationID,
    token,
    pageName,
  }: ServiceMethodArgs<{ fathersName: string; loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post<T>(
      "/masterDashboard/updateFathersName",
      token,
      {
        fathersName,
        loanApplicationID,
      },
      headers
    );
  }

  static async updateGender<T>({
    gender,
    loanApplicationID,
    token,
    pageName,
  }: ServiceMethodArgs<{ gender: string; loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post<T>(
      "/masterDashboard/updateGender",
      token,
      {
        gender,
        loanApplicationID,
      },
      headers
    );
  }

  static async updateDob<T>({
    dob,
    token,
    userID,
    pageName,
  }: ServiceMethodArgs<{ dob: Date; userID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post<T>(
      "/masterDashboard/updateDOB",
      token,
      {
        dob: format(dob, "yyyy-MM-dd"),
        userID,
      },
      headers
    );
  }

  static async updateCurrentAddress<T>({
    token,
    line1,
    line2,
    city,
    state,
    pincode,
    loanApplicationID,
    IsCurrentAndPermanentSame,
    pageName,
  }: ServiceMethodArgs<{
    line1: string;
    line2: string;
    city: string;
    state: string;
    pincode: string;
    loanApplicationID: string;
    IsCurrentAndPermanentSame: boolean;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post<T>(
      "/masterDashboard/updateCurrentAddress",
      token,
      {
        line1,
        line2,
        city,
        state,
        pincode,
        loanApplicationID,
        IsCurrentAndPermanentSame,
      },
      headers
    );
  }

  static async updatePermanentAddress<T>({
    token,
    line1,
    line2,
    city,
    state,
    pincode,
    loanApplicationID,
    pageName,
  }: ServiceMethodArgs<{
    line1: string;
    line2: string;
    city: string;
    state: string;
    pincode: string;
    loanApplicationID: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post<T>(
      "/masterDashboard/updatePermanentAddress",
      token,
      {
        line1,
        line2,
        city,
        state,
        pincode,
        loanApplicationID,
      },
      headers
    );
  }

  static async updateBusinessAddress<T>({
    line1,
    line2,
    city,
    state,
    pincode,
    userID,
    token,
    businessAddressType,
    pageName,
  }: ServiceMethodArgs<{
    line1: string;
    line2: string;
    city: string;
    state: string;
    pincode: string;
    userID: string;
    businessAddressType: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post<T>(
      "/masterDashboard/updateBusinessAddress",
      token,
      {
        line1,
        line2,
        city,
        state,
        pincode,
        userID,
        businessAddressType,
      },
      headers
    );
  }

  static async updateBusinessDetails<T>({
    token,
    firmName,
    constitution,
    last12MonthsSale,
    dateOfIncorporation,
    userID,
    businessPAN,
    pageName,
  }: ServiceMethodArgs<{
    userID: string;
    firmName: string;
    constitution: string;
    last12MonthsSale: string;
    dateOfIncorporation: Date;
    businessPAN: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const formData: any = {
      firmName,
      constitution,
      last12MonthsSale,
      dateOfIncorporation: format(dateOfIncorporation, "yyyy-MM-dd"),
      userID,
    };

    if (constitution !== "Proprietorship") {
      formData.businessPAN = businessPAN;
    }
    return await Post<T>(
      "/masterDashboard/updateBusinessDetails",
      token,
      formData,
      headers
    );
  }

  static async applyNewLoan<T>({
    token,
    userID,
    pageName,
  }: ServiceMethodArgs<{
    userID: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post<T>(
      "/masterDashboard/applyNewLoan",
      token,
      {
        userID,
      },
      headers
    );
  }

  static async getBusinessDetails<T>({
    token,
    userID,
    pageName,
  }: ServiceMethodArgs<{
    userID: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get<T>(
      `/masterDashboard/getBusinessDetails?userID=${userID}`,
      token,
      headers
    );
  }

  static async getGstinDoc<T>({
    token,
    userID,
    gstin,
    pageName,
  }: ServiceMethodArgs<{
    userID: string;
    gstin: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get<T>(
      `/masterDashboard/gstinDoc?userID=${userID}&gstin=${gstin}&regenerate=false`,
      token,
      headers
    );
  }

  static async rejectSingleDoc<T>({
    token,
    loanApplicationID,
    kycDetailsID,
    rejectionReason,
    pageName,
  }: ServiceMethodArgs<{
    loanApplicationID: string;
    kycDetailsID: string;
    rejectionReason: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/rejectSingleDoc`,
      token,
      {
        loanApplicationID,
        loanKycDetailsID: kycDetailsID,
        rejectionReason,
      },
      headers
    );
  }

  static async getBcRange({
    sourceEntityID,
    token,
    pageName,
  }: ServiceMethodArgs<{ sourceEntityID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/bcRange?sourceEntityID=${sourceEntityID}`,
      token,
      headers
    );
  }

  static async getBcStatus({
    userID,
    token,
    pageName,
  }: ServiceMethodArgs<{ userID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/bcStatus?userID=${userID}`,
      token,
      headers
    );
  }

  static async bankConnectCompleted({
    userID,
    entityID,
    token,
    pageName,
  }: ServiceMethodArgs<{
    userID: string;
    entityID: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/bankConnectCompleted`,
      token,
      {
        userID,
        entityID,
      },
      headers
    );
  }

  static async sendAgreementToLender({
    token,
    loanApplicationID,
    pageName,
  }: ServiceMethodArgs<{ loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      "/masterDashboard/sendSignAgreementDoc",
      token,
      {
        loanApplicationID,
      },
      headers
    );
  }

  static async downloadBankConnectReport({
    userID,
    sourceEntityID,
    token,
    pageName,
  }: ServiceMethodArgs<{ userID: string; sourceEntityID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/getBankConnectReport?userID=${userID}&sourceEntityID=${sourceEntityID}`,
      token,
      headers
    );
  }

  static async getWorkflows({
    loanApplicationID,
    token,
    pageName,
  }: ServiceMethodArgs<{ loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    }
    const requestParams = new URLSearchParams();
    requestParams.append("resourceID", loanApplicationID);
    requestParams.append("resourceType", "loan_application");
    return await Get(
      `/masterDashboard/getWorkflows?${requestParams.toString()}`,
      token,
      headers
    );
  }

  static async approveBusinessKYC({
    token,
    pageName,
    loanApplicationID,
  }: ServiceMethodArgs<{ loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    const payload = {
      loanApplicationID,
    };

    const response = await Post(
      `/masterDashboard/approveBusinessDocs`,
      token,
      payload,
      headers
    );
    return response;
  }
}
