import { format, parseISO } from "date-fns";
import parse from "date-fns/parse";

export const camelToHuman = (str: string): string => {
  const start =
    str.charAt(0).toUpperCase() == str.charAt(0)
      ? ""
      : str.charAt(0).toUpperCase();
  return (
    start +
    str
      .replace(/([A-Z]+)/g, " $1")
      .replace(/([A-Z][a-z])/g, " $1")
      .slice(1)
  );
};

export const formatNumber = (amount: number | string): string => {
  if (!amount && amount !== 0) return "";
  let x: string | number = amount.toString();
  let afterPoint = "";
  if (x.indexOf(".") > 0) afterPoint = x.substring(x.indexOf("."), x.length);
  x = Math.floor(parseFloat(x));
  x = x.toString();
  let lastThree = x.substring(x.length - 3);
  let otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers != "") lastThree = "," + lastThree;
  let res =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
  return res;
};

export const downloadLink = (link: string) => {
  let a: any = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = link;
  a.download = true;
  a.click();
  document.body.removeChild(a);
};

export const debounce = <T extends (...args: any) => any>(
  func: T,
  delay: number
) => {
  let debounceTimer: NodeJS.Timeout;
  return (...args: any) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func(...args), delay);
  };
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set#implementing_basic_set_operations
export function isSuperset<T>(set: Set<T>, subset: Set<T>): boolean {
  for (let elem of subset) {
    if (!set.has(elem)) {
      return false;
    }
  }
  return true;
}

export type Values<T extends Record<any, any>> = T[keyof T];

export function objectKeys<T extends {}>(obj: T) {
  return Object.keys(obj ?? {}) as Array<keyof T>;
}

export async function copyToClipboard(value: any): Promise<boolean> {
  try {
    await navigator.clipboard.writeText(value);
    return true;
  } catch (error) {
    console.log(error);
    if (value) {
      const selectionInput = document.createElement("textarea");
      selectionInput.value = value;
      document.body.appendChild(selectionInput);
      selectionInput?.focus();
      selectionInput?.select();
      document.execCommand("copy");
      document.body.removeChild(selectionInput);
      return true;
    } else {
      return false;
    }
  }
}

export function formatNumberIntl(
  number: number,
  locale?: string,
  options?: Intl.NumberFormatOptions
): string {
  let result = "";
  let intlOptions = {
    ...(options
      ? options
      : {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 0,
        }),
  };

  try {
    result = new Intl.NumberFormat(locale || "en-IN", intlOptions).format(
      number
    );
  } catch (ex) {
    console.log(ex);
    result = "";
  }

  return result;
}

export const StringToDate = (dateString: string | null) => {
  if (!dateString) {
    return null;
  }
  return parse(dateString, "yyyy-MM-dd", new Date());
};

export function getFormattedDate(
  dateString: string,
  withTime: boolean = false
): string {
  if (!dateString) {
    return "";
  }
  try {
    let date = parseISO(dateString);
    return !withTime
      ? format(date, "do MMM, yyyy")
      : format(date, "do MMM, yyyy, h:mm aa");
  } catch (ex) {
    console.log(new Error(ex).message);
    return "";
  }
}
