import { Get, Post } from "../../shared/services/Fetch";
import {
  ACCESS_OBJECT,
  ACCESS_PERM,
  GET_SE_LIST_OBJECTS,
} from "../../shared/utils/constants";
import { noHeadersResponse } from "../../shared/utils/Global";
import { ServiceMethodArgs } from "../LoanApplication/interfaces";
import { UserDSA } from "../Partners/interfaces";
import { Agent, SourceEntity, Values } from "./interfaces";

export class CommonService {
  constructor() {}

  static async generateLink({
    userID,
    token,
    withdrawalAmount,
    transactionID,
    invoiceIDs,
    merchantIfscCode,
    merchantName,
    merchantBankAccountNumber,
    merchantGstNumber,
    pageName,
    program,
    merchantEmail,
    merchantMobile,
    invoiceDates,
  }: ServiceMethodArgs<{
    userID: string;
    withdrawalAmount?: Number;
    program?: string;
    transactionID?: string;
    merchantName?: string;
    merchantGstNumber?: string;
    merchantIfscCode?: string;
    merchantBankAccountNumber?: string;
    invoiceIDs?: string;
    merchantEmail?: string;
    merchantMobile?: string;
    invoiceDates?: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const params = new URLSearchParams();
    if (userID) {
      params.append("userID", userID);
    }
    if (withdrawalAmount) {
      params.append("withdrawAmount", String(withdrawalAmount));
    }
    if (transactionID) {
      params.append("transactionID", transactionID);
    }
    if (program) {
      params.append("program", program);
    }
    if (merchantBankAccountNumber) {
      params.append("merchantBankAccountNumber", merchantBankAccountNumber);
    }
    if (merchantGstNumber) {
      params.append("merchantGstNumber", merchantGstNumber);
    }
    if (merchantIfscCode) {
      params.append("merchantIfscCode", merchantIfscCode);
    }
    if (merchantName) {
      params.append("merchantName", merchantName);
    }
    if (invoiceIDs) {
      params.append("invoiceIDs", invoiceIDs);
    }
    if (invoiceDates) {
      params.append("invoiceDates", invoiceDates);
    }
    if (merchantEmail) {
      params.append("merchantEmail", merchantEmail);
    }
    if (merchantMobile) {
      params.append("merchantMobile", merchantMobile);
    }

    return await Get(
      `/masterDashboard/generateWebLink?${params.toString()}`,
      token,
      headers
    );
  }

  static async uploadDashboardMedia({
    token,
    formData,
    pageName,
  }: ServiceMethodArgs<{ formData: any }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    return await Post(
      `/masterDashboard/uploadDashboardMedia`,
      token,
      formData,
      headers,
      true
    );
  }

  static async getSourceEntityList({ token, pageName }: ServiceMethodArgs<{}>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    let URL = "/masterDashboard/getSourceEntityList";

    return await Get<{ sourceList: Array<SourceEntity> }>(URL, token, headers);
  }

  static async getAgentList({
    token,
    searchQuery = "",
    productType = "",
    pageName,
  }: ServiceMethodArgs<{
    token: string;
    searchQuery: string;
    productType?: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }
    let URL = "/masterDashboard/agentList";
    let searchParams = new URLSearchParams();
    if (searchQuery) {
      searchParams.append("searchQuery", searchQuery);
    }
    if (productType) {
      searchParams.append("productType", productType);
    }
    if (Array.from(searchParams.entries()).length > 0) {
      URL += `?${searchParams.toString()}`;
    }

    return await Get<{ agentList: Array<Agent> }>(URL, token, headers);
  }

  static async getUserDSAList({ token, pageName }: ServiceMethodArgs<{}>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get<{ agentList: Array<UserDSA> }>(
      `/masterDashboard/userDSAList`,
      token,
      headers
    );
  }

  static async getIIFLListPartners({
    searchQuery = "",
    token,
    action,
    object,
    productType = "",
    pageName,
  }: ServiceMethodArgs<{
    action: Values<typeof ACCESS_PERM>;
    object: Values<typeof ACCESS_OBJECT>;
    searchQuery: string;
    productType?: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    let params = new URLSearchParams();
    if (searchQuery) params.append("searchQuery", searchQuery);
    if (action) params.append("action", action);
    if (object) params.append("object", GET_SE_LIST_OBJECTS[object]);
    if (productType) params.append("productType", productType);

    return await Get(
      `/masterDashboard/listIIFLPartners?${params.toString()}`,
      token,
      headers
    );
  }
}
