import { WorkflowService } from "../../services/Workflow";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

export default async function ({
    params,
    getToken,
    pageName,
  }: CustomLoaderFunctionArgs) {
    const { loanID } = params;
    if (loanID) {
      const token = getToken();
      const workflowResponse = await WorkflowService.getWorkflows({
        token,
        resourceID: loanID,
        resourceType: "loan_application",
        pageName: pageName
      })
      
      return { workflowResponse };
    }
  }