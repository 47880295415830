import classNames from "classnames";
import { MdOutlineWarning } from "react-icons/md";

interface IProps {
  text: string;
}

export default function ErrorTextBox({ text }: IProps) {
  return (
    <div className={classNames("mt-1 text-xs text-red-600")}>
      <ul className="">
        {text && (
          <li className="flex items-start justify-start">
            <MdOutlineWarning className="mr-1 mt-0.5 w-3 h-3"></MdOutlineWarning>{" "}
            {text}
          </li>
        )}
      </ul>
    </div>
  );
}
