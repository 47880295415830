import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

interface ScrollButtonProps {
  direction: "left" | "right";
  shouldShow: boolean;
  scrollOffset: number;
  navbarRef: React.RefObject<HTMLDivElement>;
  setScrollPosition: (position: number) => void;
}

const ScrollButton: React.FC<ScrollButtonProps> = ({
  direction,
  shouldShow,
  scrollOffset,
  navbarRef,
  setScrollPosition,
}) => {
  if (!shouldShow) return null;

  const handleClick = () => {
    const scrollAmount = direction === "left" ? -scrollOffset : scrollOffset;
    navbarRef.current?.scrollTo({
      left: (navbarRef.current?.scrollLeft || 0) + scrollAmount,
      behavior: "smooth",
    });
    setScrollPosition(navbarRef.current?.scrollLeft || 0);
  };

  return (
    <button
      className={`hidden absolute ${
        direction === "left"
          ? "left-0 bg-gradient-to-r"
          : "right-0 bg-gradient-to-l"
      } py-3 px-2 z-[100] from-gray-200 to-transparent`}
      onClick={handleClick}
    >
      <ChevronLeftIcon
        className={classNames(
          "w-5 h-5",
          direction != "left" ? "rotate-180" : ""
        )}
      />
    </button>
  );
};

export default ScrollButton;
