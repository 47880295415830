import { defer } from "react-router";
import { AdminService } from "../../services/Admin";

import { Loader } from "../Admin/interface";
import { getAllGroups, getAllMaskingLevels } from "./dummy";

export default async function policyLoader({
  request,
  getToken,
  pageName,
}: Loader) {
  const token = getToken();
  const url = new URL(request.url);

  let allGroups: any = await AdminService.getAllGroups({
    token,
    pageName,
    switchedOrgID: url.searchParams.get("platformId") ?? null,
  });
  if (!allGroups.status) {
    allGroups = await getAllGroups();
  }

  let maskingLevels: any = await AdminService.getAllMaskingLevels({
    token,
    pageName,
  });
  if (!maskingLevels.status) {
    maskingLevels = await getAllMaskingLevels();
  }

  return defer({
    allGroups,
    maskingLevels,
  });
}
