import { format } from "date-fns";
import { FinBoxResponse } from "../../shared/services/APIResponse";
import { Get, Post } from "../../shared/services/Fetch";
import { noHeadersResponse } from "../../shared/utils/Global";
import { ServiceMethodArgs } from "../LoanApplication/interfaces";
import { GetTransactionsParams, TransactionsResponse } from "./interfaces";

export class CreditLineService {
  static async getTransactions({
    token,
    limit = 10,
    page = 1,
    statusText,
    searchType,
    searchQuery,
    filters = {},
    to = null,
    from = null,
    dateFilter = "updated_at",
    sourceEntityIDs = [],
    loanType,
    pageName,
  }: ServiceMethodArgs<GetTransactionsParams>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    let params = new URLSearchParams({ ...(filters as object) });
    params.append("limit", String(limit));
    params.append("page", String(page));
    if (statusText) {
      statusText
        .filter((s) => s !== "TOTAL" && s !== "total")
        .map((s) => {
          params.append("statusText", s.toUpperCase());
        });
    }
    if (to && from) {
      params.append("to", format(new Date(to), "yy-MM-dd"));
      params.append("from", format(new Date(from), "yy-MM-dd"));
    }
    if (searchType && searchQuery) {
      params.append(searchType, searchQuery);
    }
    if (dateFilter) {
      params.append("filter", dateFilter);
    }

    if (sourceEntityIDs?.length > 0) {
      sourceEntityIDs?.map((entityID) =>
        params.append("sourceEntityIDs", entityID)
      );
    }

    if (loanType) {
      params.append("loanType", loanType);
    }

    if (searchType && searchQuery) {
      return await Get<TransactionsResponse>(
        `/masterDashboard/searchOnlyTransactions?${params.toString()}`,
        token || "",
        headers
      );
    }

    return await Get<TransactionsResponse>(
      `/masterDashboard/transactions?${params.toString()}`,
      token || "",
      headers
    );
  }

  static async getCreditLineTransactions({
    loanApplicationID,
    token,
    pageName,
  }: ServiceMethodArgs<{ loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return Get(
      `/masterDashboard/getCreditLineTransactions?loanApplicationID=${loanApplicationID}`,
      token,
      headers
    );
  }

  static async getPreLimit({
    loanApplicationID,
    token,
    pageName,
  }: ServiceMethodArgs<{ loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return Get(
      `/masterDashboard/getPreLimit?loanApplicationID=${loanApplicationID}`,
      token,
      headers
    );
  }

  static async getCreditLineDetails({
    token,
    loanApplicationID,
    pageName,
  }: ServiceMethodArgs<{ loanApplicationID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(
      `/masterDashboard/getCreditLineDetails?loanApplicationID=${loanApplicationID}`,
      token,
      headers
    );
  }

  static async cancelToProcessing({
    txnID,
    token,
    pageName,
  }: ServiceMethodArgs<{ txnID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      "/masterDashboard/cancelToProcessing",
      token,
      {
        txnID,
      },
      headers
    );
  }

  static async resolveQuery({
    txnID,
    token,
    pageName,
  }: ServiceMethodArgs<{ txnID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      "/masterDashboard/resolveQuery",
      token,
      {
        txnID,
      },
      headers
    );
  }

  static async updateCreditLineTxnStatus({
    payload,
    token,
    pageName,
  }: ServiceMethodArgs<{ payload: any }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/updateCreditLineTxnStatus`,
      token,
      payload,
      headers
    );
  }

  static async updatedCreditLineTxnDetails({
    payload,
    token,
    pageName,
  }: ServiceMethodArgs<{ payload: any }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/updateCreditLineTxnDetails`,
      token,
      payload,
      headers
    );
  }

  static async splitTxn({
    invoices,
    token,
    txnID,
    pageName,
  }: ServiceMethodArgs<{ invoices: Array<any>; txnID: string }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Post(
      `/masterDashboard/splitTxn`,
      token,
      {
        txnID,
        invoices,
      },
      headers
    );
  }

  static async createDirectTransaction({
    token,
    transactionID,
    withdrawalAmount,
    merchantName,
    merchantBankAccountNumber,
    merchantIfscCode,
    merchantGstNumber,
    txnID,
    sourceEntityID,
    customerID,
    invoiceIDs,
  }: ServiceMethodArgs<{
    transactionID: string;
    withdrawalAmount: number;
    merchantName?: string;
    merchantBankAccountNumber?: string;
    merchantIfscCode?: string;
    merchantGstNumber?: string;
    txnID?: string;
    sourceEntityID: string;
    customerID: string;
    invoiceIDs: string;
  }>) {
    return await Post(`/masterDashboard/createDirectTransaction`, token, {
      txnID,
      transactionID,
      withdrawalAmount,
      merchantName,
      merchantBankAccountNumber,
      merchantIfscCode,
      merchantGstNumber,
      sourceEntityID,
      customerID,
      invoiceIDs,
    });
  }
}
