import { defer } from "react-router";

import { Loader } from "./interface";
import { AdminService } from "../../services/Admin";
import {
  ChildSourceEntitiesWithOwnPrograms,
  RoleHelperDefinitions,
} from "../../services/Admin/interface";
import { DropdownWithSearchOption } from "../../components/atomic/DropdownWithSearch/interface";

export default async function adminLoader({
  getToken,
  pageName,
  request,
}: Loader) {
  const token = getToken();
  const url = new URL(request.url);
  const platformId = url.searchParams.get("platformId");

  const [helperData] = await Promise.all([
    AdminService.getRoleHelperData({ token, pageName }),
  ]);

  let selectedSourceEntity:
    | DropdownWithSearchOption
    | { childSourceEntities: ChildSourceEntitiesWithOwnPrograms }
    | null = null;
  if (platformId) {
    const roleHelperDefinitions = helperData?.data?.roleHelperDefinitions;
    if (roleHelperDefinitions === undefined || roleHelperDefinitions === null) {
      console.warn("The roleHelperDefinitions property in HelperData is undefined or null", helperData);
    }
    const platform = roleHelperDefinitions?.find(
      (s) => s.organizationID == platformId
    );
    if (platform) {
      selectedSourceEntity = {
        name: platform.organizationName,
        value: platform.organizationID,
        childSourceEntities: platform.childSourceEntities,
      };
    }
  }

  return defer({
    roleHelperData: helperData || [],
    selectedSourceEntity,
  });
}
