import { Get } from "../../shared/services/Fetch";
import { noHeadersResponse } from "../../shared/utils/Global";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

export default async function userBankStatementsLoader({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const { userID } = params;
  const headers = new Headers();
  if (pageName) {
    headers.append("page-header", pageName);
  } else {
    return noHeadersResponse();
  }
  return await Get(
    `/masterDashboard/getBankStatements?userID=${userID}`,
    getToken(),
    headers
  );
}
