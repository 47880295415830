import React, { useState, useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

interface EditorInterface {
  initialValue?: string;
  onChange: (args: string) => void;
}

export default function Editor({ onChange, initialValue }: EditorInterface) {
  const [value, setValue] = useState("");
  const quillRef = useRef<ReactQuill>(null);

  useEffect(() => {
    setValue(initialValue || "");
  }, [initialValue]);

  useEffect(() => {
    const quillInstance = quillRef.current?.getEditor();
    // Call the getLength() method on the Quill instance
    const length = quillInstance?.getLength();

    onChange?.({ value, length });
  }, [value]);

  return (
    <>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={value}
        onChange={setValue}
      />
    </>
  );
}
