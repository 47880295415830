import { defer } from "react-router";
import { CreditLineService } from "../../services/CreditLine";
import {
  TransactionDateFilter,
  TransactionsProductTypes,
} from "../../services/CreditLine/interfaces";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";
import { PlatformInteface } from "./interfaces";

export default async function allTransactionsLoader({
  getToken,
  request,
  pageName,
}: CustomLoaderFunctionArgs) {
  const token = getToken();
  const url = new URL(request.url);

  const filterData = JSON.parse(
    window?.localStorage?.getItem("filters") || "{}"
  );
  const allTransactions = filterData?.allTransactions;

  const allTransactionsResponse = CreditLineService.getTransactions({
    dateFilter: url.searchParams.has("dateType")
      ? (url.searchParams.get("dateType") as TransactionDateFilter)
      : "updated_at",
    token,
    page: url.searchParams.has("page")
      ? Number(url.searchParams.get("page"))
      : 1,
    limit: 10,
    statusText: url.searchParams.getAll("statusText"),
    searchQuery: url.searchParams.get("searchText") ?? "",
    searchType: url.searchParams.get("searchType") ?? "",
    to: url.searchParams.has("to")
      ? new Date(url.searchParams.get("to") as string)
      : null,
    from: url.searchParams.has("from")
      ? new Date(url.searchParams.get("from") as string)
      : null,
    loanType:
      allTransactions?.productType?.value ||
      (url.searchParams.get("loanType") as TransactionsProductTypes),
    sourceEntityIDs: allTransactions?.platform?.map(
      (source: PlatformInteface) => source?.value
    ),
    pageName,
  });

  return defer({
    allTransactionsResponse,
    storedFilters: allTransactions,
  });
}
