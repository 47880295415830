import { APIResponse } from "../../shared/services/APIResponse";
import { ExportsHistoryResponseDTO } from "../../dtos/ExportsDTO";
import { Get, Post } from "../../shared/services/Fetch";
import { ServiceMethodArgs } from "../LoanApplication/interfaces";
import { format } from "date-fns";
import { noHeadersResponse } from "../../shared/utils/Global";

const DEFAULT_RESPONSE = {
  status: false,
  error: "",
  data: null,
};
export interface IReportsService {
  getExportHistory(
    page: number,
    limit: number
  ): Promise<APIResponse<ExportsHistoryResponseDTO>>;
}

export default class ReportsService {
  static async getExportHistory({
    page,
    limit,
    token,
    pageName,
  }: ServiceMethodArgs<{
    page: number;
    limit: number;
    token: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    try {
      const response = await Get(
        `/masterDashboard/getExportHistory?limit=${limit}&page=${page}`,
        token,
        headers
      );
      return response;
    } catch (err: any) {
      return {
        ...DEFAULT_RESPONSE,
        error: new Error(err).message,
      };
    }
  }

  static async downloadMISData({
    fromDate,
    toDate,
    productType,
    sourceEntityIDs = [],
    selectionMode,
    token,
    pageName,
  }: ServiceMethodArgs<{
    fromDate: string;
    toDate: string;
    productType: string;
    sourceEntityIDs: Array<string>;
    selectionMode: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    try {
      const searchParams = new URLSearchParams();
      if (fromDate) {
        searchParams.append("from", format(new Date(fromDate), "yyyy-MM-dd"));
      }
      if (toDate) {
        searchParams.append("to", format(new Date(toDate), "yyyy-MM-dd"));
      }
      if (productType) {
        searchParams.append("loanType", productType);
      }
      if (sourceEntityIDs) {
        sourceEntityIDs.forEach((sourceEntityID) =>
          searchParams.append("sourceEntityIDs", sourceEntityID)
        );
      }
      if (selectionMode) {
        searchParams.append("selectionMode", selectionMode);
      }

      return await Get(
        `/masterDashboard/downloadMIS?${searchParams.toString()}`,
        token,
        headers
      );
    } catch (err: any) {
      return {
        ...DEFAULT_RESPONSE,
        error: new Error(err).message,
      };
    }
  }

  static async downloadLoanApplicationMIS({
    fromDate,
    toDate,
    sourceEntityIDs = [],
    selectionMode,
    token,
    pageName,
  }: ServiceMethodArgs<{
    fromDate: Date;
    toDate: Date;
    sourceEntityIDs: Array<string>;
    selectionMode: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    try {
      const searchParams = new URLSearchParams();
      if (fromDate) {
        searchParams.append("from", format(new Date(fromDate), "yyyy-MM-dd"));
      }
      if (toDate) {
        searchParams.append("to", format(new Date(toDate), "yyyy-MM-dd"));
      }
      if (sourceEntityIDs) {
        sourceEntityIDs.forEach((sourceEntityID) =>
          searchParams.append("sourceEntityIDs", sourceEntityID)
        );
      }
      if (selectionMode) {
        searchParams.append("selectionMode", selectionMode);
      }

      return await Get(
        `/masterDashboard/downloadLoanApplicationMIS?${searchParams.toString()}`,
        token,
        headers
      );
    } catch (err: any) {
      return {
        ...DEFAULT_RESPONSE,
        error: new Error(err).message,
      };
    }
  }

  static async downloadRejectedMIS({
    fromDate,
    toDate,
    sourceEntityIDs = [],
    selectionMode,
    token,
    pageName,
  }: ServiceMethodArgs<{
    fromDate: Date;
    toDate: Date;
    sourceEntityIDs: Array<string>;
    selectionMode: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    try {
      const searchParams = new URLSearchParams();
      if (fromDate) {
        searchParams.append("from", format(new Date(fromDate), "yyyy-MM-dd"));
      }
      if (toDate) {
        searchParams.append("to", format(new Date(toDate), "yyyy-MM-dd"));
      }
      if (sourceEntityIDs) {
        sourceEntityIDs.forEach((sourceEntityID) =>
          searchParams.append("sourceEntityIDs", sourceEntityID)
        );
      }
      if (selectionMode) {
        searchParams.append("selectionMode", selectionMode);
      }
      return await Get(
        `/masterDashboard/downloadRejectedMIS?${searchParams.toString()}`,
        token,
        headers
      );
    } catch (err: any) {
      return {
        ...DEFAULT_RESPONSE,
        error: new Error(err).message,
      };
    }
  }

  static async disqualificationReport({
    fromDate,
    toDate,
    productType,
    dateType,
    selectionMode,
    token,
    pageName,
  }: ServiceMethodArgs<{
    fromDate: string;
    toDate: string;
    productType: string;
    selectionMode: string;
    dateType: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const searchParams = new URLSearchParams();

    try {
      if (fromDate) {
        searchParams.append(
          "fromDate",
          format(new Date(fromDate), "yyyy-MM-dd")
        );
      }
      if (toDate) {
        searchParams.append("toDate", format(new Date(toDate), "yyyy-MM-dd"));
      }
      if (productType) {
        searchParams.append("productType", productType);
      }
      if (dateType) {
        searchParams.append("dateFilter", dateType);
      }
      if (selectionMode) {
        searchParams.append("selectionMode", selectionMode);
      }
      return await Get(
        `/masterDashboard/disqualificationReport?${searchParams.toString()}`,
        token,
        headers
      );
    } catch (err: any) {
      return {
        ...DEFAULT_RESPONSE,
        error: new Error(err).message,
      };
    }
  }

  static async documentReport({
    fromDate,
    toDate,
    dateType,
    token,
    selectionMode,
    sourceEntityIDs = [],
    pageName,
  }: ServiceMethodArgs<{
    fromDate: string;
    toDate: string;
    dateType: string;
    selectionMode: string;
    sourceEntityIDs: Array<string>;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const searchParams = new URLSearchParams();
    try {
      if (fromDate) {
        searchParams.append(
          "fromDate",
          format(new Date(fromDate), "yyyy-MM-dd")
        );
      }
      if (toDate) {
        searchParams.append("toDate", format(new Date(toDate), "yyyy-MM-dd"));
      }
      if (dateType) {
        searchParams.append("dateFilter", dateType);
      }

      if (sourceEntityIDs) {
        sourceEntityIDs.map((s) => searchParams.append("sourceEntityIDs", s));
      }
      if (selectionMode) {
        searchParams.append("selectionMode", selectionMode);
      }
      return await Get(
        `/masterDashboard/documentReport?${searchParams.toString()}`,
        token,
        headers
      );
    } catch (err: any) {
      return {
        ...DEFAULT_RESPONSE,
        error: new Error(err).message,
      };
    }
  }

  static async loanStatusReport({
    fromDate,
    toDate,
    productType,
    dateType,
    selectionMode,
    token,
    pageName,
  }: ServiceMethodArgs<{
    fromDate: string;
    toDate: string;
    productType: string;
    dateType: string;
    selectionMode: string;
    token: string;
  }>): Promise<any> {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    try {
      const searchParams = new URLSearchParams();
      if (fromDate) {
        searchParams.append(
          "fromDate",
          format(new Date(fromDate), "yyyy-MM-dd")
        );
      }
      if (toDate) {
        searchParams.append("toDate", format(new Date(toDate), "yyyy-MM-dd"));
      }
      if (dateType) {
        searchParams.append("dateFilter", dateType);
      }
      if (productType) {
        searchParams.append("productType", productType);
      }
      if (selectionMode) {
        searchParams.append("selectionMode", selectionMode);
      }
      return await Get(
        `/masterDashboard/loanStatusReport?${searchParams.toString()}`,
        token,
        headers
      );
    } catch (err: any) {
      return {
        ...DEFAULT_RESPONSE,
        error: new Error(err).message,
      };
    }
  }

  static async insuranceReport({
    fromDate,
    toDate,
    dateType,
    selectionMode,
    token,
    sourceEntityIDs = [],
    pageName,
  }: ServiceMethodArgs<{
    fromDate: string;
    toDate: string;
    dateType: string;
    selectionMode: string;
    sourceEntityIDs: Array<string>;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    try {
      const searchParams = new URLSearchParams();
      if (fromDate) {
        searchParams.append(
          "fromDate",
          format(new Date(fromDate), "yyyy-MM-dd")
        );
      }
      if (toDate) {
        searchParams.append("toDate", format(new Date(toDate), "yyyy-MM-dd"));
      }
      if (sourceEntityIDs) {
        sourceEntityIDs.map((s) => searchParams.append("sourceEntityIDs", s));
      }
      if (dateType) {
        searchParams.append("dateFilter", dateType);
      }
      if (selectionMode) {
        searchParams.append("selectionMode", selectionMode);
      }
      const response = await Get(
        `/masterDashboard/insuranceReport?${searchParams.toString()}`,
        token,
        headers
      );
      return response;
    } catch (err: any) {
      return {
        ...DEFAULT_RESPONSE,
        error: new Error(err).message,
      };
    }
  }

  static async employmentReport({
    fromDate,
    toDate,
    dateType,
    selectionMode,
    sourceEntityIDs = [],
    token,
    pageName,
  }: ServiceMethodArgs<{
    fromDate: string;
    toDate: string;
    dateType: string;
    selectionMode: string;
    token: string;
    sourceEntityIDs: Array<string>;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    try {
      const searchParams = new URLSearchParams();
      if (fromDate) {
        searchParams.append(
          "fromDate",
          format(new Date(fromDate), "yyyy-MM-dd")
        );
      }
      if (toDate) {
        searchParams.append("toDate", format(new Date(toDate), "yyyy-MM-dd"));
      }
      if (sourceEntityIDs) {
        sourceEntityIDs.map((s) => searchParams.append("sourceEntityIDs", s));
      }
      if (dateType) {
        searchParams.append("dateFilter", dateType);
      }
      if (selectionMode) {
        searchParams.append("selectionMode", selectionMode);
      }
      return await Get(
        `/masterDashboard/employmentReport?${searchParams.toString()}`,
        token,
        headers
      );
    } catch (err: any) {
      return {
        ...DEFAULT_RESPONSE,
        error: new Error(err).message,
      };
    }
  }

  static async funnelReport({
    fromDate,
    toDate,
    dateType,
    sourceEntityIDs = [],
    selectionMode,
    token,
    productType,
    pageName,
  }: ServiceMethodArgs<{
    fromDate: string;
    toDate: string;
    dateType: string;
    sourceEntityIDs: Array<string>;
    selectionMode: string;
    productType: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    try {
      const searchParams = new URLSearchParams();
      if (fromDate) {
        searchParams.append(
          "fromDate",
          format(new Date(fromDate), "yyyy-MM-dd")
        );
      }
      if (toDate) {
        searchParams.append("toDate", format(new Date(toDate), "yyyy-MM-dd"));
      }
      if (sourceEntityIDs) {
        sourceEntityIDs.map((s) => searchParams.append("sourceEntityIDs", s));
      }
      if (dateType) {
        searchParams.append("dateFilter", dateType);
      }
      if (productType) {
        searchParams.append("productType", productType);
      }
      if (selectionMode) {
        searchParams.append("selectionMode", selectionMode);
      }
      return await Get(
        `/masterDashboard/funnelReport?${searchParams.toString()}`,
        token,
        headers
      );
    } catch (err: any) {
      return {
        ...DEFAULT_RESPONSE,
        error: new Error(err).message,
      };
    }
  }

  static async odBillsDump({
    dateType,
    fromDate,
    toDate,
    token,
    sourceEntityIDs = [],
    selectionMode,
    statusText,
    pageName,
  }: ServiceMethodArgs<{
    dateType: string;
    sourceEntityIDs: Array<string>;
    selectionMode: string;
    statusText: Array<string>;
    fromDate: Date;
    toDate: Date;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const searchParams = new URLSearchParams();
    if (fromDate) {
      searchParams.append("from", format(new Date(fromDate), "yyyy-MM-dd"));
    }
    if (toDate) {
      searchParams.append("to", format(new Date(toDate), "yyyy-MM-dd"));
    }
    if (sourceEntityIDs) {
      sourceEntityIDs.forEach((sourceEntityID) =>
        searchParams.append("sourceEntityIDs", sourceEntityID)
      );
    }
    statusText.map((s) => {
      searchParams.append("statusText", s);
    });
    if (dateType) {
      searchParams.append("filter", dateType);
    }
    if (selectionMode) {
      searchParams.append("selectionMode", selectionMode);
    }
    return await Get(
      `/masterDashboard/odBillsDump?${searchParams.toString()}`,
      token,
      headers
    );
  }

  static async userDataDump({
    toDate,
    dateType,
    source,
    statusText,
    fromDate,
    sourceEntityIDs = [],
    selectionMode,
    dsaID,
    token,
    pageName,
  }: ServiceMethodArgs<{
    toDate: Date;
    fromDate: Date;
    sourceEntityIDs: Array<string>;
    statusText: Array<string>;
    selectionMode: string;
    source: string;
    dateType: string;
    dsaID: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const searchParams = new URLSearchParams();

    if (fromDate) {
      searchParams.append("from", format(new Date(fromDate), "yyyy-MM-dd"));
    }
    if (toDate) {
      searchParams.append("to", format(new Date(toDate), "yyyy-MM-dd"));
    }
    if (sourceEntityIDs) {
      sourceEntityIDs.forEach((sourceEntityID) =>
        searchParams.append("sourceEntityIDs", sourceEntityID)
      );
    }
    statusText.map((s) => {
      searchParams.append("statusText", s);
    });
    if (dateType) {
      searchParams.append("filter", dateType);
    }
    if (dsaID) {
      searchParams.append("dsaID", dsaID);
    }
    if (source) {
      searchParams.append("source", source);
    }
    if (selectionMode) {
      searchParams.append("selectionMode", selectionMode);
    }
    return await Get(
      `/masterDashboard/userDataDump?${searchParams.toString()}`,
      token,
      headers
    );
  }

  static async loanDateRangeDump({
    dsaIDs = [],
    statusText,
    productTypes,
    dateType,
    sourceEntityIDs = [],
    selectionMode,
    source,
    fromDate,
    toDate,
    token,
    dsaID,
    pageName,
  }: ServiceMethodArgs<{
    toDate: Date;
    fromDate: Date;
    sourceEntityIDs: Array<string>;
    selectionMode: string;
    statusText: Array<string>;
    source: string;
    dateType: string;
    dsaIDs: Array<string>;
    productTypes: Array<string>;
    dsaID: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const searchParams = new URLSearchParams();
    if (fromDate) {
      searchParams.append("from", format(new Date(fromDate), "yyyy-MM-dd"));
    }
    if (toDate) {
      searchParams.append("to", format(new Date(toDate), "yyyy-MM-dd"));
    }
    if (sourceEntityIDs) {
      sourceEntityIDs.forEach((sourceEntityID) =>
        searchParams.append("sourceEntityIDs", sourceEntityID)
      );
    }
    statusText.map((s) => {
      searchParams.append("statusText", s);
    });
    if (dateType) {
      searchParams.append("filter", dateType);
    }
    if (dsaID) {
      searchParams.append("dsaID", dsaID);
    }
    if (source) {
      searchParams.append("source", source);
    }
    if (productTypes?.length) {
      productTypes.map((productType) => {
        searchParams.append("loanType", productType);
      });
    }
    if (selectionMode) {
      searchParams.append("selectionMode", selectionMode);
    }
    return await Get(
      `/masterDashboard/loanDateRangeDump?${searchParams.toString()}`,
      token,
      headers
    );
  }

  static async collectionDump({
    fromDate,
    toDate,
    productType,
    sourceEntityIDs = [],
    selectionMode,
    dsaID,
    source,
    dateType,
    token,
    pageName,
  }: ServiceMethodArgs<{
    fromDate: Date;
    toDate: Date;
    productType: string;
    sourceEntityIDs: Array<string>;
    selectionMode: string;
    dsaID?: string;
    source: string;
    dateType: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const searchParams = new URLSearchParams();
    if (fromDate) {
      searchParams.append("from", format(new Date(fromDate), "yyyy-MM-dd"));
    }
    if (toDate) {
      searchParams.append("to", format(new Date(toDate), "yyyy-MM-dd"));
    }
    if (productType) {
      searchParams.append("loanType", productType);
    }
    if (dateType) {
      searchParams.append("filter", dateType);
    }
    if (sourceEntityIDs) {
      sourceEntityIDs.forEach((sourceEntityID) =>
        searchParams.append("sourceEntityIDs", sourceEntityID)
      );
    }
    if (dsaID) {
      searchParams.append("dsaID", dsaID);
    }
    if (selectionMode) {
      searchParams.append("selectionMode", selectionMode);
    }

    return await Get(
      `/masterDashboard/collectionDump?${searchParams.toString()}`,
      token,
      headers
    );
  }

  static async ruleEngineDump({
    dateType,
    toDate,
    fromDate,
    sourceEntityIDs,
    selectionMode,
    token,
    pageName,
  }: ServiceMethodArgs<{
    dateType: string;
    toDate: Date;
    fromDate: Date;
    sourceEntityIDs: Array<string>;
    selectionMode: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const searchParams = new URLSearchParams();
    if (fromDate) {
      searchParams.append("from", format(new Date(fromDate), "yyyy-MM-dd"));
    }
    if (toDate) {
      searchParams.append("to", format(new Date(toDate), "yyyy-MM-dd"));
    }
    if (sourceEntityIDs) {
      sourceEntityIDs.forEach((sourceEntityID) =>
        searchParams.append("sourceEntityIDs", sourceEntityID)
      );
    }
    if (dateType) {
      searchParams.append("filter", dateType);
    }
    if (selectionMode) {
      searchParams.append("selectionMode", selectionMode);
    }
    return await Get(
      `/masterDashboard/ruleEngineDump?${searchParams.toString()}`,
      token,
      headers
    );
  }

  static async nachStatusDump({
    token,
    dsaID,
    sourceEntityIDs,
    selectionMode,
    pageName,
  }: ServiceMethodArgs<{
    sourceEntityIDs: Array<string>;
    dsaID: string;
    selectionMode: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    let searchParams = new URLSearchParams();
    if (sourceEntityIDs) {
      sourceEntityIDs.forEach((sourceEntityID) =>
        searchParams.append("sourceEntityIDs", sourceEntityID)
      );
    }
    if (dsaID) {
      searchParams.append("dsaID", dsaID);
    }
    if (selectionMode) {
      searchParams.append("selectionMode", selectionMode);
    }
    return await Get(
      `/masterDashboard/nachStatusDump?${searchParams.toString()}`,
      token,
      headers
    );
  }

  static async transactionsDump({
    token,
    statusText,
    toDate,
    fromDate,
    sourceEntityIDs,
    selectionMode,
    dateType,
    pageName,
  }: ServiceMethodArgs<{
    statusText: Array<string>;
    toDate: Date;
    fromDate: Date;
    sourceEntityIDs: Array<string>;
    selectionMode: string;
    dateType: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const searchParams = new URLSearchParams();
    if (fromDate) {
      searchParams.append("from", format(new Date(fromDate), "yyyy-MM-dd"));
    }
    if (toDate) {
      searchParams.append("to", format(new Date(toDate), "yyyy-MM-dd"));
    }
    if (sourceEntityIDs) {
      sourceEntityIDs.forEach((sourceEntityID) =>
        searchParams.append("sourceEntityIDs", sourceEntityID)
      );
    }
    statusText.map((s) => {
      searchParams.append("statusText", s);
    });
    if (dateType) {
      searchParams.append("filter", dateType);
    }
    if (selectionMode) {
      searchParams.append("selectionMode", selectionMode);
    }
    return await Get(
      `/masterDashboard/transactionsDump?${searchParams.toString()}`,
      token,
      headers
    );
  }

  static async getReportList({ token, pageName }: ServiceMethodArgs<{}>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    return await Get(`/masterDashboard/listReports`, token, headers);
  }

  static async pflCustomUserDataDump({
    token,
    toDate,
    fromDate,
    dateType,
    loanStages,
    leadStages,
    pageName,
  }: ServiceMethodArgs<{
    statusText: Array<string>;
    toDate: Date;
    fromDate: Date;
    dateType: string;
    loanStages: Array<string>;
    leadStages: Array<string>;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const searchParams = new URLSearchParams();
    if (fromDate) {
      searchParams.append("from", format(new Date(fromDate), "yyyy-MM-dd"));
    }
    if (toDate) {
      searchParams.append("to", format(new Date(toDate), "yyyy-MM-dd"));
    }
    if (dateType) {
      searchParams.append("filter", dateType);
    }

    if (leadStages && leadStages.length > 0) {
      leadStages.forEach((f) =>
        searchParams.append("leadStage", f.toUpperCase())
      );
    }

    if (loanStages && loanStages.length > 0 && leadStages.length === 0) {
      loanStages.forEach((f) =>
        searchParams.append("loanStage", f.toUpperCase())
      );
    }

    return await Get(
      `/masterDashboard/pflCustomUserDataDump?${searchParams.toString()}`,
      token,
      headers
    );
  }

  static async pflEnachMandateReport({
    token,
    toDate,
    fromDate,
    dateType,
    pageName,
  }: ServiceMethodArgs<{
    toDate: Date;
    fromDate: Date;
    dateType: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const searchParams = new URLSearchParams();
    if (fromDate) {
      searchParams.append("from", format(new Date(fromDate), "yyyy-MM-dd"));
    }
    if (toDate) {
      searchParams.append("to", format(new Date(toDate), "yyyy-MM-dd"));
    }
    if (dateType) {
      searchParams.append("filter", dateType);
    }

    return await Get(
      `/masterDashboard/pflEnachMandateReport?${searchParams.toString()}`,
      token,
      headers
    );
  }

  static async getSalesDispositionReport({
    token,
    toDate,
    fromDate,
    dateType,
    salesDispositionType,
    caseStatuses,
    pageName,
  }: ServiceMethodArgs<{
    toDate: Date;
    fromDate: Date;
    dateType: string;
    salesDispositionType: string;
    caseStatuses: string[];
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const requestBody = {
      from: "",
      to: "",
      filter: "",
      sourceEntityID: "b2b9a111-32a7-4001-bb1d-216f46ab8a93",
      caseStatuses: [] as string[],
    };
    if (fromDate) {
      requestBody["from"] = new Date(fromDate).toISOString();
    }
    if (toDate) {
      requestBody["to"] = new Date(toDate).toISOString();
    }
    if (dateType) {
      requestBody["filter"] = dateType;
    }
    if (caseStatuses.length > 0) {
      requestBody["caseStatuses"] = caseStatuses;
    }

    return await Post(
      `/masterDashboard/getSalesDispositionReport`,
      token,
      requestBody,
      headers
    );
  }

  static async getWorkflowTaskReport({
    token,
    toDate,
    fromDate,
    dateType,
    workflowType,
    status,
    pageName,
  }: ServiceMethodArgs<{
    toDate: Date;
    fromDate: Date;
    dateType: string;
    workflowType: string;
    workflowStatus: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const requestBody = {
      from: "",
      to: "",
      filter: "",
      workflowType: "",
      status: "",
    };
    if (fromDate) {
      requestBody["from"] = new Date(fromDate).toISOString();
    }
    if (toDate) {
      requestBody["to"] = new Date(toDate).toISOString();
    }
    if (dateType) {
      requestBody["filter"] = dateType;
    }
    if (workflowType) {
      requestBody["workflowType"] = workflowType;
    }
    if (status) {
      requestBody["status"] = status;
    }
    return await Post(
      `/masterDashboard/getWorkflowTaskReport`,
      token,
      requestBody,
      headers
    );
  }

  static async multiOfferReport({
    token,
    toDate,
    fromDate,
    dateType,
    pageName,
  }: ServiceMethodArgs<{
    toDate: Date;
    fromDate: Date;
    dateType: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const searchParams = new URLSearchParams();
    if (fromDate) {
      searchParams.append("from", format(new Date(fromDate), "yyyy-MM-dd"));
    }
    if (toDate) {
      searchParams.append("to", format(new Date(toDate), "yyyy-MM-dd"));
    }
    if (dateType) {
      searchParams.append("filter", dateType);
    }

    return await Get(
      `/masterDashboard/multiOfferReport?${searchParams.toString()}`,
      token,
      headers
    );
  }

  static async pflUTMReport({
    token,
    toDate,
    fromDate,
    dateType,
    pageName,
  }: ServiceMethodArgs<{
    statusText: Array<string>;
    toDate: Date;
    fromDate: Date;
    dateType: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const searchParams = new URLSearchParams();
    if (fromDate) {
      searchParams.append("from", format(new Date(fromDate), "yyyy-MM-dd"));
    }
    if (toDate) {
      searchParams.append("to", format(new Date(toDate), "yyyy-MM-dd"));
    }
    if (dateType) {
      searchParams.append("filter", dateType);
    }

    return await Get(
      `/masterDashboard/pflUtmReport?${searchParams.toString()}`,
      token,
      headers
    );
  }

  static async abflCustomReport({
    token,
    toDate,
    fromDate,
    dateType,
    pageName,
  }: ServiceMethodArgs<{
    statusText: Array<string>;
    toDate: Date;
    fromDate: Date;
    dateType: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    const searchParams = new URLSearchParams();
    if (fromDate) {
      searchParams.append("from", format(new Date(fromDate), "yyyy-MM-dd"));
    }
    if (toDate) {
      searchParams.append("to", format(new Date(toDate), "yyyy-MM-dd"));
    }
    if (dateType) {
      searchParams.append("filter", dateType);
    }

    return await Get(
      `/masterDashboard/abflCustomReport?${searchParams.toString()}`,
      token,
      headers
    );
  }

  static async abflUTMReport({
    token,
    toDate,
    fromDate,
    dateType,
    pageName,
  }: ServiceMethodArgs<{
    statusText: Array<string>;
    toDate: Date;
    fromDate: Date;
    dateType: string;
  }>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    if (!fromDate || !toDate || !dateType) {
      console.error("FromDate, ToDate and DateType are mandatory fields");
      return;
    }

    const searchParams = new URLSearchParams({
      from: format(new Date(fromDate), "yyyy-MM-dd"),
      to: format(new Date(toDate), "yyyy-MM-dd"),
      filter: dateType,
    });

    return await Get(
      `/masterDashboard/abflUTMReport?${searchParams.toString()}`,
      token,
      headers
    );
  }
}
