import { IAuthService } from "./authService";
import { APIResponse } from "../../shared/services/APIResponse";
import { left, right } from "../../shared/core/Either";
import { BaseAPI } from "../../shared/services/BaseAPI";
import { Result } from "../../shared/core/Result";
import { transformDTOToQueryParamString } from "../../shared/utils/APIUtil";
import {
  GetOverdraftBillBySearchQueryRequestDTO,
  GetOverdraftBillsRequestDTO,
  GetOverdraftBillsResponseDTO,
} from "../../dtos/OverdraftBillDTO";

export interface IOverdraftService {
  getOverdrafts(
    dto: GetOverdraftBillsRequestDTO
  ): Promise<APIResponse<GetOverdraftBillsResponseDTO>>;
  getOverdraftsBySearchQuery(
    dto: GetOverdraftBillBySearchQueryRequestDTO
  ): Promise<APIResponse<GetOverdraftBillsResponseDTO>>;
}

export class OverdraftService extends BaseAPI implements IOverdraftService {
  constructor(authService: IAuthService) {
    super(authService);
  }

  async getOverdrafts(
    dto: GetOverdraftBillsRequestDTO
  ): Promise<APIResponse<GetOverdraftBillsResponseDTO>> {
    try {
      const response = await this.get(
        `/masterDashboard/getODBills${transformDTOToQueryParamString<
          GetOverdraftBillsRequestDTO | {}
        >(dto)}`
      );
      if (response.status) {
        return right(Result.ok<any>(response.data));
      } else {
        return left(response.error ? response.error : "Connection failed");
      }
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }

  async getOverdraftsBySearchQuery(
    dto: GetOverdraftBillBySearchQueryRequestDTO
  ): Promise<APIResponse<GetOverdraftBillsResponseDTO>> {
    try {
      const response = await this.get(
        `/masterDashboard/searchOnlyGetODBills${transformDTOToQueryParamString<
          GetOverdraftBillBySearchQueryRequestDTO | {}
        >(dto)}`
      );
      if (response.status) {
        return right(Result.ok<any>(response.data));
      } else {
        return left(response.error ? response.error : "Connection failed");
      }
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }
}
