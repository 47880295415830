import { SettingsService } from "../../services/Settings";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

export async function getThemeListLoader({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const { sourceEntityID = "" } = params;
  const token = getToken();
  return await SettingsService.getThemes({ token, sourceEntityID, pageName });
}
