import { produce } from "immer";
import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { IAction, IPageState } from "./interface";
import { PRODUCT_TYPES } from "../../services/Partners/constants";

export const ACTIONS = {
  SET_EDIT_MODE: "SET_EDIT_MODE",
  SET_CURRENT_BULK_ACTION: "SET_CURRENT_BULK_ACTION",
  SET_SHOW_ADD_EDIT_MODAL: "SET_SHOW_ADD_EDIT_MODAL",
  SET_SHOW_REASSIGN_MANAGER_MODAL: "SET_SHOW_REASSIGN_MANAGER_MODAL",
  SET_PAGE_TYPE: "SET_PAGE_TYPE",
  SET_SEARCH: "SET_SEARCH",
  SET_SHOW_DEACTIVATE_AGENT_MODAL: "SET_SHOW_DEACTIVATE_AGENT_MODAL",
  SET_SHOW_DELETE_AGENT_MODAL: "SET_SHOW_DELETE_AGENT_MODAL",
  SET_PRODUCT_TYPE: "SET_PRODUCT_TYPE",
  SET_PAGE: "SET_PAGE",
  SET_SHOW_COLLABORATORS_VIEW: "SET_SHOW_COLLABORATORS_VIEW",
  UPDATE_STATE: "UPDATE_STATE",
  SET_SHOW_COLLABORATOR_PROFILE: "SET_SHOW_COLLABORATOR_PROFILE",
  SET_HIDE_COLLABORATOR_PROFILE: "SET_HIDE_COLLABORATOR_PROFILE",
  SET_TOGGLE_PROFILE_VIEW: "SET_TOGGLE_PROFILE_VIEW",
  SET_TOGGLE_COLLABORATORS_VIEW: "SET_TOGGLE_COLLABORATORS_VIEW",
  UPDATE_CHANNELS_LIST: "UPDATE_CHANNELS_LIST",
} as const;

function getParams(param: string) {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(param) ?? "";
}

// TODO: Move default state to loader
export const defaultState: IPageState = {
  pageType:
    getParams("pageType") === "list"
      ? {
          name: "List",
          value: "list",
        }
      : {
          name: "Chart",
          value: "chart",
        },
  isEdit: false,
  collaboratorsList: [],
  activeProfile: null,
  activeCollaborator: null,
  isShowAddEditModal: false,
  isShowBulkActionModal: false,
  isShowDeactivateAgentModal: false,
  isShowDeleteAgentModal: false,
  isShowCollaboratorModal: false,
  isShowCollaboratorView: false,
  isShowCollaboratorViewModal: false,
  isShowReassignManagerModal: false,
  isShowReactivateAccountModal: false,
  isProfileOpen: false,
  currentBulkAction: {},
  searchText: getParams("searchText"),
  productType:
    PRODUCT_TYPES.find((pType) => pType.value === getParams("productType")) ||
    PRODUCT_TYPES[0],
  searchType: getParams("searchType"),
  listPageState: {
    page: getParams("pageType") === "list" ? +getParams("page") || 1 : 1,
  },
  chartPageState: {
    page: getParams("pageType") === "chart" ? +getParams("page") || 1 : 1,
    currentNodeDepth: 4,
    node_id: null,
  },
  agentToDelete: null,
  agentToReassign: null,
  agentToReactivate: null,
  // agentToDeactivate: null,
  dsaToAddEdit: null,
  agentToReassignManager: null,
  managerToBeReassgined: null,
  channelsList: [],
};

function reducer(draft: IPageState, action: IAction) {
  const { payload = {}, type } = action;

  if (import.meta.env.REACT_APP_ENV !== "PROD") {
    console.group("page_action");
    console.table({
      type,
      payload,
    });
    console.groupEnd();
  }

  switch (type) {
    case ACTIONS.UPDATE_CHANNELS_LIST: {
      payload.channelsList?.map((c) => {
        draft.channelsList.push(c);
      });
      return draft;
    }
    case ACTIONS.SET_CURRENT_BULK_ACTION: {
      draft.currentBulkAction = payload?.currentBulkAction ?? null;
      draft.isShowBulkActionModal = payload?.isShowBulkActionModal || false;

      return draft;
    }

    case ACTIONS.SET_SHOW_ADD_EDIT_MODAL: {
      draft.isEdit = payload?.isEdit || false;
      draft.isShowAddEditModal = payload.isShowAddEditModal || false;
      if (payload.activeProfile) {
        draft.activeProfile = payload.activeProfile;
      }
      return draft;
    }

    case ACTIONS.SET_PAGE: {
      if (payload?.listPageState?.page) {
        draft.listPageState = payload.listPageState || {
          page: 1,
        };
      } else {
        draft.chartPageState = payload.chartPageState || {
          page: 1,
          currentNodeDepth: 4,
          node_id: null,
        };
      }
      if (
        typeof payload.searchText !== "undefined" &&
        typeof payload.searchType !== "undefined"
      ) {
        draft.searchText = payload.searchText;
        draft.searchType = payload.searchType;
      }
      return draft;
    }

    case ACTIONS.SET_PAGE_TYPE: {
      if (payload.pageType) {
        draft.pageType = payload.pageType;
        draft.searchText = "";
        draft.searchType = "";
        if (payload.pageType.value === "chart") {
          draft.chartPageState = {
            page: 1,
            currentNodeDepth: 4,
            node_id: null,
          };
          draft.listPageState = defaultState.listPageState;
        } else {
          draft.listPageState = {
            page: 1,
          };
          draft.chartPageState = defaultState.chartPageState;
        }
      }
      return draft;
    }

    case ACTIONS.SET_PRODUCT_TYPE: {
      if (payload.productType) {
        draft.productType = payload.productType;
      }
      return draft;
    }

    case ACTIONS.SET_SEARCH: {
      draft.searchType = payload.searchType || "";
      draft.searchText = payload.searchText || "";

      return draft;
    }

    case ACTIONS.SET_SHOW_DEACTIVATE_AGENT_MODAL: {
      draft.isShowDeactivateAgentModal =
        payload?.isShowDeactivateAgentModal || false;
      if (payload.activeProfile) {
        draft.activeProfile = payload.activeProfile;
      }
      return draft;
    }

    case ACTIONS.SET_SHOW_DELETE_AGENT_MODAL: {
      draft.isShowDeleteAgentModal = payload?.isShowDeleteAgentModal || false;
      if (payload.activeProfile) {
        draft.activeProfile = payload.activeProfile;
      }

      return draft;
    }

    case ACTIONS.SET_SHOW_REASSIGN_MANAGER_MODAL: {
      draft.isShowReassignManagerModal =
        payload?.isShowReassignManagerModal || false;
      if (payload.activeProfile) {
        draft.activeProfile = payload.activeProfile;
      }

      return draft;
    }

    case ACTIONS.SET_TOGGLE_COLLABORATORS_VIEW: {
      draft.isShowCollaboratorView = payload?.isShowCollaboratorView ?? false;
      return draft;
    }

    case ACTIONS.SET_TOGGLE_PROFILE_VIEW: {
      draft.isShowCollaboratorView = payload.isShowCollaboratorView ?? false;
      draft.isProfileOpen = payload?.isProfileOpen ?? false;
      draft.activeProfile = payload?.activeProfile ?? null;
      return draft;
    }

    case ACTIONS.SET_SHOW_COLLABORATOR_PROFILE: {
      draft.isShowCollaboratorView = false;
      draft.activeCollaborator = payload?.activeCollaborator ?? null;
      return draft;
    }

    case ACTIONS.SET_HIDE_COLLABORATOR_PROFILE: {
      draft.activeCollaborator = null;
      return draft;
    }

    case ACTIONS.UPDATE_STATE: {
      Object.keys(payload).map((key) => {
        draft[key] = payload?.[key];
      });

      return draft;
    }

    default:
      return draft;
  }
}

interface IPartnersContext {
  pageState: IPageState;
  updatePageState: (
    type: IAction["type"],
    payload?: IAction["payload"]
  ) => void;
}

const PartnersContext = createContext<IPartnersContext | null>(null);
export const PartnersProvider = ({ children }: { children: JSX.Element }) => {
  const [pageState, dispatch] = useReducer(produce(reducer), defaultState);

  const updatePageState = useCallback(
    (type: IAction["type"], payload: IAction["payload"]) => {
      dispatch({
        type,
        payload,
      });
    },
    [dispatch]
  );

  return (
    <PartnersContext.Provider value={{ pageState, updatePageState }}>
      {children}
    </PartnersContext.Provider>
  );
};

const GetPartnersContext = (): IPartnersContext => {
  const context = useContext(PartnersContext);
  if (!context) throw new Error("PartnersContext is not defined");
  return context;
};

export default GetPartnersContext;
