import { initialActiveLineListState } from ".";
import { AutocompleteOption } from "../../../components/atomic/Dropdown/AutocompleteCombobox";
import { GetActiveCreditLineResponseDTO } from "../../../dtos/ActiveCreditLineDTO";
import {
  sourceEntityService,
  activeCreditLineService,
} from "../../../services/apis";
import { CustomLoaderFunctionArgs } from "../../../shared/router/RouteLoader";

export interface ActiveLineLoaderDTO {
  preLoadedActiveLineData: GetActiveCreditLineResponseDTO;
  sourceEntityListOption: AutocompleteOption[];
}

export const activeLineLoader = async ({
  params,
  request,
  pageName,
}: CustomLoaderFunctionArgs): Promise<ActiveLineLoaderDTO> => {
  const url = new URL(request.url);
  let sourceEntityListOption: AutocompleteOption[] = [];
  const { activeLineData } = initialActiveLineListState;
  let preLoadedActiveLineData: GetActiveCreditLineResponseDTO = activeLineData;
  const result = await Promise.all([
    sourceEntityService.getSourceEntityList(pageName),
    activeCreditLineService.getActiveLines(
      {
        page: 1,
        limit: 10,
        sourceEntityIDs: undefined,
      },
      pageName
    ),
  ]);

  if (result[0].isRight()) {
    const { sourceList } = result[0].value.getValue();
    sourceEntityListOption = sourceList.map((el) => ({
      id: el.sourceEntityID,
      name: el.sourceEntityName,
    }));
  }
  if (result[1].isRight()) {
    preLoadedActiveLineData = result[1].value.getValue();
  }
  return {
    preLoadedActiveLineData,
    sourceEntityListOption,
  };
};
