import { Get } from "../../shared/services/Fetch";

export class WorkflowService {
    constructor() {}
  
    static async getWorkflows({ token, resourceID, resourceType, pageName }:{ token: string, resourceID: string, resourceType: string, pageName?: string }) {
        const headers = new Headers();
        if (pageName) {
            headers.append("page-header", pageName);
        }
        const response = await Get(
            `/masterDashboard/getWorkflows?resourceID=${resourceID}&resourceType=${resourceType}`,
            token,
            headers
        );

        return response;
    }
}