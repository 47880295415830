import React from "react";
import { useParams } from "react-router-dom";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

import Button from "../../components/atomic/Button";
import { notify } from "../../shared/context/AlertContext";
import GetAuthContext from "../../shared/context/AuthContext";
import { getFormattedCurrency } from "../../shared/utils/Global";
import { LoanApplicationService } from "../../services/LoanApplication";

type Props = {
  loanOffer: any;
  getValues: any;
  isValid: boolean;
};

export default function CalculateEMI(props: Props) {
  const { loanID } = useParams();
  const { fetchToken } = GetAuthContext();

  const [newEMI, setNewEMI] = React.useState<number>(0);
  const [loading, setLoading] = React.useState(false);

  const emi = props?.loanOffer?.emis?.[0]?.emiAmount;

  const calculateHandler = async () => {
    if (!props?.isValid) {
      return;
    }
    const formValues = props?.getValues?.();
    setLoading(true);
    const payload = {
      loanApplicationID: loanID as string,
      amount: String(props?.loanOffer?.amount),
      tenure: String(formValues?.proposed?.tenureMonths),
      interest: String(formValues?.proposed?.annualInterest),
    };
    const response = (await LoanApplicationService.getOfferNegotiationEMI({
      payload,
      token: fetchToken(),
      pageName: "Loan Application Loan Term Deviation Tab",
    })) as any;
    if (response?.status) {
      setNewEMI(response?.data?.emi);
    } else {
      notify({
        title: "Error",
        text: response.error,
      });
    }
    setLoading(false);
  };

  return (
    <div className="border rounded-lg px-2.5 py-2 flex items-center justify-between mb-8">
      <div className="p-1 bg-[#E3E4E5] text-xs font-medium flex items-center rounded text-[#626262]">
        <div className="bg-white px-2 py-0.5 rounded">Current EMI</div>
        <div className="ml-2">{getFormattedCurrency(emi)}</div>
      </div>
      <div className="flex items-center">
        {!!newEMI && (
          <div className="flex items-center mr-1.5 text-xs font-medium">
            <div className="text-[#373737]">New EMI:</div>
            <div className="text-[#204C86] ml-1">
              {getFormattedCurrency(newEMI)}
            </div>
          </div>
        )}
        <Button
          outline
          color="primary"
          onClick={calculateHandler}
          type={props?.isValid ? "button" : "submit"}
          className="text-xs !px-3 !py-2 border-none"
        >
          {loading ? (
            "Calculating..."
          ) : (
            <>
              {newEMI ? (
                <ArrowPathIcon className="h-4 w-4 text-[#204C86]" />
              ) : (
                "Calculate new EMI"
              )}
            </>
          )}
        </Button>
      </div>
    </div>
  );
}
