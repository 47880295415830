export const WORKFLOWS_WITH_DOCS = [
  {
    name: "KYC - RCU Document Request",
    actions: ["abfl_rad_complete"],
    status: ["rad", "vkyc_and_rad"],
    workflowID: "abfl_kyc_wf",
    key: "abfl_rad_complete",
  },
  {
    name: "Manual Credit Review Workflow - Credit L1",
    workflowID: "abfl_bre_wf",
    status: ["processing"],
    actions: ["Credit L1"],
    key: "Credit L1",
  },
  {
    name: "Manual Credit Review Workflow - RCU",
    workflowID: "abfl_bre_wf",
    status: ["processing"],
    actions: ["RCU"],
    key: "RCU",
  },
  {
    name: "Manual Credit Review Workflow - Sales",
    workflowID: "abfl_bre_wf",
    status: ["processing"],
    actions: ["Sales"],
    key: "Sales",
  },
];

