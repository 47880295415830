import { GetSourceEntityListDTO } from "../../dtos/SourceEntityDTO";
import { APIResponse } from "../../shared/services/APIResponse";
import { left, right } from "../../shared/core/Either";
import { IAuthService } from "./authService";
import { BaseAPI } from "../../shared/services/BaseAPI";
import { Result } from "../../shared/core/Result";
import { noHeadersResponse } from "../../shared/utils/Global";

export interface ISourceEntityService {
  getSourceEntityList(
    pageName: string
  ): Promise<APIResponse<GetSourceEntityListDTO>>;
}

export class SourceEntityService
  extends BaseAPI
  implements ISourceEntityService
{
  constructor(authService: IAuthService) {
    super(authService);
  }

  public async getSourceEntityList(
    pageName: string
  ): Promise<APIResponse<GetSourceEntityListDTO>> {
    try {
      const headers: any = {};
      if (pageName) {
        headers["page-header"] = pageName;
      } else {
        return noHeadersResponse();
      }
      const response = await this.get(
        `/masterDashboard/getSourceEntityList`,
        undefined,
        headers
      );
      return right(Result.ok<GetSourceEntityListDTO>(response.data));
    } catch (err: any) {
      return left(err ? err : "Connection failed");
    }
  }
}
