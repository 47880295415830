import { format } from "date-fns";
import { FinBoxResponse } from "../../shared/services/APIResponse";
import { Get } from "../../shared/services/Fetch";
import { noHeadersResponse } from "../../shared/utils/Global";
import { ServiceMethodArgs } from "../LoanApplication/interfaces";
import { BillsResponse, GetBillsParams } from "./interfaces";

export class OverdraftService {
  static async getOverdraftBills({
    token,
    limit = 10,
    page = 1,
    statusText,
    searchType,
    searchQuery,
    filters = {},
    to = null,
    from = null,
    dateType = "updated_at",
    sourceEntityIDs = [],
    pageName,
  }: ServiceMethodArgs<GetBillsParams>) {
    const headers = new Headers();
    if (pageName) {
      headers.append("page-header", pageName);
    } else {
      return noHeadersResponse();
    }

    let methodName = "getODBills";
    let params = new URLSearchParams({ ...(filters as object) });
    params.append("limit", String(limit));
    params.append("page", String(page));
    if (statusText) {
      statusText
        .filter((s) => s !== "TOTAL" && s !== "total")
        .map((s) => {
          params.append("statusText", s);
        });
    }
    if (to && from) {
      params.append("to", format(to, "yyyy-MM-dd"));
      params.append("from", format(from, "yyyy-MM-dd"));
    }
    if (searchType && searchQuery) {
      params.append(searchType, searchQuery);
      methodName = "searchOnlyGetODBills";
    }

    if (sourceEntityIDs?.length > 0) {
      sourceEntityIDs.map((sourceEntityID) => {
        params.append("sourceEntityIDs", sourceEntityID);
      });
    }

    params.append("filter", dateType);

    return await Get<BillsResponse>(
      `/masterDashboard/${methodName}?${params.toString()}`,
      token || "",
      headers
    );
  }
}
