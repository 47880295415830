import { CommonService } from "../../services/Common";
import {
  PAGE_TYPES,
  PRODUCT_TYPES,
  SEARCH_TYPES,
} from "../../services/Partners/constants";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";
import usePartnersStore from "./store";

type Mode = "onboarded" | "pending" | "rejected";

export const STATUS_MAP: Record<Mode, number> = {
  pending: 0,
  rejected: 2,
  onboarded: 1,
};

export default async function partnersLoader({
  request,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  console.log("Partners Loader called");
  const url = new URL(request.url);
  const searchParams = new URLSearchParams(url.search);
  const pageTypeParam = searchParams.get("pageType") || "chart";
  const productTypeParam = searchParams.get("productType") || "all";
  const searchTypeParam = searchParams.get("searchType") ?? "";
  const searchTextParam = searchParams?.get("searchText") ?? "";
  const token = getToken();
  const userDSAListResponse = await CommonService.getUserDSAList({
    token,
    pageName,
  });

  let productTypes = searchParams.get("productType") || "all";
  if (productTypes == "all") {
    productTypes = "";
  }

  // if (pageTypeParam === "chart") {
  // } else {
  //   promisesList.push(
  //     await PartnersService.getListPartners({
  //       limit: 10,
  //       page: +(searchParams.get("page") || 1),
  //       token,
  //       searchType: searchTypeParam,
  //       searchText: decodeURIComponent(searchTextParam),
  //       filters: [],
  //       status: 1,
  //       productType: productTypes,
  //       pageName,
  //     })
  //   );
  // }

  const productType =
    PRODUCT_TYPES.find((pType) => pType.value === productTypeParam) ||
    PRODUCT_TYPES[0];
  const pageType =
    PAGE_TYPES.find((p) => p.value == pageTypeParam) || PAGE_TYPES[0];
  const searchType =
    SEARCH_TYPES.find((p) => p.value == searchTypeParam) || SEARCH_TYPES[0];

  // const promiseResponse = await Promise.all(promisesList);

  usePartnersStore.setState({
    searchText: searchTextParam,
    searchType: searchTypeParam,
  });

  return {
    userDSAListResponse,
    searchText: searchTextParam,
    searchType,
    pageType,
    productType,
  };
}
