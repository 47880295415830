import {
  MdOutlineEmail,
  MdOutlinePerson,
  MdOutlinePhoneAndroid,
} from "react-icons/md";
import { ReactElement, ReactNode } from "react";
import ActionItems from "../../atomic/ActionItems";
import { PencilIcon } from "@heroicons/react/24/outline";
import PopoverPanel from "../../atomic/Dropdown/PopoverPanel";

interface IProps {
  details: any;
  actions: Array<{ isShow: boolean; value: ReactNode }>;
  selfie?: string;
  status: string;
  config: any;
  items: any[];
  IIFLActions?: Array<{ isShow: boolean; value: ReactNode }>;
  docActions?: Array<{ isShow: boolean; value: ReactNode }>;
}

export default function DetailsSidebar({
  details,
  actions = [],
  selfie,
  status,
  items,
  config,
  IIFLActions = [],
  docActions = [],
}: IProps) {
  return (
    <div className="w-[20%] border border-gray-200 rounded-lg p-4 hidden md:block">
      <div className="w-full flex items-center justify-center mb-2">
        <div className="h-20 w-20 rounded-full overflow-hidden">
          {selfie ? (
            <img
              src={selfie}
              alt="user_selfie"
              className="w-full h-full aspect-auto"
            />
          ) : (
            <MdOutlinePerson className="w-20 h-20 aspect-auto text-gray-400" />
          )}
        </div>
      </div>
      <div className="flex items-center justify-center mb-4">
        <p
          className="font-medium max-w-max text-xs capitalize px-3 py-1.5 rounded-md text-center"
          style={{
            color: config?.["color"],
            backgroundColor: config?.["bg"],
          }}
        >
          {config?.label}
        </p>
      </div>
      <p className="text-center text-sm font-medium mb-2">{details?.name}</p>
      <p className="text-center text-xs text-gray-400 mb-1 flex items-center justify-center">
        <MdOutlinePhoneAndroid className="h-4 w-4 mr-1" />
        {details?.mobile}
      </p>
      {details?.email ? (
        <p className="text-center text-xs text-gray-400 flex items-center justify-center mb-1">
          <MdOutlineEmail className="h-4 w-4 mr-1" />
          {details?.email}
        </p>
      ) : null}

      {details?.loanType ? (
        <p className="text-center text-xs text-gray-400 mb-4 capitalize">
          {details?.loanType?.replace(/_/g, " ")}
        </p>
      ) : null}

      {actions?.length ? (
        <div className="mt-6">
          <PopoverPanel
            buttonText="Actions"
            styles={{
              panel: "w-full",
              container: "mb-1",
              arrow: "text-[#626262]",
              buttonText: "text-[#626262]",
              button:
                "w-full flex items-center justify-between bg-white text-[#626262] text-sm font-normal border text-[#626262]",
            }}
          >
            <div className="relative bg-white">
              {actions?.map((item, index) => {
                if (!item?.isShow) return null;
                return <div key={index}>{item.value}</div>;
              })}
              {IIFLActions?.length ? (
                <div className="border-t">
                  {IIFLActions?.map((item, index) => {
                    if (!item?.isShow) return null;
                    return <div key={index}>{item.value}</div>;
                  })}
                </div>
              ) : null}
              {docActions?.length ? (
                <div className="border-t">
                  {docActions?.map((item, index) => {
                    if (!item?.isShow) return null;
                    return <div key={index}>{item.value}</div>;
                  })}
                </div>
              ) : null}
            </div>
          </PopoverPanel>
          {/* <ActionItems items={actions} /> */}
        </div>
      ) : null}

      <div className="mt-5 mb-4 hidden md:block">
        {items.map((item, itemIdx) => {
          return item?.value ? (
            <div
              className="mb-4"
              key={itemIdx}
            >
              <p className="text-xs text-gray-500 font-medium mb-1">
                {item.name}
              </p>
              <p className="text-xs text-black">{item.value}</p>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
}
