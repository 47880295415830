import { LoanApplicationService } from "../../services/LoanApplication";
import { CustomLoaderFunctionArgs } from "../../shared/router/RouteLoader";

export default async function loanBankInfoLoader({
  params,
  getToken,
  pageName,
}: CustomLoaderFunctionArgs) {
  const { loanID } = params;

  return await LoanApplicationService.getBankDetails({
    loanApplicationID: loanID,
    token: getToken(),
    pageName,
  });
}
