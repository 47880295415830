import { DashboardUserType } from "../../dtos/DashboardUserDTO";

export const checkIfDsaUser = (userType: DashboardUserType | string) => {
  if (!userType) {
    return false;
  } else {
    return userType === "dsa" || userType === "sub-dsa";
  }
};

export const checkIfDefaultUser = (userType: DashboardUserType | string) => {
  if (!userType) {
    return false;
  } else {
    return userType === "default";
  }
};

export const checkIfPlatformDsa = (userType: DashboardUserType | string) => {
  if (!userType) {
    return false;
  } else {
    return userType === "platform-dsa";
  }
};

export const checkIfSuperDsaUser = (userType: DashboardUserType | string) => {
  if (!userType) {
    return false;
  } else {
    return userType === "super-dsa";
  }
};
