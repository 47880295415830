import {
  GetActiveCreditLineBySearchQueryRequestDTO,
  GetActiveCreditLineRequestDTO,
  GetActiveCreditLineResponseDTO,
  GetActiveCreditLineSearchType,
} from "../../../dtos/ActiveCreditLineDTO";
import { activeCreditLineService } from "../../../services/apis";
import { activeLineLoader } from "./prePageLoad";

const {
  MOBILE,
  NAME,
  CUSTOMER_ID,
  EMAIL,
  PAN,
  PARTNER_CODE,
  LOAN_APPLICATION_ID,
  FIRM_NAME,
} = GetActiveCreditLineSearchType;

export const activeLineAttributeSearchType = [
  { value: MOBILE, name: "Mobile" },
  { value: NAME, name: "Name" },
  { value: CUSTOMER_ID, name: "Customer ID" },
  { value: EMAIL, name: "Email" },
  { value: PAN, name: "Pan" },
  { value: PARTNER_CODE, name: "Partner Code" },
  { value: FIRM_NAME, name: "Firm Name" },
  { value: LOAN_APPLICATION_ID, name: "Loan Application ID" },
];

export interface ActiveLineListPageState {
  activeLineData: GetActiveCreditLineResponseDTO;

  search: {
    text: string;
    type: GetActiveCreditLineSearchType | undefined;
  };

  perPageLimit: number;
  currentPage: number;

  sourceEntityIDs: string | undefined;

  isFetchingActiveLines: boolean;
  isFetchingActiveLinesSuccess: boolean;
  isFetchingActiveLinesFailure: boolean;

  error: any;
}

export const initialActiveLineListState: ActiveLineListPageState = {
  activeLineData: {
    creditLines: [],
    total: 0,
  },

  search: {
    text: "",
    type: undefined,
  },

  perPageLimit: 10,
  currentPage: 1,

  sourceEntityIDs: undefined,

  isFetchingActiveLines: false,
  isFetchingActiveLinesSuccess: false,
  isFetchingActiveLinesFailure: false,

  error: "",
};

export const activeLineReducer = (
  state: ActiveLineListPageState = initialActiveLineListState,
  action: { type: string; payload: any }
): ActiveLineListPageState => {
  switch (action.type) {
    case "FETCHING_ACTIVE_LINES": {
      return {
        ...state,
        isFetchingActiveLines: true,
        isFetchingActiveLinesSuccess: false,
        isFetchingActiveLinesFailure: false,
      };
    }
    case "FETCHING_ACTIVE_LINES_SUCCESS": {
      return {
        ...state,
        isFetchingActiveLines: false,
        isFetchingActiveLinesSuccess: true,
        isFetchingActiveLinesFailure: false,
        activeLineData: action.payload,
      };
    }
    case "FETCHING_ACTIVE_LINES_FAILURE": {
      return {
        ...state,
        isFetchingActiveLines: false,
        isFetchingActiveLinesSuccess: false,
        isFetchingActiveLinesFailure: true,
        error: action.payload,
      };
    }
    case "SET_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    default:
      throw new Error("Invalid Action");
  }
};

export const activeLineDispatcher = (dispatch: (arg0: any) => void) => {
  function fetchingActiveLines() {
    dispatch({
      type: "FETCHING_ACTIVE_LINES",
    });
  }

  function fetchingActiveLinesSuccess(payload: GetActiveCreditLineResponseDTO) {
    dispatch({
      type: "FETCHING_ACTIVE_LINES_SUCCESS",
      payload,
    });
  }

  function fetchingActiveLinesFailure(error: string) {
    dispatch({
      type: "FETCHING_ACTIVE_LINES_FAILURE",
      error,
    });
  }

  function updatePage(page: number) {
    dispatch({
      type: "SET_PAGE",
      payload: page,
    });
  }

  const getActiveLineList = async (
    dto: GetActiveCreditLineRequestDTO,
    pageName: string
  ) => {
    fetchingActiveLines();
    const result = await activeCreditLineService.getActiveLines(dto, pageName);
    if (result.isRight()) {
      fetchingActiveLinesSuccess(result.value.getValue());
    } else {
      fetchingActiveLinesFailure(result.value);
    }
  };

  const getActiveLineListBySearchQuery = async (
    dto: GetActiveCreditLineBySearchQueryRequestDTO,
    pageName: string
  ) => {
    fetchingActiveLines();
    const result = await activeCreditLineService.getActiveLinesBySearchQuery(
      dto,
      pageName
    );
    if (result.isRight()) {
      fetchingActiveLinesSuccess(result.value.getValue());
    } else {
      fetchingActiveLinesFailure(result.value);
    }
  };

  return {
    getActiveLineListBySearchQuery,
    getActiveLineList,
    updatePage,
  };
};

export { activeLineLoader };
