import classNames from "classnames";
import { InputHTMLAttributes, ReactNode, TextareaHTMLAttributes } from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { ErrorTextBox } from "../../atomic/ErrorBox";

interface IProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  styles?: {
    container?: string;
    label?: string;
    input?: string;
  };
  label?: ReactNode;
  register?: any;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  errorText?: string;
}

export default function TextArea({
  styles = {},
  label,
  name = "",
  autoComplete,
  onChange = undefined,
  value,
  placeholder,
  register,
  error,
  rows = 5,
  cols = 10,
  errorText = "",
  ...rest
}: IProps) {
  return (
    <div
      className={classNames(
        "",
        styles.container ? styles.container : "sm:col-span-4"
      )}
    >
      {label && (
        <label
          htmlFor={name}
          className={classNames(
            "block text-xs font-medium text-gray-700 mb-2",
            styles.label ? styles.label : ""
          )}
        >
          {label}
        </label>
      )}
      <div>
        <textarea
          id={name || undefined}
          value={value}
          autoComplete={autoComplete ? autoComplete : undefined}
          className={classNames(
            "block w-full rounded-md border-gray-200 sm:text-sm lg:text-xs px-4 py-2",
            styles.input || ""
          )}
          placeholder={placeholder}
          onChange={(e) => {
            e.stopPropagation();
            onChange?.(e);
          }}
          rows={rows}
          cols={cols}
          {...register}
          {...rest}
        />
        {errorText ? <ErrorTextBox text={errorText}></ErrorTextBox> : null}
      </div>
    </div>
  );
}
