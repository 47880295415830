import React from "react";
import parse from "html-react-parser";
import { enIN } from "date-fns/locale";
import { formatDistance } from "date-fns/esm";
import { addHours, addMinutes } from "date-fns";
import { File02 } from "@untitled-ui/icons-react";

import Title from "../../LoanTermDeviation/Title";
import Label from "../../../components/atomic/Label";
import Editor from "../../../components/atomic/Editor";
import Button from "../../../components/atomic/Button";
import { notify } from "../../../shared/context/AlertContext";

interface Note {
  createdAt: string;
  createdBy: string;
  note: string;
}

interface NotesProps {
  notes: Note[];
  onSubmit: any;
  close: () => void;
}

export default function Notes(props: NotesProps) {
  const { close, notes, onSubmit } = props;
  const [values, setValue] = React.useState(
    {} as { value: string; length: number }
  );

  return (
    <div className="flex flex-col h-screen notes-container">
      {/* title */}
      <div className="px-10 py-3 border-b shadow">
        <Title
          title="Notes"
          close={close}
        />
      </div>

      {/* list */}
      <div className="flex-1 overflow-y-auto p-10">
        {notes?.map((note, index) => (
          <div
            key={index}
            className="mb-10 last:mb-0"
          >
            <NoteDesc note={note} />
          </div>
        ))}
      </div>

      {/* form */}
      <div className="shadow-[0_-2px_12px_0px_rgba(0,0,0,0.05)] bg-white border-t p-5">
        <fieldset className="mb-4 ql-editor-style">
          <Label
            name="Add a note"
            label="Add a note"
          />
          <Editor
            key={values}
            initialValue={values?.value ?? ""}
            onChange={(value: any) => setValue(value)}
          />
        </fieldset>
        <div className="flex justify-end items-center">
          <Button
            color="primary"
            disabled={!Boolean(values?.value) || values?.length <= 1}
            onClick={() => {
              if (values?.length <= 1) {
                notify({
                  title: "Error",
                  text: "Please enter note",
                });
                return;
              }
              onSubmit({ note: values.value });
              setValue({});
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

function NoteDesc({ note }: { note: Note }) {
  let createdAt = addHours(new Date(note.createdAt), 5);
  createdAt = addMinutes(createdAt, 30);
  return (
    <div className="flex">
      <div className="mr-6">
        <div className="p-2 rounded-lg shadow-[0_1px_2px_0px_rgba(16,24,40,0.05)] border">
          <File02 className=" text-[#4C4C4D]" />
        </div>
      </div>
      <div>
        <div className="text-[#475467] font-normal text-xs mt-2">
          {parse(note?.note)}
        </div>
        <div className="mt-1.5 flex items-center text-[#344054] text-xs font-semibold">
          <div className="">{note?.createdBy}</div>{" "}
          <div className="ml-0.5">|</div>
          <div className="ml-0.5">
            Created{" "}
            {formatDistance(createdAt, new Date(), {
              locale: enIN,
            })}{" "}
            ago
          </div>
        </div>
      </div>
    </div>
  );
}
