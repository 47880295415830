import { XCircleIcon } from "@heroicons/react/24/outline";
import { createContext, ReactNode, useState } from "react";
import { toast } from "react-hot-toast";
import Notification, {
  NotifyStatesTypes,
} from "../../components/atomic/Notification";

interface IAlterState {
  errors: Array<string>;
}

interface IAlterDispatch {
  closeError: (errorIndex: number) => void;
  addError: (error: string) => void;
}

const AlertStateContext = createContext({
  errors: [],
} as IAlterState);

const AlertDispatchContext = createContext({
  closeError: () => {},
  addError: () => {},
} as IAlterDispatch);

interface AlertProviderProps {
  children: any;
}

function AlertProvider({ children }: AlertProviderProps) {
  const [errors, setErrors] = useState<string[]>([]);

  const addError = (error: string) => {
    setErrors((prevState: string[]) => [...prevState, error]);
  };

  const closeError = (errorIndex: number) => {
    setErrors((prevState) =>
      prevState.filter((err, index) => index !== errorIndex)
    );
  };

  return (
    <AlertStateContext.Provider
      value={{
        errors: errors,
      }}
    >
      <AlertDispatchContext.Provider
        value={{
          closeError,
          addError,
        }}
      >
        {children}
      </AlertDispatchContext.Provider>
    </AlertStateContext.Provider>
  );
}

interface INotify {
  title: ReactNode;
  text?: ReactNode;
  type?: NotifyStatesTypes;
}

const notify = ({ title, text, type = "error" }: INotify) => {
  toast.custom(
    (t) => {
      return (
        <Notification
          t={t}
          title={title}
          text={text}
          type={type}
          toast={toast}
        />
      );
    },
    {
      position: "top-right",
      duration: 2000,
      icon: type === "error" ? <XCircleIcon></XCircleIcon> : null,
    }
  );
};

export { AlertProvider, AlertDispatchContext, AlertStateContext, notify };
